import { AccountInfo, AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { Store } from 'redux';
import { ApiRequest, LoginRequest, MsalConfig } from './AuthConfig';
import * as AuthActions from './store/authentication/actions';

let authManagerInstance: PublicClientApplication;

class AuthManager 
{
  private account?: AccountInfo;
  private accessToken?: string;

  constructor() {
    if(authManagerInstance === undefined) {
      authManagerInstance = new PublicClientApplication(MsalConfig);
    }
  }

  public getInstance() : PublicClientApplication {
    return authManagerInstance;
  }

  public generateAccessToken(callback: Function) {
    authManagerInstance.acquireTokenSilent({
      account: authManagerInstance.getAllAccounts()[0],
      scopes: ApiRequest.scopes,
    }).then((tokenResponse) => {
      this.accessToken = tokenResponse.accessToken ? `Bearer ${tokenResponse.accessToken}` : '';
      callback();
    }).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
      // fallback to interaction when silent call fails
        authManagerInstance.acquireTokenPopup({
          account: authManagerInstance.getAllAccounts()[0],
          scopes: ApiRequest.scopes,
        }).then((tokenResponse) => {
          this.accessToken = tokenResponse.accessToken ? `Bearer ${tokenResponse.accessToken}` : '';
          callback();
        })
          .catch(error => {
          // handleError(error);
          });
      }
    });
  }

  public getAccessToken() {
    return this.accessToken;
  }

  public IsUserAuthenticated() {
    return authManagerInstance.getAllAccounts().length > 0;
  }

  public initialize(store: Store): void {
    authManagerInstance
      .handleRedirectPromise()
      .then((response: AuthenticationResult | null) =>
        this.handleResponse(store, response ?? undefined),
      )
      .catch(console.error);
  }

  public getAccount(): AccountInfo | undefined {
    if (this.account !== undefined) {
      return this.account;
    }

    const currentAccounts = authManagerInstance.getAllAccounts();
    if (currentAccounts?.length >= 1) {
      this.account = currentAccounts[0];
    }

    return this.account;
  }

  public login(): void {
    authManagerInstance.loginRedirect(LoginRequest);
  }

  public logout(): void {
    authManagerInstance.logoutRedirect();
  }

  private handleResponse(store: Store, response?: AuthenticationResult): void {
    this.account = response?.account ?? this.getAccount();
    store.dispatch<any>(AuthActions.actionCreators.requestSsoUser());
  }
}


export { AuthManager };
