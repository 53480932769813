import * as React from 'react';
import ReactGA from 'react-ga';
import * as AuthTypes from '../../../store/authentication/types';
import { useEffect, useState } from 'react';
import { WaveScore } from '../../Common/WaveScore/waveScore';
// import { ScoreArea } from '../../../store/leaderboard/types';
import * as ProductLeaderboardStore from '../../../store/productLeaderboard/ProductLeaderboard';
import ToolTips from '../../Common/ToolTips/toolTips';


type IProps = {
    rank: number,
    maxScore: number,
    entry: ProductLeaderboardStore.ProductEntry,
    siteId: string; 
    tenantDetails?: AuthTypes.TenantDetails,
    scoringAreaLookup?: { [id: string]: AuthTypes.ScoreArea },
    selectedScoringArea?: string,
    scoringEntity?: AuthTypes.ScoringEntity,
    onClick: Function,
}

const ProductEntry  = (props: IProps) => {
  const[scoringAreaTooltipsLookup, setScoringAreaTooltipsLookup] = useState<{ [area: string]: boolean }>({});
  const selectedProduct =  props.entry;

  useEffect(() => {
    const { scoringAreaLookup } = props;
    const scoringAreaTooltipsLookup: { [area: string]: boolean } = {};
    if(scoringAreaLookup) {
      Object.keys(scoringAreaLookup).forEach((scoringArea) => {
        scoringAreaTooltipsLookup[scoringArea] = false;
      });
      setScoringAreaTooltipsLookup(scoringAreaTooltipsLookup);
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const checkIfActiveArea = (scoringArea: string) => {
    const entryScoringArea = props.entry.scores.find(s => s.area === (props.scoringAreaLookup && props.scoringAreaLookup[scoringArea].dataId));
    return entryScoringArea && entryScoringArea.area === props.selectedScoringArea;
  };

  const getAreaScore = (scoringArea: string) => {
    const entryScoringArea = props.entry.scores.find(s => s.area === (props.scoringAreaLookup && props.scoringAreaLookup[scoringArea].dataId));
    if (entryScoringArea && entryScoringArea.rawScore > 0) {
      return entryScoringArea.rawScore.toFixed(1);
    }
    return '0.0';
  };

  const toggleTooltip = (scoringArea: string, toggle: boolean) => {
    const newScoringAreaLookup = { ...scoringAreaTooltipsLookup };
    if(scoringAreaTooltipsLookup) {
      newScoringAreaLookup[scoringArea] = toggle;
    }
    setScoringAreaTooltipsLookup(newScoringAreaLookup);
  };

  const constructEntryImagePath = (path?: string) => {
    if(path) {
      return `/assets/areas/${path.split('.svg')[0]}_black.svg`;
    }
    return '';
  };

  return (
    <div className='leaderboard-wrapper'>
      <div onClick={() => props.onClick()}
        className='leaderboard-entry ui-toolkit'>
        <div className={`entry-header ${!props.scoringEntity ? 'dim' : ''}`}>
          <div className="position">{props.rank}</div>
          <div className="change">
          </div>
        </div>
        <div className="metrics">
          <button className="leaderboard-details-info">
            <div className={`details ${!props.scoringEntity ? 'dim' : ''}`}>
              <div className="product">
                {selectedProduct.itemDescription}
              </div>
              <div className="tenant">
              </div>
              <div className="tenant">
                {selectedProduct.itemNumber}
              </div>
            </div>

          </button>
          <div className="areas">
            {props.scoringAreaLookup && Object.keys(props.scoringAreaLookup).map((scoringArea) => (
              <div
                onMouseOver={() => toggleTooltip(scoringArea, true)}
                onMouseOut={() => toggleTooltip(scoringArea, false)}
                className={ window.screen.width < 834 && 
                  checkIfActiveArea(scoringArea) 
                  ? 
                  `area-score active ${!props.scoringEntity ? 'dim' : ''}` 
                  : 
                  `area-score ${!props.scoringEntity ? 'dim' : ''}`}
                key={props.scoringAreaLookup?.[scoringArea].dataId}>
                <img
                  className='area-image'
                  src={constructEntryImagePath(props.scoringAreaLookup?.[scoringArea].image)}
                ></img>
                <ToolTips
                  text={props.scoringAreaLookup?.[scoringArea]?.name ?? ''}
                  tipToggled={Object.keys(scoringAreaTooltipsLookup).length > 0 ? scoringAreaTooltipsLookup[scoringArea] : false}
                  handleParentClick={() => null}
                  onInputChange={() => null}
                  hideButton={true}
                />
                <div>
                  {getAreaScore(scoringArea)}
                </div>
              </div>
            ))}
          </div>
          <div className={`score ${!props.scoringEntity ? 'dim' : ''}`}>
            <WaveScore maxScore={props.maxScore}
              score={props.entry.totalScore}
              index={props.rank + 10} />
            <button type="button">
              <div className="chevron" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductEntry;