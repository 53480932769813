import * as React from 'react';
import ReactGA from 'react-ga';

export default class Error extends React.PureComponent<{ text: string }, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const { text } = this.props;

    return (
      <div className="error ui-toolkit">
        <span>{text}</span>
      </div>
    );
  }
}
