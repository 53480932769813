import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import ConfirmationModal from '../../uiToolkit/components/confirmationModal';
import { ApplicationState } from '../../store';
import * as BandingStore from '../../store/configuration/Banding';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  bandings: state.bandings,
});
const mapDispatch = {
  ...BandingStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { groupid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  id: number;
  name: string;
  default: number;
  competingLabel: string;
  formIsSubmitted: boolean;
  confirmationModal:boolean;
}

class EditGroup extends React.PureComponent<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      name: '',
      default: 0,
      competingLabel: '',
      formIsSubmitted: false,
      confirmationModal: false,
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: {
          groupid,
        },
      },
      requestBanding,
    } = this.props;

    if (groupid !== '0') {
      requestBanding(groupid);
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { bandings: { selectedBanding, isLoading, isUpdated, isErrored, isDeleted }, history } = this.props;
    const { id } = this.state;

    if (selectedBanding !== prevProps.bandings.selectedBanding && !isLoading) {
      if (selectedBanding !== undefined) {
        this.setState({
          id: selectedBanding.id,
          name: selectedBanding.name,
          default: selectedBanding.default,
          competingLabel: selectedBanding.competingLabel,
        });
      } else {
        this.setState({
          id: 0,
          name: '',
          default: 0,
          competingLabel: '',
        });
      }
    }

    if (isUpdated && !prevProps.bandings.isUpdated) {
      toast.info(`Successfully ${ id === 0 ? 'created' : 'updated'} scoring group`);
      history.goBack();
    } else if(isErrored && !prevProps.bandings.isErrored){
      toast.error(`Failed ${ id === 0 ? 'creating' : 'updating'} scoring group`, {
        className: 'toast-popup error',
      });
    } else if (isDeleted && !prevProps.bandings.isDeleted) {
      toast.info('Successfully deleted scoring group');
    }
  }

  public handleTextInputUpdate(value: string, fieldName: string) {
    const update: object = { [fieldName]: value };

    this.setState(update);
  }

  public handleSave() {
    const { saveBanding } = this.props;
    const { id, name, competingLabel } = this.state;

    this.setState({ formIsSubmitted: true });

    if (name && competingLabel) {
      saveBanding({ id, name, competingLabel });
    }
  }

  public handleDelete() {
    const { deleteBanding } = this.props;
    const { id } = this.state;
    deleteBanding(id.toString());
  }

  public render() {
    const {
      id,
      name,
      competingLabel,
      default: defaultSelection,
      formIsSubmitted,
      confirmationModal,
    } = this.state;

    const { bandings: { isLoading } } = this.props;

    return (
      <div>
        { isLoading && <Loading/> }
        { !isLoading && <Form title={`${id === 0 ? 'Create' : 'Edit'} Scoring Group`}>
          <Input
            name="name"
            label="Name"
            placeholderText="Name"
            inputType="text"
            onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
            value={name}
            valid={formIsSubmitted && name.length === 0}
          />
          <Input
            name="hiddenLabel"
            label="Leaderboard Hidden Label"
            placeholderText="Competing Label"
            inputType="text"
            onChange={(e: string) => this.handleTextInputUpdate(e, 'competingLabel')}
            value={competingLabel}
            valid={formIsSubmitted && competingLabel.length === 0}
          />
          <label>
            Default Category:
            {defaultSelection === 1 ? 'True' : 'False'}
          </label>

          <Button
            text="Save"
            onClick={() => this.handleSave()}
            enabled
          />
          {id > 0 && defaultSelection === 0
          && (
            <Button
              text="Delete"
              onClick={() => this.setState({ confirmationModal: true })}
              enabled
            />
          )}
        </Form>}
        {confirmationModal
        && (
          <ConfirmationModal
            continue={() => this.handleDelete()}
            cancel={() => this.setState({ confirmationModal: false })}
            inProgress={isLoading}
          />
        )}
      </div>
    );
  }
}

export default connector(withRouter(EditGroup));
