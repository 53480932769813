import * as React from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

interface IProps {
  text: string,
  invertTextColour?: boolean
}

export const Message = (props: IProps) => {
  const { text, invertTextColour } = props;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className={`message ui-toolkit ${invertTextColour ? 'invert' : ''}`}>
      <span>{text}</span>
    </div>
  );
  
};
