import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as CategoryStore from '../../store/configuration/Categories';
import Error from '../Common/Error/error';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  categories: state.categories,
});
const mapDispatch = {
  ...CategoryStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  filter: string;
  page: number;
  pageSize: number;
}

class ItemCategories extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: '',
      page: 1,
      pageSize: 10,
    };
  }

  public componentDidMount() {
    const { history, requestCategories } = this.props;
    
    ReactGA.pageview(window.location.pathname + window.location.search);
    
    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());

    const queryFilter: string = (query.filter && query.filter.toString()) || '';
    const queryPage: number = parseInt((query.page && query.page.toString()) || '1', 10) || 1;

    const newState: IState = {
      ...this.state,
    };

    let updateState: boolean = false;

    if (newState.filter !== queryFilter && queryFilter.length > 0) {
      updateState = true;
      newState.filter = queryFilter;
    }

    if (newState.page !== queryPage && queryPage > 0) {
      updateState = true;
      newState.page = queryPage;
    }

    if (updateState) {
      this.setState(newState);
    }

    requestCategories(newState.filter, newState.page, newState.pageSize);
  }

  public doSearch(filter: string) {
    const { location, history, requestCategories } = this.props;
    const { pathname } = location;
    const { pageSize } = this.state;

    this.setState({ filter, page: 1 });

    history.replace(`${pathname}?filter=${filter}&page=${1}`);

    requestCategories(filter, 1, pageSize);
  }

  public doPaging(page: number) {
    const { location, history, requestCategories } = this.props;
    const { pathname } = location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    history.replace(`${pathname}?filter=${filter}&page=${page}`);

    requestCategories(filter, page, pageSize);
  }

  public render() {
    const { history, categories } = this.props;
    const { filter, page, pageSize } = this.state;

    return (
      <Page mode="center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Categories</h1>
            <p>
            A category allows you to group together similar types of
            items that may appear in score areas.
            </p>
            <p>
            Please note changes to categories will be reflected only
            when data is processed within the current or future
            reporting periods.
            </p>
          </div>
          
          <Table
            add
            addClick={() => history.push('/configuration/categories/0')}
            addLabel="Add Category"
            search
            searchClick={(f: string) => this.doSearch(f)}
            initialValue={filter}
            pagination
            pageSize={pageSize}
            pageNumber={page}
            pageChange={(p: number) => this.doPaging(p)}
            totalRecords={categories.totalRecords}
          >
            <thead>
              <tr>
                <th className="padding title">Category</th>
                <th className="padding title">Default</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>

              {categories.isLoading && 
                <tr><td colSpan={3}><Loading/></td></tr>}

              {categories.isErrored && 
                <tr><td colSpan={3}><Error text={categories.errorMessage} /></td></tr>}

              {categories.categories.sort((a, b) => a.id - b.id).map((b) => (
                <tr
                  key={b.id.toString()}
                  onClick={() => history.push(`/configuration/categories/${b.id}`)}
                >
                  <td className="padding">{b.name}</td>
                  <td className="padding">{b.default === 1 ? 'Yes' : 'No'}</td>
                  <td className="padding">Edit</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(ItemCategories));
