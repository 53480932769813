import * as React from 'react';

interface IProps {
    inProgress: boolean;
    continue: Function;
    cancel: Function;
}

export default class PromptModal extends React.PureComponent<IProps, {}> {
  public render() {
    const { inProgress, continue: continueFunc, cancel: cancelFunc } = this.props;
    return (
      <div className="prompt-modal">
        <div className="modal-blocker ui-toolkit">
          <div className="modal">
            <form>
              <div className="modal-title">
                <h2>Are you sure?</h2>
              </div>
              <div className="modal-body">
                By leaving this page you will lose your progress
              </div>
              <div className="modal-buttons">
                <button
                  type="button"
                  className="action"
                  disabled={inProgress}
                  onClick={() => cancelFunc()}
                >
                    Stay
                </button>
                <button
                  type="button"
                  className="action negative"
                  disabled={inProgress}
                  onClick={() => continueFunc()}
                >
                    Leave
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
