import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as ReactGA from 'react-ga';
import * as ScoringItemsStore from '../../store/dashboard/ScoringItems';
import Table from '../../uiToolkit/containers/table';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import CustomPrompt from '../../uiToolkit/components/customPrompt';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  scoringItems: state.scoringItems,
});
const mapDispatch = {
  ...ScoringItemsStore.actionCreators,
};

type TParams = { tenantId: string, scoringItemId: string };
const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    selectedScoringItems: ScoringItemsStore.ScoringItem[];
    tenantId: string;
    formIsUpdated: boolean;
}

class ScoringItems extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedScoringItems:[],
      tenantId: '',
      formIsUpdated: false,
    };
  }

  public componentDidMount() {
    const { match: {
      params: {
        tenantId,
      },
    }, requestScoringItems } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    requestScoringItems(tenantId);
  }

  public componentDidUpdate(prevProps: IProps) {
       
    const { match: {
      params: {
        tenantId,
      },
    }, requestScoringItems, scoringItems: { isUpdated, isErrored } } = this.props;

    if (isUpdated && !prevProps.scoringItems.isUpdated) {
      requestScoringItems(tenantId);
    }
    
    if (isUpdated && !prevProps.scoringItems.isUpdated) {
      this.showNotification('Successfully updated scoring item');
    } else if (isErrored && !prevProps.scoringItems.isErrored) {
      this.showNotification('There was an error saving scoring item');
    }   
  }
 
  public showNotification(message: string) {
    const { scoringItems: { isErrored } } = this.props;
      
    this.setState({ formIsUpdated: false });

    if(isErrored){
      toast.error(message, {
        className: 'toast-popup error',
      });
    } else {
      toast.info(message);
    }
  }

  public handleTextInputUpdate(id: number, value: React.ChangeEvent<HTMLInputElement> | string, fieldName: 'name' | 'type' | 'dataId' ) {
    const update = this.state.selectedScoringItems.filter(item => item.id === id)[0];
    update[fieldName] = value as string;
    this.setState({ ...this.state, selectedScoringItems: [...this.state.selectedScoringItems.filter(item => item.id !== id), update] });
  }

  public handleUpdatingForm(scoringItem: ScoringItemsStore.ScoringItem) {
    this.setState({ formIsUpdated: true, selectedScoringItems: [...this.state.selectedScoringItems,scoringItem] });
  }

  public handleSave(selectedItemId:number) {
    const { match: {
      params: {
        tenantId,
      },
    }, saveScoringItem } = this.props;
        
    const { id, name, type, dataId } = this.state.selectedScoringItems.filter(item => item.id === selectedItemId)[0];
    const data = { id, name, type, tenantId, dataId };
    if (name && type && tenantId && dataId) {
      saveScoringItem(data);
      this.handleSelectedScoringItemsUpdate(id);
    }
  }

  public handleCancel(id: number) {
    const { match: {
      params: {
        tenantId,
      },
    }, requestScoringItems } = this.props;
    
    this.handleSelectedScoringItemsUpdate(id);
    requestScoringItems(tenantId);
  }

  public handleSelectedScoringItemsUpdate(id: number) {
    this.setState({ formIsUpdated: true, selectedScoringItems: [...this.state.selectedScoringItems.filter(item => item.id !== id)] }, () => {
      if (this.state.selectedScoringItems.length === 0) {
        this.setState({ ...this.state, formIsUpdated: false });
      }
    });
  }

  public render() {
    const { match: {
      params: {
        tenantId,
      },
    }, history, scoringItems } = this.props;
    const {
      selectedScoringItems,
      formIsUpdated,
    } = this.state;

    return (
      <>
        <CustomPrompt
          when={formIsUpdated}
          navigate={(path: string) => {
            this.props.history.push(path);
          }}
          shouldBlockNavigation={() => {
            if (formIsUpdated) {
              return true;
            }
            return false;
          }}
        />

        <div className="text-wrapper scoring-items">
          <h1>Scoring Items</h1>
          <p>A Scoring Item is characterized as an item affecting the score of an area </p>
          <p>For example a common scoring entity would be a coffee cup or a paper straw </p>
        </div>
        { !scoringItems.isLoading && scoringItems.scoringItems.length === 0 && 
          <div className="scoring-items">
            <div className="actions">
              <p className="bold-text">The current tenant does not have any scoring items</p>
              <button
                type="button"
                onClick={() => history.push(`/dashboard/tenant/${tenantId}/addScoringItems`) }
              >
                <div className="label">Create new Scoring Items</div>
                <div className="plus-icon">
                      +
                  <div className="circle-plus-icon"></div>
                </div>
              </button>
            </div>
          </div>
        }

        <div className="scoring-items dashboard ui-toolkit">
          <Page mode="center dashboard"
            pageNumber={1}
            padding>
            <div className="dashboard-wrapper">
              <div className="dashboard-content">
                <Page mode="center"
                  pageNumber={1}>
                  <Table
                    add
                    addClick={() => 
                      this.props.history.push(`/dashboard/tenant/${tenantId}/addScoringItems`)}
                    addLabel="Create Scoring Item"
                    search={false}
                    searchClick={() => null}
                    initialValue=""
                    pagination={false}
                    pageSize={1}
                    pageNumber={1}
                    pageChange={() => null}
                    totalRecords={1}
                  >
                    <thead>
                      <tr>
                        <th className="padding title">Item Name</th>
                        <th className="padding title">Item Type</th>
                        <th className="padding title">Item Number</th>
                        <th className="padding title" >Edit</th>
                      </tr>
                    </thead>
                    <tbody>

                      {scoringItems.isLoading && 
                        <tr><td colSpan={4}><Loading/></td></tr>}
          
                      {!scoringItems.isLoading && scoringItems.scoringItems.map((s) => (
                        <tr
                          key={s.id.toString()}
                          onClick={() => this.handleUpdatingForm(s)}
                        >
                          <td className="padding scoring-items">
                            {!selectedScoringItems.filter(item => item.id === s.id)[0] && s.name}
                            {selectedScoringItems.filter(item => item.id === s.id)[0] &&
                                <Input
                                  name="id"
                                  label=""
                                  placeholderText=""
                                  inputType="text"
                                  onChange={(e: string) => { this.handleTextInputUpdate(s.id ,e , 'name'); }}
                                  value={selectedScoringItems.filter(item => item.id === s.id)[0].name}
                                  valid={(selectedScoringItems.filter(item => item.id === s.id)[0].name.length===0)}
                                />
                            }   
                          </td>
                          <td className="padding scoring-items">
                            {!selectedScoringItems.filter(item => item.id === s.id)[0] && s.type}
                            { formIsUpdated && selectedScoringItems.filter(item => item.id === s.id)[0] &&
                                <Input
                                  name=""
                                  label=""
                                  placeholderText=""
                                  inputType="text"
                                  onChange={(e: string) => { this.handleTextInputUpdate(s.id ,e , 'type'); }}
                                  value={selectedScoringItems.filter(item => item.id === s.id)[0].type}
                                  valid={(selectedScoringItems.filter(item => item.id === s.id)[0].type.length === 0)}
                                />
                            }
                          </td>
                          <td className="padding scoring-items">
                            {!selectedScoringItems.filter(item => item.id === s.id)[0] && s.dataId}
                            { formIsUpdated && selectedScoringItems.filter(item => item.id === s.id)[0]  &&
                                <Input
                                  name=""
                                  label=""
                                  placeholderText=""
                                  inputType="text"
                                  onChange={(e: string) => { this.handleTextInputUpdate(s.id ,e , 'dataId'); }}
                                  value={selectedScoringItems.filter(item => item.id === s.id)[0].dataId}
                                  valid={(selectedScoringItems.filter(item => item.id  ===s.id)[0].dataId.length === 0)}
                                />
                            }
                          </td>
                          <td className="padding">
                            {!selectedScoringItems.filter(item => item.id === s.id)[0] &&
                                <button><img className='edit'
                                  src='/assets/edit-icon.svg' /></button>
                            }
                            {formIsUpdated && selectedScoringItems.filter(item => item.id === s.id)[0] &&
                                <div className=' scoring-items form-action'>
                                  <Button
                                    text="Save"
                                    onClick={() => this.handleSave(s.id)}
                                    enabled
                                  />
                                  <span className="cancel-btn">
                                    <Button
                                      text="Cancel"
                                      onClick={() => this.handleCancel(s.id)}
                                      enabled
                                    />
                                  </span>
                                </div>
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Page>
              </div>
            </div>
          </Page>
        </div>
      </>
    );
  }
}

export default connector(withRouter(ScoringItems));
