import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScoringItemState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    scoringItems: ScoringItem[];

    selectedScoringItem?: ScoringItem;
}

export interface ScoringItem {
    id: number;
    name: string;
    type: string;
    dataId: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestScoringItemsAction {
    type: 'REQUEST_SCORING_ITEMS';
}

interface ReceiveScoringItemsAction {
    type: 'RECEIVE_SCORING_ITEMS';
    payload: any;
}

interface FailedScoringItemsAction {
    type: 'FAILED_SCORING_ITEMS';
}

interface RequestScoringItemAction {
    type: 'REQUEST_SCORING_ITEM';
}

interface ReceiveScoringItemAction {
    type: 'RECEIVE_SCORING_ITEM';
    payload: any;
}

interface FailedScoringItemAction {
    type: 'FAILED_SCORING_ITEM';
}

interface RequestSaveScoringItemAction {
    type: 'REQUEST_SAVE_SCORING_ITEM';
}

interface ReceiveSaveScoringItemAction {
    type: 'RECEIVE_SAVE_SCORING_ITEM';
    payload: any;
}

interface FailedSaveScoringItemAction {
    type: 'FAILED_SAVE_SCORING_ITEM';
}


// Declare a 'discriminated union' type. This guarantees that
// all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestScoringItemsAction | ReceiveScoringItemsAction | FailedScoringItemsAction | RequestScoringItemAction
    | ReceiveScoringItemAction | FailedScoringItemAction | RequestSaveScoringItemAction | ReceiveSaveScoringItemAction
    | FailedSaveScoringItemAction ; 

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestScoringItems: (tenantId:string):
        AppThunkAction<any> => (dispatch, getState) => {
      
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCORING_ITEMS',
        http: {
          verb: 'GET',
          endpoint: `/api/scoringItem/getScoringItems/${tenantId}`,
          successAction: 'RECEIVE_SCORING_ITEMS',
          failureAction: 'FAILED_SCORING_ITEMS',
        },
      });
    }
  },

  requestScoringItem: (id:string):
        AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCORING_ITEM',
        http: {
          verb: 'GET',
          endpoint: `/api/scoringItem/getScoringItem/${id}`,
          successAction: 'RECEIVE_SCORING_ITEM',
          failureAction: 'FAILED_SCORING_ITEM',
        },
      });
    }
  },

  saveScoringItem: (data:any):
        AppThunkAction<any> => (dispatch, getState) => {
            
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_SCORING_ITEM',
        http: {
          verb: 'POST',
          endpoint: '/api/scoringItem/Save',
          successAction: 'RECEIVE_SAVE_SCORING_ITEM',
          failureAction: 'FAILED_SAVE_SCORING_ITEM',
          body: {
            ...data,
          },
        },
      });
    }
  },
};



// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: ScoringItemState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  scoringItems: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<ScoringItemState> = (state: ScoringItemState | undefined, incomingAction: Action): ScoringItemState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_SCORING_ITEMS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      scoringItems: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_SCORING_ITEMS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      scoringItems: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_SCORING_ITEMS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      scoringItems: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'REQUEST_SCORING_ITEM':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      selectedScoringItem: undefined,
    };
  case 'RECEIVE_SCORING_ITEM':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      selectedScoringItem: action.payload,
    };
  case 'FAILED_SCORING_ITEM':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      selectedScoringItem: undefined,

    };
  case 'REQUEST_SAVE_SCORING_ITEM':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_SCORING_ITEM':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_SCORING_ITEM':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
       
};
