import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as ReportingEntityStore from '../../store/configuration/ReportingEntities';
import Button from '../../uiToolkit/components/button';
import Modal from '../../uiToolkit/components/modal';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  reportingEntities: state.reportingEntities,
});
const mapDispatch = {
  ...ReportingEntityStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    filter: string;
    page: number;
    pageSize: number;
    showBandingModal: boolean;
    showOwnerModal: boolean;
    showSetCompanyModal: boolean;
    selectedIds: number[];
    selectedGroupId?: string;
    selectedOwnerId?: string;
    selectedCompanyId?: string;
}

class ScoringEntities extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: '',
      page: 1,
      pageSize: 10,
      showBandingModal: false,
      showOwnerModal: false,
      showSetCompanyModal: false,
      selectedIds: [],
      selectedGroupId: undefined,
      selectedOwnerId: undefined,
      selectedCompanyId: undefined,
    };
  }

  public componentDidMount() {
    const { history, requestReportingEntities } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());

    const queryFilter: string = (query.filter && query.filter.toString()) || '';
    const queryPage: number = parseInt((query.page && query.page.toString()) || '1', 10) || 1;

    const newState: IState = {
      ...this.state,
    };

    let updateState: boolean = false;

    if (newState.filter !== queryFilter && queryFilter.length > 0) {
      updateState = true;
      newState.filter = queryFilter;
    }

    if (newState.page !== queryPage && queryPage > 0) {
      updateState = true;
      newState.page = queryPage;
    }

    if (updateState) {
      this.setState(newState);
    }

    requestReportingEntities(newState.filter, newState.page, newState.pageSize);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { requestReportingEntities, reportingEntities } = this.props;
    const { filter, page, pageSize } = this.state;

    if (reportingEntities.isUpdated
            && !prevProps.reportingEntities.isUpdated
            && !reportingEntities.isLoading) {

      toast.info('Successfully updated scoring entities');
      
      requestReportingEntities(filter, page, pageSize);
      this.setState({ selectedIds: [] });
    } else if (reportingEntities.isErrored && !prevProps.reportingEntities.isErrored) {
      toast.error('There was an error updating scoring entities', {
        className: 'toast-popup error',
      });
    }
  }

  public setBanding() {
    const { RequestSetReportingEntitiesBanding } = this.props;
    const { selectedIds, selectedGroupId } = this.state;

    if (selectedIds.length > 0 && selectedGroupId && selectedGroupId.length > 0) {
      RequestSetReportingEntitiesBanding(selectedGroupId, selectedIds);
      this.handleBandingModalHide();
    }
  }

  public setOwner() {
    const { RequestSetReportingEntitiesOwner } = this.props;
    const { selectedIds, selectedOwnerId } = this.state;

    if (selectedIds.length > 0 && selectedOwnerId && selectedOwnerId.length > 0) {
      RequestSetReportingEntitiesOwner(selectedOwnerId, selectedIds);
      this.handleOwnerModalHide();
    }
  }

  public setCompany() {
    const { RequestSetReportingEntitiesCompany } = this.props;
    const { selectedIds, selectedCompanyId } = this.state;

    if (selectedIds.length > 0 && selectedCompanyId && selectedCompanyId.length > 0) {
      RequestSetReportingEntitiesCompany(selectedCompanyId, selectedIds);
      this.handelSetCompanyModalHide();
    }
  }

  public handleBandingModalShow() {
    this.setState({ showBandingModal: true });
  }

  public handleBandingModalHide() {
    this.setState({ showBandingModal: false });
  }

  public handleOwnerModalShow() {
    this.setState({ showOwnerModal: true });
  }

  public handleOwnerModalHide() {
    this.setState({ showOwnerModal: false });
  }

  public handelSetCompanyModalShow() {
    this.setState({ showSetCompanyModal: true });
  }

  public handelSetCompanyModalHide() {
    this.setState({ showSetCompanyModal: false });
  }

  public toggleOption(id: number) {
    const { selectedIds } = this.state;
    const index: number = selectedIds.indexOf(id);

    if (index > -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds: [...selectedIds] });
  }

  public doSearch(filter: string) {
    const { location, history, requestReportingEntities } = this.props;
    const { pathname } = location;
    const { pageSize } = this.state;

    this.setState({ filter, page: 1 });

    history.replace(`${pathname}?filter=${filter}&page=${1}`);

    requestReportingEntities(filter, 1, pageSize);
  }

  public doPaging(page: number) {
    const { location, history, requestReportingEntities } = this.props;
    const { pathname } = location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    history.replace(`${pathname}?filter=${filter}&page=${page}`);

    requestReportingEntities(filter, page, pageSize);
  }

  public render() {
    const { reportingEntities } = this.props;
    const {
      filter,
      page,
      pageSize,
      selectedIds,
      showBandingModal,
      showOwnerModal,
      showSetCompanyModal,
    } = this.state;

    const entries = reportingEntities.reportingEntities.sort((a, b) => a.id - b.id);
    const bandingOptions = reportingEntities.availableBandings.sort((a, b) => a.id - b.id);
    const ownerOptions = reportingEntities.availableOwners.sort((a, b) => a.id - b.id);
    const companyOptions = reportingEntities.availableCompanies.sort((a, b) => a.id - b.id); 

    return (
      <Page mode=" center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Scoring Entities</h1>
            <p>
                A scoring entity can represent anything you have data for.
                This could be a store, team or website.
            </p>
            <p>
                Please note changes to groups will be reflected only when data is
                processed within the current or future reporting periods.
            </p>
          </div>
          <Button
            text="Set Scoring Group"
            enabled={selectedIds.length > 0}
            onClick={() => this.handleBandingModalShow()}
          />
          <Button
            text="Set Companies"
            enabled={selectedIds.length > 0}
            onClick={() => this.handelSetCompanyModalShow()}
          />
          <Button
            text="Set Scoring Entity Owner"
            enabled={selectedIds.length > 0}
            onClick={() => this.handleOwnerModalShow()}
          />
          <Table
            add={false}
            addClick={() => null}
            addLabel=""
            search
            searchClick={(f: string) => this.doSearch(f)}
            initialValue={filter}
            pagination
            pageSize={pageSize}
            pageNumber={page}
            pageChange={(p: number) => this.doPaging(p)}
            totalRecords={reportingEntities.totalRecords}
          >
            <thead>
              <tr>
                <th className="padding title">ID</th>
                <th className="padding title">Description</th>
                <th className="padding title">Company</th>
                <th className="padding title">Group</th>
                <th className="padding title">Owner</th>
              </tr>
            </thead>
            <tbody>

              {reportingEntities.isLoading && 
                <tr><td colSpan={5}><Loading/></td></tr>}

              {entries.map((b) => (
                <tr
                  key={b.id.toString()}
                  onClick={() => this.toggleOption(b.id)}
                  className={`${selectedIds.indexOf(b.id) > -1 ? 'selected' : ''}`}
                >
                  <td className="padding">{b.label}</td>
                  <td className="padding">{b.description}</td>
                  <td className="padding">{b.companyName}</td>
                  <td className="padding">{b.bandingName}</td>
                  <td className="padding">{b.ownerName}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {showBandingModal
            && (
              <Modal
                title="Set Scoring Group"
                cancel={() => this.handleBandingModalHide()}
                inProgress={reportingEntities.isLoading}
                continue={() => this.setBanding()}
                background={true}
              >
                <select onChange={(e) => this.setState({ selectedGroupId: e.target.value })}>
                  <option value="">Select a Group</option>
                  {bandingOptions.map((a) => <option value={a.id}
                    key={a.id}>{a.name}</option>)}
                </select>
              </Modal>
            )}

          {showOwnerModal
            && (
              <Modal
                title="Set Scoring Entity Owner"
                cancel={() => this.handleOwnerModalHide()}
                inProgress={reportingEntities.isLoading}
                continue={() => this.setOwner()}
                background={true}
              >
                <select onChange={(e) => this.setState({ selectedOwnerId: e.target.value })}>
                  <option value="">Select an Owner</option>
                  {ownerOptions.map((a) => <option value={a.id}
                    key={a.id}>{a.name}</option>)}
                </select>
              </Modal>
            )}

          {showSetCompanyModal
            && (
              <Modal
                title="Set Company"
                cancel={() => this.handelSetCompanyModalHide()}
                inProgress={reportingEntities.isLoading}
                continue={() => this.setCompany()}
                background={true}
              >
                <select onChange={(e) => this.setState({ selectedCompanyId: e.target.value })}>
                  <option value="">Select Company</option>
                  {companyOptions.map((a) => <option value={a.id}
                    key={a.id}>{a.name}</option>)}
                </select>
              </Modal>
            )}
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(ScoringEntities));
