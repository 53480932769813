import * as React from 'react';

interface IProps {
    inProgress: boolean;
    continue: Function;
    cancel: Function;
}

export default class ConfirmationModal extends React.PureComponent<IProps, {}> {
  public render() {
    const { inProgress, continue: continueFunc, cancel } = this.props;
    return (
      <div className="modal-blocker ui-toolkit">
        <div className="modal">
          <form>
            <div className="modal-title">
            <h2>Are you sure?</h2>
            </div>
            <div className="modal-buttons">
              <button
                type="button"
                className="action"
                disabled={inProgress}
                onClick={() => continueFunc()}
              >
                Confirm
              </button>
              <button
                type="button"
                className="action negative"
                disabled={inProgress}
                onClick={() => cancel()}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
