import * as React from 'react';

type TextAreaProps = {
    placeholderText: string,
    required?: boolean,
    onChange: Function,
    value?: string,
    valid?: boolean,
    name: string,
    label: string,
    maxLength?: number

}

export default class TextArea extends React.PureComponent<TextAreaProps, {}> {
    public render() {
        const {
            label, name, valid, value, maxLength, onChange, required, placeholderText,
        } = this.props;
        return (
            <label
                htmlFor={name}
                className="textarea ui-toolkit"
            >
                {label}
                <textarea
                    name={name}
                    placeholder={placeholderText}
                    onChange={(e) => onChange(e.target.value)}
                    maxLength={maxLength}
                    value={value}
                    required={required}
                    className={valid ? 'invalid' : ''}
                />
            </label>
        );
    }
}