import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

interface IProps {
    label: string;
    value: string;
    format: string;
    suffix?: string;
    editMode?: boolean;
    metricValues?: string[];
    onInputChange: Function;
    onDelete: Function;
}

const ScoreListItemValueMetric = (props: IProps) => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { label, value, format, suffix, editMode, metricValues, onInputChange, onDelete } = props;

  let displayValue = value;

  if(format === 'currency'){
    displayValue = value && Number.parseFloat(value).toLocaleString(undefined, {
      style: 'currency',
      currency: 'GBP',
    }); 
  }else if (format === 'integer'){
    displayValue = value && Number.parseInt(value).toFixed(0);
  }else if (format === 'float'){
    if(value.indexOf('.') > -1){
      displayValue = value && Number.parseFloat(value).toFixed(1);
    }else{
      displayValue = value && Number.parseInt(value).toFixed(0);
    }
  }else if (format === 'date'){
    if(value && value.length === 10){
      displayValue = new Date(value).toDateString();
    }
  }else if (format === 'percentage'){
    displayValue = value && Number.parseInt(value).toFixed(0) + '%';
  }

  return (
    <div className="score-list-item-value-metric ui-toolkit">
      <div className="label">
        {editMode ?  
          <>
            <input type="text"
              list="metricVariables"
              className={metricValues && metricValues.includes(label) ? 'variable' : ''}
              placeholder='Label'
              onChange={(e) => onInputChange(e.target.value, 'label')}
              value={label}>
            </input>
          </>
          :label}
      </div>
      <div className="value">{editMode ? 
        <input type="text"
          list="metricVariables"
          className={metricValues && metricValues.includes(value) ? 'variable' : ''}
          placeholder='Value'
          onChange={(e) => onInputChange(e.target.value, 'value')}
          value={value}>
        </input>
        : displayValue} 
          
      {editMode ? 
        <input type="text"
          className='suffix'
          list="metricVariables"
          placeholder='Suffix'
          onChange={(e) => onInputChange(e.target.value, 'suffix')}
          value={suffix}>
        </input>
        : suffix}

      {editMode && <button onClick={() => onDelete()}><img src='/assets/cross.png'/></button>}
      </div>

      { metricValues && 
          <datalist id="metricVariables">
            {
              metricValues.filter((m) => m.length > 0).map((value) => (
                <option key={value}
                  value={value}>
                </option>
              ))
            }
          </datalist>
      }
    </div>
  );
  
};
export default ScoreListItemValueMetric;