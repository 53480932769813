import { Action, Reducer } from 'redux';
import { Leaderboard, LeaderboardAction } from './types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LeaderboardState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    leaderboard?: Leaderboard;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const unloadedState: LeaderboardState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  leaderboard: undefined,
};

export const reducer: Reducer<LeaderboardState> = (state: LeaderboardState | undefined,
  incomingAction: Action): LeaderboardState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as LeaderboardAction;
  switch (action.type) {
  case 'REQUEST_LEADERBOARD':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      leaderboard: undefined,
    };
  case 'RECEIVE_LEADERBOARD':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      leaderboard: action.payload.leaderboard,
    };
  case 'FAILED_LEADERBOARD':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      leaderboard: undefined,
    };
  default:
    return state;
  }
};
