import * as React from 'react';

type LoginProps = {
    placeholderText: string,
    inputType: string,
    required?: boolean,
    onChange: Function,
    value?: string,
    valid?: boolean,
    name:string,
    label:string,
    maxLength?: number,
    min?: number | string,
    max?: number | string,

}

export default class Input extends React.PureComponent<LoginProps, {}> {
  public render() {
    const {
      label, name, valid, value, maxLength, onChange, required, inputType, placeholderText, min, max
    } = this.props;
    return (
      <label
        htmlFor={name}
        className="input ui-toolkit"
      >
        {label}
        <input
          name={name}
          type={inputType}
          placeholder={placeholderText}
          onChange={(e) => onChange(e.target.value)}
          maxLength = {maxLength}
          value={value}
          required={required}
          className={valid ? 'invalid' : ''}
          min = {min}
          max = {max}
        />
      </label>
    );
  }
}
