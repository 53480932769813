import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as EditRolePermisionsStore from '../../store/configuration/EditRolePermissions';
import * as AuthenticationTypes from '../../store/authentication/types';
import Loading from '../Common/Loading/loading';
import { toast } from 'react-toastify';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  rolePermisions: state.rolePermisions,
});
const mapDispatch = {
  ...EditRolePermisionsStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    items: EditRolePermisionsStore.RolePermission[];
}

class EditRolePermissions extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      items: [],
    };
  }

  public componentDidMount() {
    const {  requestRolePermisions } = this.props;
    const newState: IState = {
      ...this.state,
    };

    const updateState: boolean = false;

    if (updateState) {
      this.setState(newState);
    }

    requestRolePermisions();
  }

  componentDidUpdate(prevProps: IProps) {
    const { rolePermisions: { isUpdated, isErrored } } = this.props;
    
    if(isUpdated && !prevProps.rolePermisions.isUpdated) {
      toast.info('Permissions successfully updated');
    } else if (isErrored && !prevProps.rolePermisions.isErrored) {
      toast.error('There was an error updating permissions', {
        className: 'toast-popup error',
      });
    }
  }
  
  public handleCheckInputUpdate(value: boolean, permissionId: number, roleId: number) {
    const newState: IState = {
      ...this.state,
    };

    const { rolePermisions } = this.props;
    const newItems = rolePermisions.items.map((item) => {
      if (item.id === permissionId) {
        item.roles.map((role) => {
          if (role.id === roleId) {
            role.hasPermission=value;
          }
        });
      }
      return item;
    });

    newState.items = newItems;
    this.setState(newState);
      
  }

  public saveRolePermisions() {
    const { items } = this.state;
    const { saveRolePermisions } = this.props;

    saveRolePermisions(items);
  }

  public render() {
    const { rolePermisions: { isLoading, items, totalRecords } } = this.props;

    const entries = items.sort((a, b) => a.id - b.id);
        
    return (
      <>
        <Page mode="center"
          pageNumber={1}
          padding>
          <div className="wrapper">
            <div className="text-wrapper">
              <h1>Configure Permissions</h1>
              <p>
              Permissions can be edited here. Configurations can be linked to both users and stores.
              </p>
              <p>
              Please note changes to groups will be reflected only when data is processed
              within the current or future reporting periods.
              </p>
            </div>

            <Table
              save
              saveLabel="Save"
              saveClick={() => {this.saveRolePermisions(); }}
              add={false}
              addClick={() => null}
              addLabel=""
              search={false}
              searchClick={() => null}
              initialValue={''}
              pagination={false}
              pageSize={1}
              pageNumber={1}
              pageChange={() => null}
              totalRecords={totalRecords}
            >

              <thead>
                <tr>
                  <th className="padding title"><h2>Action</h2></th>
                  <th className="padding title title-center"
                    colSpan={5}><h2>Permissions</h2></th>
                </tr>
              </thead>
              <tbody>

                {isLoading && 
                <tr><td colSpan={5}><Loading /></td></tr>}

                { !isLoading && <tr>
                  <td className="padding"></td>
                  <td className="padding">Store Manager</td>
                  <td className="padding">Admin User</td>
                  <td className="padding">Operations Manager</td>
                  <td className="padding">Area Manager</td>
                  <td className="padding">Super User</td>
                </tr> }
                
                {!isLoading && entries.map((b) => (
                               
                  <tr key={b.id.toString()} >
                    <td className="padding">{b.permission.name}</td>
                    {b.roles.map((r) => (
                      <td key={r.id.toString()}
                        className="padding">
                                            
                        <input
                          disabled={r.isSuperUser || b.permission.id === AuthenticationTypes.Permissions.EditTenants }
                          type="checkbox"
                          checked={r.hasPermission}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCheckInputUpdate(e.target.checked, b.id,r.id )}
                        />
                      </td>
                    ))}     
                  </tr>
                ))}                               
              </tbody>
            </Table>
          </div>
        </Page>
      </>
    );
  }
}

export default connector(withRouter(EditRolePermissions));
