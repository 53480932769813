import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ItemState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    items: Item[];
    availableOptions: Category[];
}

export interface Item {
    categoryId: number;
    categoryName: string;
    description: string;
    id: number;
    label: string;
}

export interface Category {
    id: number;
    default: number;
    name: string;
    shockGraphic?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestItemsAction {
    type: 'REQUEST_ITEMS';
}

interface ReceiveItemsAction {
    type: 'RECEIVE_ITEMS';
    payload: any;
}

interface FailedItemsAction {
    type: 'FAILED_ITEMS';
}

interface RequestSetItemCategoryAction {
    type: 'REQUEST_SETITEMSCATEGORY';
}

interface ReceiveSetItemCategoryAction {
    type: 'RECEIVE_SETITEMSCATEGORY';
    payload: any;
}

interface FailedSetItemCategoryAction {
    type: 'FAILED_SETITEMSCATEGORY';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties
// contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestItemsAction | ReceiveItemsAction | FailedItemsAction |
    RequestSetItemCategoryAction | ReceiveSetItemCategoryAction | FailedSetItemCategoryAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state
// transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestItems: (filter: string, page: number, pageSize: number):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.items && appState.items.isLoading === false) {
      dispatch({
        type: 'REQUEST_ITEMS',
        http: {
          verb: 'GET',
          endpoint: `/api/itemConfiguration/items?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_ITEMS',
          failureAction: 'FAILED_ITEMS',
        },
      });
    }
  },
  RequestSetItemCategory: (categoryId: string, itemIds: number[]):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SETITEMSCATEGORY',
        http: {
          verb: 'POST',
          endpoint: '/api/itemConfiguration/SetItemCategory',
          successAction: 'RECEIVE_SETITEMSCATEGORY',
          failureAction: 'FAILED_SETITEMSCATEGORY',
          body: {
            categoryId: parseInt(categoryId, 10),
            itemIds,
          },
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: ItemState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  items: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
  availableOptions: [],
};

export const reducer: Reducer<ItemState> = (state: ItemState |
    undefined, incomingAction: Action): ItemState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_ITEMS':
      return {
        errorMessage: '',
        isErrored: false,
        isLoading: true,
        isUpdated: false,

        items: [],
        pageNumber: 1,
        pageSize: 25,
        totalRecords: 0,
        availableOptions: [],
      };
    case 'RECEIVE_ITEMS':
      return {
        errorMessage: '',
        isErrored: false,
        isLoading: false,
        isUpdated: false,

        items: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalRecords: action.payload.totalRecords,
        availableOptions: action.payload.availableOptions,
      };
    case 'FAILED_ITEMS':
      return {
        errorMessage: 'An error occurred.',
        isErrored: true,
        isLoading: false,
        isUpdated: false,

        items: [],
        pageNumber: 1,
        pageSize: 25,
        totalRecords: 0,
        availableOptions: [],
      };
    case 'REQUEST_SETITEMSCATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: true,
        isUpdated: false,
      };
    case 'RECEIVE_SETITEMSCATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: false,
        isUpdated: true,
      };
    case 'FAILED_SETITEMSCATEGORY':
      return {
        ...state,
        errorMessage: 'An error occurred.',
        isErrored: true,
        isLoading: false,
        isUpdated: false,
      };
    default:
      return state;
  }
};
