/* eslint-disable no-case-declarations */
import { Action, Reducer } from 'redux';
import { AreaAction, Period, Template, Metrics, ListMetrics, ItemData, trendData, MetricTrend } from './types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScoreAreaState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    // area level metrics
    id:string;
    tenantId:string;
    siteId:string;
    area:string;
    period?: Period;
    image: string;
    name: string;
    sequence: number;
    dataId: string,

    rawScore: number;
    prevRawScore: number,

    metrics: Metrics;
    listMetrics: ListMetrics;
    prevMetrics: Metrics;
    prevListMetrics: ListMetrics;
    itemData: ItemData[];

    trend?: trendData;
    prevTrend?:trendData;

    itemDataLookup: {[id:string]: ItemData};

    template: Template;

    scoringAreas: ScoreAreaState[];
    areaMetricTrends: MetricTrend[];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel,
// this must not mutate the old state.

const unloadedState: ScoreAreaState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  id:'0',
  tenantId:'',
  name: '',
  siteId:'',
  area:'',
  rawScore: 0.0,
  prevRawScore: 0.0,
  image: '',
  sequence: 0,
  dataId: '',

  metrics:{},
  listMetrics:{},

  prevMetrics:{},
  prevListMetrics: {},

  itemData:[],

  itemDataLookup: {},

  template: { components: { sequence:[], listMetrics:[], textMetrics:[], valueMetrics:[] }, componentValues: [] },

  scoringAreas: [],

  areaMetricTrends: [],
};

export const reducer: Reducer<ScoreAreaState> = (state: ScoreAreaState | undefined,
  incomingAction: Action):ScoreAreaState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as AreaAction;
  switch (action.type) {
  case 'REQUEST_SCOREAREA':
  case 'REQUEST_SCOREAREAS':
  case 'REQUEST_SAVE_SCOREAREA':
  case 'REQUEST_AREA_METRIC_TRENDS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_SCOREAREA':
    action.payload.template.components.listMetrics.forEach(listMetric => {
      let id = 1;
      listMetric.items.metrics.forEach(metric => {
        metric.id = id;
        id++; 
      });
    });

    if(!action.payload.template.components.valueMetrics) {
      action.payload.template.components.valueMetrics = [];
    }
    if(!action.payload.template.components.listMetrics) {
      action.payload.template.components.listMetrics = [];
    }
    if(!action.payload.template.components.textMetrics) {
      action.payload.template.components.textMetrics = [];
    }
    if(!action.payload.template.componentValues) {
      action.payload.template.componentValues = [];
    }

    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      id:action.payload.data.id,
      name: action.payload.data.name,
      tenantId:action.payload.data.tenantId,
      siteId:action.payload.data.siteId,
      area:action.payload.data.area,
      period: action.payload.data.period,
      sequence: action.payload.data.sequence,
      dataId: action.payload.data.dataId,
      image: action.payload.data.image,

      rawScore: action.payload.data.rawScore,
      prevRawScore: action.payload.previousData?.rawScore || 0.0,

      metrics: action.payload.data.metrics,
      listMetrics: action.payload.data.listMetrics,
      prevMetrics: (action.payload.previousData && action.payload.previousData.metrics) || {},
      prevListMetrics: (action.payload.previousData && action.payload.previousData.listMetrics) || {},

      trend: action.payload.trend,
      prevTrend: action.payload.previousTrend,

      itemData: action.payload.items || [],

      itemDataLookup: (action.payload.items && Object.fromEntries(action.payload.items.map(b => [b.label, b]))) || {},

      template: action.payload.template,

      scoringAreas: [],
      areaMetricTrends:[],
    };
  case 'FAILED_SCOREAREA':
  case 'FAILED_SCOREAREAS':
  case 'FAILED_SAVE_SCOREAREA':
  case 'FAILED_AREA_METRIC_TRENDS':
    return {
      ...unloadedState,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
    };
  case 'RECEIVE_SCOREAREAS':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      scoringAreas: action.payload.data,
    };
  case 'RECEIVE_SAVE_SCOREAREA':
    return {
      ...unloadedState,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'RECEIVE_AREA_METRIC_TRENDS':
    return {
      ...state,
      scoringAreas: state.scoringAreas,
      errorMessage: '',
      areaMetricTrends: action.payload,
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  default:
    return state;
  }
};
