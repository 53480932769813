import { AppThunkAction } from '../index';
import { AuthenticationAction } from './types';
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will
// trigger a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestSsoUser: ():
  AppThunkAction<AuthenticationAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.authentication && appState.authentication.isLoading === false) {
      dispatch({
        type: 'REQUEST_AUTHENTICATION',
        http: {
          verb: 'GET',
          endpoint: '/api/authentication/GetSsoUserDetails', //data endpoint
          successAction: 'RECEIVE_AUTHENTICATION',
          failureAction: 'FAILED_AUTHENTICATION',
        },
      });
    }
  },
  setCurrentReportingEntity: (reportingEntityId: string):
  AppThunkAction<AuthenticationAction> => (dispatch) => {
    dispatch({ type: 'SET_CURRENTENTITY', reportingEntityId });
  },
  setCurrentDate: (dateKey: string): AppThunkAction<AuthenticationAction> => (dispatch) => {
    dispatch({ type: 'SET_CURRENTDATE', dateKey });
  },
};