import { AppThunkAction } from '../index';
import { LeaderboardAction } from './types';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will
// trigger a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestLeaderboard: (reportingDate: string):
  AppThunkAction<LeaderboardAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.score && appState.score.isLoading === false) {
      dispatch({
        type: 'REQUEST_LEADERBOARD',
        http: {
          verb: 'GET',
          endpoint: `/api/leaderboard/leaderboard?reportingStartDate=${reportingDate}`,
          successAction: 'RECEIVE_LEADERBOARD',
          failureAction: 'FAILED_LEADERBOARD',
        },
      });
    }
  },
};
