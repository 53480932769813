import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as AuthenticationTypes from '../../store/authentication/types';
import * as ReactGA from 'react-ga';
import * as TenantsStore from '../../store/dashboard/Tenants';
import * as AuthActions from '../../store/authentication/actions';
import Tenants from './Tenants';
import EditTenant from './EditTenant';
import ScoringItems from './ScoringItems';
import AddScoringItems from './AddScoringItems';
import ScoringEntities from './ScoringEntities';
import EditScoringEntities from './EditScoringEntities';
import scoringAreas from './scoringAreas';
import EditScoringArea from './EditScoringArea';
import { AuthManager } from '../../AuthManager';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  tenants: state.tenants,
});
const mapDispatch = {
  ...TenantsStore.actionCreators,
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

class Dashboard extends React.PureComponent<IProps, {}> {
  
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate() {
    const { tenants } = this.props;

    if (tenants.isUpdated && tenants.switchedTenant) {
      const authManager = new AuthManager();
      authManager.logout();
    }
  }

  public render() {
    const { history, authentication } = this.props;
    const permissions = (authentication && authentication.permissions) || [];
    const allPermissions = AuthenticationTypes.Permissions;
    const tenantId = authentication.tenantDetails && authentication.tenantDetails.tenantId ? authentication.tenantDetails.tenantId : 0;

    return (
      <div className="dashboard ui-toolkit">
        <Page mode="center dashboard"
          pageNumber={1}
          padding>
          <div className="dashboard-wrapper">
            <div className="menu">
              <div className='tenant-header'
                onClick={() => history.push('/dashboard')}>
                <img src={(authentication.tenantDetails && `./assets/tenants/${authentication.tenantDetails.logo}`)}/>
                <p>{(authentication.tenantDetails && authentication.tenantDetails.name)}</p> 
              </div> 
              <button
                type="button"
                onClick={() => history.push(`/dashboard/tenant/${tenantId}/scoringEntities`)}
              >
                Edit Scoring Entities
                <div className="chevron" />
              </button>

              <button
                type="button"
                onClick={() => history.push(`/dashboard/tenant/${tenantId}/scoringItems`)}
              >
                Edit Scoring Items
                <div className="chevron" />
              </button>

              <button
                type="button"
                onClick={() => history.push(`/dashboard/tenant/${tenantId}/scoringAreas`)}
              >
                Edit Scoring Areas
                <div className="chevron" />
              </button>
            </div>

            <div className="dashboard-content">
              <Switch>
                { (<Route exact
                  path="/dashboard/"
                  component={Tenants} />)}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && (<Route exact
                          path="/dashboard"
                          component={Tenants} />)}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && (<Route exact
                          path="/dashboard/tenant/:tenantId/scoringEntities/"
                          component={ScoringEntities} />)}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && <Route exact
                          path="/dashboard/tenant/:tenantId/editScoringEntity/:scoringEntityId"
                          component={EditScoringEntities} />}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && (<Route exact
                          path="/dashboard/tenant/:tenantId/scoringAreas/"
                          component={scoringAreas} />)}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && <Route exact
                          path="/dashboard/tenant/:tenantId/editScoringArea/:scoringAreaId"
                          component={EditScoringArea} />}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && <Route exact
                          path="/dashboard/tenant/:tenantId/scoringItems/"
                          component={ScoringItems} />}
                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && <Route exact
                          path="/dashboard/tenant/:tenantId/addScoringItems"
                          component={AddScoringItems} />}

                {permissions.indexOf(allPermissions.EditTenants) > -1
                        && <Route exact
                          path="/dashboard/editTenant/:id"
                          component={EditTenant} />}
              </Switch>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}

export default connector(withRouter(Dashboard));
