import * as React from 'react';

type SearchProps = {
    placeholderText: string,
    initialValue: string,
    onSearch: Function
}

type State = {
    searchString: string,
}

export default class Search extends React.PureComponent<SearchProps, State> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      searchString: props.initialValue,
    };
  }

  public componentDidUpdate(prevProps: SearchProps) {
    const { initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
      this.setState({ searchString: initialValue });
    }
  }

  public render() {
    const { placeholderText, onSearch } = this.props;
    const { searchString } = this.state;

    return (
      <div className="search ui-toolkit">
        <input
          type="text"
          placeholder={placeholderText}
          value={searchString}
          onChange={(e) => this.setState({ searchString: e.target.value })}
        />
        <button type="button" onClick={() => onSearch(searchString)}>Search</button>
      </div>
    );
  }
}
