import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as ReactGA from 'react-ga';
import * as ScoringEntriesStore from '../../store/dashboard/ScoringEntities';
import Table from '../../uiToolkit/containers/table';
import Error from '../Common/Error/error';
import Loading from '../Common/Loading/loading';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  scoringEntities: state.scoringEntities,
});
const mapDispatch = {
  ...ScoringEntriesStore.actionCreators,
};

type TParams = { tenantId: string, scoringEntityId: string };
const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

class ScoringEntities extends React.PureComponent<IProps, {}> {

  public componentDidMount() {
    const { match: {
      params: {
        tenantId,
      },
    }, requestScoringEntities } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    requestScoringEntities(tenantId);
  }

  public render() {
    const { match: {
      params: {
        tenantId,
      },
    }, history, scoringEntities } = this.props;

    return (
      <>
        <div className="text-wrapper scoring-entries">
          <h1>Scoring Entities</h1>
          <p>A scoring entity is characterised as the entity that receives a final score. </p>
          <p>For example a common scoring entity would be a shop. </p>
        </div>
        {!scoringEntities.isLoading && scoringEntities.scoringEntities.length === 0 &&
          <div className="scoring-entries">
            <div className='actions'>
              <p>The current tenant does not have any scoring Entities</p>
              <button
                type="button"
                onClick={() => history.push(`/dashboard/tenant/${tenantId}/editScoringEntity/0`)}
              >
                <div className="label">Create new Scoring Entities</div>
                <div className="plus-icon">
                          +
                  <div className="circle-plus-icon"></div>
                </div>
              </button>
            </div>
          </div>
        }
        <div className="dashboard ui-toolkit">
          <Page mode="center dashboard"
            pageNumber={1}
            padding>
            <div className="dashboard-wrapper">
              <div className="dashboard-content">

                <Page mode="center"
                  pageNumber={1}>
                  <Table
                    add
                    addClick={() => this.props.history.push(`/dashboard/tenant/${tenantId}/editScoringEntity/0`)}
                    addLabel="Create Scoring Entity"
                    search={false}
                    searchClick={() => null}
                    initialValue=""
                    pagination={false}
                    pageSize={1}
                    pageNumber={1}
                    pageChange={() => null}
                    totalRecords={1}
                  >
                    <thead>
                      <tr>
                        <th className="padding title">Entity Name</th>
                        <th className="padding title">Entity Type</th>
                        <th className="padding title">Entity Number</th>
                        <th className="padding title">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scoringEntities.isLoading
                                          && <tr><td colSpan={4}><Loading/></td></tr>}

                      {scoringEntities.isErrored
                                          && <tr><td colSpan={4}><Error text={scoringEntities.errorMessage} /></td></tr>}

                      {!scoringEntities.isLoading && scoringEntities.scoringEntities.length > 0 && scoringEntities.scoringEntities.map((s) => (
                        <tr
                          key={s.id.toString()}
                          onClick={() => history.push(`/dashboard/tenant/${tenantId}/editScoringEntity/${s.id}`)}
                        >
                          <td className="padding">{s.name}</td>
                          <td className="padding">{s.type}</td>
                          <td className="padding">{s.dataID}</td>
                          <td className="padding"><button><img className='edit'
                            src='/assets/edit-icon.svg' /></button></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Page>
              </div>
            </div>
          </Page>
        </div>
      </>
    );
  }
}

export default connector(withRouter(ScoringEntities));
