/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScoreWeightingState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    scoreWeighting: ScoreWeighting;
}

export interface ScoreWeighting {
    id: number;
    currentDataWeight: number;
    recentDataWeight: number;
    comparativeScoreWeight: number;
    progressiveScoreWeight: number;
} 

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state
// transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

interface RequestScoreWeightingsAction {
    type: 'REQUEST_SCOREWEIGHTINGS';
}

interface ReceiveScoreWeightingsAction {
    type: 'RECEIVE_SCOREWEIGHTINGS';
    payload: any;
}

interface FailedScoreWeightingsAction {
    type: 'FAILED_SCOREWEIGHTINGS';
}

interface RequestSaveScoreWeightingsAction {
    type: 'REQUEST_SAVE_SCOREWEIGHTINGS';
}

interface ReceiveSaveScoreWeightingsAction {
    type: 'RECEIVE_SAVE_SCOREWEIGHTINGS';
    payload: any;
}

interface FailedSaveScoreWeightingsAction {
    type: 'FAILED_SAVE_SCOREWEIGHTINGS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestScoreWeightingsAction | ReceiveScoreWeightingsAction | FailedScoreWeightingsAction |
    RequestSaveScoreWeightingsAction | ReceiveSaveScoreWeightingsAction | FailedSaveScoreWeightingsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a
// state transition.
// They don't directly mutate state, but they can have external side-effects (such as
// loading data).

export const actionCreators = {
  requestScoreWeightings: ():
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreWeightings && appState.scoreWeightings.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCOREWEIGHTINGS',
        http: {
          verb: 'GET',
          endpoint: '/api/scoreWeighting/ScoreWeightings',
          successAction: 'RECEIVE_SCOREWEIGHTINGS',
          failureAction: 'FAILED_SCOREWEIGHTINGS',
        },
      });
    }
  },
  
  saveScoreWeightings: (data: ScoreWeighting):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreWeightings && appState.scoreWeightings.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_SCOREWEIGHTINGS',
        http: {
          verb: 'POST',
          endpoint: '/api/scoreWeighting/save',
          successAction: 'RECEIVE_SAVE_SCOREWEIGHTINGS',
          failureAction: 'FAILED_SAVE_SCOREWEIGHTINGS',
          body: {
            ...data,
          },
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const unloadedState: ScoreWeightingState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  scoreWeighting: {
    id: 0,
    currentDataWeight: 0,
    recentDataWeight: 0,
    comparativeScoreWeight: 0,
    progressiveScoreWeight: 0,
  },
};

export const reducer: Reducer<ScoreWeightingState> = (state:
    ScoreWeightingState | undefined, incomingAction: Action): ScoreWeightingState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_SCOREWEIGHTINGS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      scoreWeighting: {
        id: 0,
        currentDataWeight: 0,
        recentDataWeight: 0,
        comparativeScoreWeight: 0,
        progressiveScoreWeight: 0,
      },
    };
  case 'RECEIVE_SCOREWEIGHTINGS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      scoreWeighting: {
        id: action.payload.id,
        currentDataWeight: action.payload.currentDataWeight * 100,
        recentDataWeight: action.payload.recentDataWeight * 100,
        comparativeScoreWeight: action.payload.comparativeScoreWeight * 100,
        progressiveScoreWeight: action.payload.progressiveScoreWeight * 100,
      },
    };
  case 'FAILED_SCOREWEIGHTINGS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      scoreWeighting: {
        id: 0,
        currentDataWeight: 0,
        recentDataWeight: 0,
        comparativeScoreWeight: 0,
        progressiveScoreWeight: 0,
      },
    };
  case 'REQUEST_SAVE_SCOREWEIGHTINGS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_SCOREWEIGHTINGS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_SCOREWEIGHTINGS':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
};
