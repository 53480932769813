import * as React from 'react';
import moment from 'moment';
import * as Cron from 'cron-parser';
import ReactGA from 'react-ga';

type CronDateDropdownProps = {
    placeholderText: string,
    required?: boolean,
    cronString: string,
    onChange: Function,
    currentDateKey: string,
    isGrey?: boolean
}

export default class CronDateDropdown extends React.PureComponent<CronDateDropdownProps, {}> {
  private generateDropDownOption(currentDate: string, previousDate: string): JSX.Element {
    const text = `${moment(previousDate).format('Do MMM YYYY')} - ${moment(currentDate).format('Do MMM YYYY')}`;
    const value = moment(previousDate).format('YYYY-MM-DD');

    return (<option value={value}
      key={value}>{text}</option>);
  }

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public render() {
    const {
      cronString, currentDateKey, onChange, isGrey, placeholderText,
    } = this.props;

    let date = new Date();

    let startDate = new Date(date.setMonth(date.getMonth() - 6));

    if (startDate < new Date('2020-06-01')) {
      startDate = new Date('2020-06-01');
    }

    date = new Date();
    const endDate = new Date();

    const cron = Cron.parseExpression(cronString, {
      currentDate: startDate,
      endDate,
      iterator: true,
    });

    const dropDownElements: JSX.Element[] = [];
    let previousDate = startDate;

    while (true) {
      if (cron.hasNext()) {
        const obj: any = cron.next();
        
        if (dropDownElements.findIndex(x => x.key === previousDate.toString()) === -1) {
          dropDownElements.push(
            this.generateDropDownOption(obj.value.toString(), previousDate.toString()),
          );
        }
        previousDate = obj.value.toString();
      } else {
        break;
      }
    }

    if (moment(endDate).diff(moment(previousDate), 'days') > 1) {
      if (dropDownElements.findIndex(x => x.key === previousDate.toString()) === -1) {
        dropDownElements.push(
          this.generateDropDownOption(endDate.toString(), previousDate.toString()),
        );
      }
    }

    return (
      <div className={`cron-dropdown ui-toolkit ${isGrey ? 'grey' : ''}`}>
        <select
          placeholder={placeholderText}
          onChange={(e) => onChange(e.target.value)}
          value={currentDateKey}
        >
          {dropDownElements.reverse()}
        </select>
      </div>
    );
  }
}
