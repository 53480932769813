import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import MenuBar from '../MenuBar/menuBar';
import SideMenu from '../SideMenu/sideMenu';
import Footer from '../Footer/footer';
import NavItem from '../../../uiToolkit/components/navItem';
import ScrollToTop from '../../../uiToolkit/components/scrollToTop';
import { ApplicationState } from '../../../store';
import ReactGA from 'react-ga';
import * as AuthActions from '../../../store/authentication/actions';
import * as AuthenticationTypes from '../../../store/authentication/types';
import { AuthenticatedTemplate, MsalContext } from '@azure/msal-react';
import { AuthManager } from '../../../AuthManager';
import Loading from '../Loading/loading';
import { Message } from '../Message/message';
import Login from '../../Login/Login';

import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import NavItemWithIcon from '../../../uiToolkit/components/navItemWithIcon';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});
const mapDispatch = AuthActions.actionCreators;

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type LayoutProps =
    PropsFromRedux
    & PropsType;

class Layout extends React.PureComponent<LayoutProps, {}> {
  static contextType = MsalContext;
  
  private getNavigationItems() {
    const {
      history, authentication,
    } = this.props;

    const permissions = (authentication && authentication.permissions) || [];
    const selectedReportingEntity = (authentication && authentication.selectedReportingEntity) || '-';
    
    const authManager = new AuthManager();

    return (
      <>
        <NavItem
          onClick={() => history.push('/leaderboard')}
          text="Leaderboard"
          imagePath="/assets/menu-icons/leaderboard.svg"
          imageAltTest="Tournament bracket"
        />
        <NavItem
          onClick={() => history.push(`/score/${selectedReportingEntity}`)}
          disabled={!selectedReportingEntity}
          text="Score Card"
          imagePath="/assets/menu-icons/speed.svg"
          imageAltTest="Speedometer"
        />
      
        <NavItemWithIcon
          onClick={() => history.push('/product/leaderboard')}
          text="Product Leaderboard"
          icon={faBasketShopping}
        />
        {permissions.length > 0 && permissions.indexOf(AuthenticationTypes.Permissions.EditItemCategories) > -1 && (
          <NavItem
            onClick={() => history.push('/configuration')}
            text="Configuration"
            imagePath="/assets/menu-icons/cogs.svg"
            imageAltTest="Cogs"
            extraClass="no-click"
          />
        )}
        {permissions.length > 0 && permissions.indexOf(AuthenticationTypes.Permissions.EditTenants) > -1 && (
          <NavItem
            onClick={() => history.push('/dashboard')}
            text="Orderly Admin"
            imagePath="/assets/logo-orderly-no-text.png"
            imageAltTest="Orderly Icon"
            extraClass="no-click"
          />
        )}
        <NavItem
          onClick={() => authManager.logout()}
          text="Logout"
          imagePath="/assets/menu-icons/logout.svg"
          imageAltTest="exit door"
        />
      </>
    );
  }

  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
    this.setState({ isAuthenticated: this.context.accounts.length > 0 });
  }

  public render() {
    const {
      children,
      authentication,
    } = this.props;

    const authManager = new AuthManager();

    return (
      <>
        {
          authentication.tenantDetails || !authManager.IsUserAuthenticated() ?
            <div className="layout">
              <ScrollToTop />
              <AuthenticatedTemplate>
                <SideMenu>
                  {this.getNavigationItems()}
                </SideMenu>
              </AuthenticatedTemplate>
              <div className="layout-contents">
                <MenuBar>
                  {authManager.IsUserAuthenticated() && this.getNavigationItems()}
                </MenuBar>
                {children}
                <Footer />
              </div>
            </div>
            :
            <>
              {
                authentication.isLoading && <Loading />
              }

              {
                authentication.isErrored && <Login />
              }
            </>
            
        }
      </>
    );
  }
}

export default connector(withRouter(Layout));
