import * as React from 'react';
import TableAddEntry from '../components/tableAddEntry';
import Search from '../components/search';
import Pagination from '../components/pagination';
import TableSaveEntry from '../components/tableSaveEntry'

interface IProps {
    children: React.ReactNode,

    add: boolean,
    addLabel: string,
    addClick: Function,

    save?: boolean,
    saveLabel?:string,
    saveClick?: Function,

    search: boolean,
    searchClick: Function,
    initialValue: string,

    pagination: boolean,
    pageSize: number,
    pageNumber: number,
    totalRecords: number,
    pageChange: Function
}

const Page = ({
  search,
  searchClick,
  initialValue,
  children, add, addLabel,
  addClick, pagination, pageSize,
  pageNumber,
  totalRecords,
  pageChange,
  save,
  saveClick,
  saveLabel
}: IProps) => (
  <div className="table ui-toolkit">

    {add
    && (
    <TableAddEntry
      id="0"
      label={addLabel}
      onClick={addClick}
      fade={false}
    />
    )}

   {save && (
    <TableSaveEntry
      id="1"
      label={saveLabel ? saveLabel :''}
      onClick={saveClick ? saveClick : () => { }}
      fade={false}
    />
     )}

    {search && (
    <Search
      placeholderText="search"
      onSearch={searchClick}
      initialValue={initialValue}
    />
    )}

    <table>
      {children}
    </table>

    {pagination
    && (
    <Pagination
      pageSize={pageSize}
      pageNumber={pageNumber}
      totalRecords={totalRecords}
      pageChange={pageChange}
    />
    )}
  </div>
);

export default Page;
