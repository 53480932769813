/* eslint-disable no-unused-vars */
import { AnyAction } from 'redux';

export interface Product {
    rawScore: number,
    itemNumber: string,
    itemDescription: string,
    siteID: string,
    metrics: {
        retailPrice: number[],
        profitability: number[],
        wasteValue: number,
        salesVolume: number,
        wastePercent: number,
        availability: number,
        missedSales: number,
        wasteQty: number,
        profitMargin: number,
    },
    scoreAreas: ProductScoreArea[],
    indicators: ProductScoreIndicator[],
    missingScorecards: string[]
}

export interface ProductScoreArea {
    area: string,
    rawScore: number,
}

export interface ProductScoreIndicator {
    description: string,
    result: string,
}

export enum ScoringEntityTypes {
    Tenant = 'tenant',
    Franchise = 'franchise',
    Area = 'area',
    Store = 'store',
}

export enum ProductScoreIndicatorResult {
    Bad = 'Bad',
    Average = 'Average',
    Good = 'Good',
}

export interface ProductResponse{
    product: Product
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions
// of state transitions.
// They do not themselves have any side-effects; they just describe
// something that is going to happen.

export interface RequestProductAction extends AnyAction {
    type: 'REQUEST_PRODUCT';
}

export interface ReceiveProductAction extends AnyAction {
    type: 'RECEIVE_PRODUCT';
    payload: ProductResponse;
}

export interface FailedProductAction extends AnyAction {
    type: 'FAILED_PRODUCT';
}

// Declare a 'discriminated union' type. This guarantees that all references to
// 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ProductAction = 
    RequestProductAction | 
    ReceiveProductAction | 
    FailedProductAction;