import * as React from 'react';

interface IProps {
    text: string,
    tipToggled: boolean;
    handleParentClick: Function,
    handleGrandparentClick?: Function,
    editMode? : boolean,
    onInputChange: Function,
    hideButton?: boolean,
}

interface IState {
}


class ToolTips extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  public render() {
    const {
      text,
      tipToggled,
      handleGrandparentClick,
      handleParentClick,
      editMode,
      onInputChange,
      hideButton,
    } = this.props;

    return (

      <div className="tooltip-wrapper"> 
        {!hideButton &&
          <button 
            className="tooltip-toggle"
            onClick={(e) => 
              (!editMode && handleParentClick(e, !tipToggled), handleGrandparentClick && handleGrandparentClick(e, false))
            }
          > ? </button>
        }
        <div className="tooltip-content">
          <div className={`tooltip-show ${tipToggled ? '' : 'tooltip-hide'}`}>
            {
              editMode ? 
                <input placeholder='metrics tooltip'
                  type="text"
                  onChange={(e) => onInputChange(e.target.value, 'tooltip')} 
                  value={text}/> 
                :  
                text
            } 
          </div>
        </div>
      </div>
    );
  }
}

export default ToolTips;
