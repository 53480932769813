import { Permissions } from '../../store/authentication/types';

class PermissionHelper{
  private currentPermissions;
  constructor(currentPermissions: number[]){
    this.currentPermissions = currentPermissions || [];
  }

  HasPermission(permission: Permissions){
    return this.currentPermissions && this.currentPermissions.indexOf(permission) > -1;
  }

  IsStoreUser(){
    return this.currentPermissions.length === 1 && this.currentPermissions.indexOf(Permissions.ViewSuggestionsHistory) > -1;
  }

  IsOperationsManager(){
    return this.currentPermissions.length > 0 && this.currentPermissions.indexOf(Permissions.ViewFranchiseLevelScoreData) > -1;
  }

  HasAccessToMultipleStores(){
    return this.currentPermissions.length > 0 && this.currentPermissions.indexOf(Permissions.ViewAdminDashboard) > -1;
  }

  IsAdmin(){
    return this.currentPermissions.length > 0 && this.currentPermissions.indexOf(Permissions.ViewTenantLevelScoreData) > -1;
  }
}

export default(PermissionHelper);
