import * as React from 'react';
import ReactGA from 'react-ga';

interface IProps {
    score: number;
    maxScore: number;
    index: number;
}

class ProgressBar extends React.PureComponent<IProps, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public render() {
    const {
      maxScore,
      score,
      index,
    } = this.props;

    const percentageUnfilled = ((score / maxScore) * 150);

    return (
      <div className="progress-bar ui-toolkit">
        <svg viewBox="0,0 160,6"
          preserveAspectRatio="none">
          <path
            id={`progressbar-${index}`}
            className="bar-filled"
            d={`M 5,0 
                L 155,0
                A 3,3 0 0,1 155,6
                L 5,6
                A 3,3 0 0,1 5,0`}
          />
          {score !== maxScore
          && (
            <path
              id={`progressbar-fill-${index}`}
              className="bar-unfilled"
              d={`M ${percentageUnfilled + 5},0 
                  L 155,0
                  A 3,3 0 0,1 155,6
                  L ${percentageUnfilled + 5},6
                  A 3,3 0 0,0 ${percentageUnfilled + 5},0`}
            />
          )}
        </svg>
      </div>

    );
  }
}

export default ProgressBar;
