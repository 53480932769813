import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as ReactGA from 'react-ga';
import * as ScoringEntriesStore from '../../store/dashboard/Tenants';
import { AuthManager } from '../../AuthManager';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});
const mapDispatch = {
  ...ScoringEntriesStore.actionCreators,
};

type TParams = { tenantId: string, scoringItemId: string };
const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

class DownloadGuide extends React.PureComponent<IProps, {}> {

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public handleDownload() {
    const authManager = new AuthManager();
    
    authManager.generateAccessToken(() => {
      const token = authManager.getAccessToken();
      
      fetch('/api/help/downloadfile/?fileName=userguide.pdf', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `${token}`,
        },
      })
        .then((res) => { return res.blob(); })
        .then((data) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = 'User Guide.pdf';
          link.click();
        });

    });
  }

  public render() {
    return (
      <div className="text-wrapper user-guide">
        <h1>User Guide</h1>
        <p>Click the button below to download the Orderly Score user guide </p>
        <div className='actions'>
          <button
            type="button"
            onClick={(() => this.handleDownload())}
          >
                        Download User Guide
          </button>
        </div>
      </div>
    );
  }
}

export default connector(withRouter(DownloadGuide));
