import * as React from 'react';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import * as AuthActions from '../../../store/authentication/actions';
import { ApplicationState } from '../../../store';
import { ConnectedProps, connect } from 'react-redux';
import PermissionHelper from '../../../uiToolkit/helpers/PermissionHelper';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});

const mapDispatch = {
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = {
  placeholderText: string;
  onChange: Function;
  selectedEntity: string,
  isGrey?: boolean;
} & PropsFromRedux;

const ReportingEntityDropdown = (props: IProps) => {
  const {
    onChange,
    isGrey,
    placeholderText,
    selectedEntity,
    authentication: { scoringEntities, franchises, tenantDetails, permissions },
  } = props;
  const permHelper = new PermissionHelper(permissions);

  const generateDropDownOptions = (): JSX.Element[] => {
    const dropdownOptions: JSX.Element[] = [];

    if (permHelper.IsAdmin()) {
      dropdownOptions.push(
        <option key={'tenant'}
          disabled={true}
          className='separator-option'
          value={'tenant'}>-----Tenant-----</option>,
      );
      dropdownOptions.push(
        <option value={tenantDetails?.tenantId}
          key={tenantDetails?.tenantId}>
          {tenantDetails?.name}
        </option>,
      );

      dropdownOptions.push(
        <option key={'franchises'}
          disabled={true}
          className='separator-option'
          value={'franchises'}>---Franchises---</option>,
      );
      franchises && franchises.filter(x => x.length > 0).forEach((franchise) => {
        dropdownOptions.push(
          <option value={franchise}
            key={franchise}>
            {franchise}
          </option>,
        );
      });
    } else if (permHelper.IsOperationsManager()) {
      if(scoringEntities) {
        const franchise = scoringEntities[0].franchise;
        dropdownOptions.push(
          <option value={franchise}
            key={franchise}>
            {franchise}
          </option>,
        );
      }
    } else if(permHelper.HasAccessToMultipleStores()) {
      dropdownOptions.push(
        <option value='area'
          key='area'>
          {'Your Area'}
        </option>,
      );
    }

    if (scoringEntities) {
      dropdownOptions.push(
        <option key={'stores'}
          disabled={true}
          className='separator-option'
          value={'stores'}>-----Stores-----</option>,
      );
      dropdownOptions.push(
        ...scoringEntities.map((s) => (
          <option value={s.dataId}
            key={s.dataId}>
            {s.name}
          </option>
        )),
      );
    }

    return dropdownOptions;
  };



  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        permHelper.HasAccessToMultipleStores() && 
          <div className={`scoring-entity-dropdown ui-toolkit ${isGrey ? 'grey' : ''}`}>
            <select
              placeholder={placeholderText}
              onChange={(e) => onChange(e.target.value)}
              value={selectedEntity}>
              {generateDropDownOptions()}
            </select>
          </div>
      }
    </>
  );
};

export default connector(ReportingEntityDropdown);

