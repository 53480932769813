import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import ToolTips from '../../Common/ToolTips/toolTips';

type IProps = {
    id: number,
    label: string,
    value: string,
    total?: string,
    change?: number,
    increaseGood: boolean,
    format: string,
    tooltip: string,
    grandparentClicked: boolean,
    handleGrandparentClicked: Function,
    editMode?: boolean,
    metricVariables?: string[],
    onInputChange: Function,
    onDelete: Function,
    showGraph: boolean;
    onToggleGraph: () => void;
}
const METRIC_FORMATS_WITH_GRAPH = ['integer', 'currency', 'percentage'];

const ScoreValueMetric = (props: IProps) => {

  const [tipToggled, setTipToggled] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (props.grandparentClicked){
      setTipToggled(false);
    }
  },[props.grandparentClicked]);

  const handleClick = (e: React.MouseEvent, value: boolean) => {
    e.stopPropagation();
    setTipToggled(value);
  };

  const formatTime = (value: number) => {
    let displayValue = '';
    if (value < 60) {
      displayValue = value + 's';
    } else {
      const minutes = Math.round(value / 60);
      displayValue = `${minutes.toFixed()}min`;
    }
    return displayValue;
  };

  const hasMetricGraph =(): boolean => {
    return METRIC_FORMATS_WITH_GRAPH.includes(props.format);
  };


  const {
    change, label, total, value, increaseGood, format, tooltip, editMode, metricVariables, onInputChange, onDelete, id,
  } = props;
    
  let displayValue = value;
  let displayChange = '-';

  if(format === 'currency') {
    displayValue = value && Number.parseFloat(value).toLocaleString(undefined, {
      style: 'currency',
      currency: 'GBP',
    }); 

    if(change) {
      displayChange = (change > 0 ? '+' : '') + change.toLocaleString(undefined, {
        style: 'currency',
        currency: 'GBP',
      });
    }
  } else if (format === 'integer') {
    displayValue = value && Number.parseInt(value).toFixed(0);

    if(change){
      displayChange = change > 0 ? '+' + change.toFixed(0) : change.toFixed(0);
    }
  } else if (format === 'date'){
    if(value && value.length === 10){
      displayValue = moment(value).format('ddd Do MMM YYYY');
        
    }

    displayChange = '';

  } else if (format === 'percentage'){
    displayValue = value && Number.parseInt(value).toFixed(0) + '%';

    if(change){
      displayChange = change > 0 ? '+' + change.toFixed(0) :change.toFixed(0)+ '%' ;
    }
  } else if (format === 'text'){
    displayChange = '';
  } else if(format === 'timelapse') {
    const elapsedTime = Number.parseInt(value);
      
    displayValue = formatTime(elapsedTime);

    if(change) {
      displayChange = change > 0 ? '+' + formatTime(elapsedTime): '-' + formatTime(Math.abs(change));
    }
  }

  let changeDirection = 'none';
  let changes = true;

  if (displayChange && displayChange.length > 0) {
    if (displayChange.indexOf('+') > -1) {
      changeDirection = 'increase';
    } else if (displayChange.indexOf('-') > -1) {
      if (displayChange.length > 1) {
        changeDirection = 'decrease';
      }
    }
  } else {
    changes = false;
  }

  return (
    <div onClick={ hasMetricGraph() ? props.onToggleGraph : undefined}
      className={`score-value-metric ui-toolkit  ${hasMetricGraph() ? 'pointer-on-hover' : ''}`}
    >
      <div className = "list-lable">

        {
          editMode ? 
            <div className='list-table-container'>
              <input type="text"
                className='metric-sequence'
                value={id} 
                onChange={(e) => onInputChange(e.target.value, 'id')}/>
                
              <input placeholder='Label'
                onChange={(e) => onInputChange(e.target.value, 'label')}
                value={label}
                type="text" 
              /> 
            </div>
            :
            hasMetricGraph() 
              ? 
              <span className="pointer-on-hover"
                onClick={props.onToggleGraph}>{label}</span>
              :
              label
        }

        <div className = "tooltip-container">
          {editMode || tooltip !== '' ?
            <ToolTips
              handleParentClick={handleClick}
              handleGrandparentClick={props.handleGrandparentClicked}
              tipToggled={editMode ? true : props.grandparentClicked? false : tipToggled}
              text = {tooltip} 
              onInputChange={(value: string, fieldName: string) => onInputChange(value, fieldName)} 
              editMode={editMode}
            /> : null}
        </div>
        
      </div>
      <div className="score-holder"> 
        {editMode || (total && total !== '0')
          ? (
            <div className="score">
              {editMode ? 
                <select value={value}
                  onChange={(e) => onInputChange(e.target.value, 'value')} 
                  className='variable-selector'>
                  <option 
                    key={''}>
                    {''}
                  </option>
                  {metricVariables && 
                    metricVariables.filter((m) => m.length > 0).map((variable) => (
                      <option 
                        key={variable}>
                        {variable}
                      </option>
                    ))}
                </select>
                : displayValue}
              <span className="score-total">
                  /
                {
                  editMode ? 
                    <input type='text'
                      list='valueMetrics'
                      className='variable-selector total'
                      onChange={(e) => onInputChange(e.target.value, 'total')}
                      value={total} />
                    : 
                    total}
              </span>
              {
                editMode &&
                  <div onClick={() => onInputChange(!increaseGood, 'increaseGood')}
                    className={`change change-direction-increase change-good-${increaseGood}`}>
                    <svg>
                      <path d={`M 10,0 
                                L 20,15  
                                L 0,15
                                L 10,0
                                `}
                      />
                    </svg>
                  </div>
              }
              {
                editMode &&
                  <button onClick={() => onDelete()}>
                    <img className='delete'
                      src='/assets/cross.png'/>
                  </button>
              }
              <datalist id="valueMetrics">
                {
                  metricVariables && metricVariables.map(m => (
                    <option
                      key={m}
                      value={m}>
                    </option>
                  ))
                }
              </datalist>
            </div>
          )
          : (<div className="score">{displayValue}</div>)}
        {!editMode && changes && (
          <div className="changes">
            <div className={`change-value change-direction-${changeDirection} change-good-${increaseGood}`}>
              {displayChange}
            </div>
            <div className={`change change-direction-${changeDirection} change-good-${increaseGood}`}>
              <svg>
                <path d={`M 10,0 
                            L 20,15  
                            L 0,15
                            L 10,0
                            `}
                />
              </svg>
            </div>
          </div>
        )} 
      </div>
    </div>
  );
};

export default ScoreValueMetric;