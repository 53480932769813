import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import * as React from 'react';
import ReactGA from 'react-ga';

interface IProps {
  children?: React.ReactNode
}

export default class SideMenu extends React.PureComponent<IProps, { isOpen: boolean }> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

    private toggle = () => {
      const { isOpen } = this.state;

      this.setState({
        isOpen: !isOpen,
      });
    }

    public render() {
      const { children } = this.props;
      const { isOpen } = this.state;

      return (
        <div className={`side-bar ui-toolkit ${isOpen ? 'expanded' : ''}`}>
          <div>
            <AuthenticatedTemplate>
              <button type="button"
                className="toggle-button"
                onClick={() => this.toggle()}>
                <img
                  src="/assets/menu-icons/menu.svg"
                  alt="Burger menu icon"
                />
              </button>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <div className="counter-balance" />
            </UnauthenticatedTemplate>
          </div>
          <div>
            {children}
          </div>
        </div>
      );
    }
}
