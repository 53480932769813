import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Avatar } from '../../Common/Avatar/avatar';

interface IProps {
    companyImageLink: string,
    backgroundImageLink: string,
    scoreId: string,
    scoreName: string,
    companyName: string,
    ownerName: string,
}

const UserSummary = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { scoreName, companyName, ownerName, companyImageLink, backgroundImageLink, scoreId } = props;

  return (
    <div className="user-summary ui-toolkit"
      style={{ backgroundImage: `url(${backgroundImageLink})` }}>
      <Avatar companyImageLink={companyImageLink}
        label={scoreId} />
      <div className="user-details">
        <div className="name">{scoreName.replace(scoreId, '').trim()}</div>
        <div className="tenant">{companyName}</div>
        <div className="owner">{ownerName}</div>
      </div>
    </div>
  );
};
export default UserSummary;