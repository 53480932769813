import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import ConfirmationModal from '../../uiToolkit/components/confirmationModal';
import { ApplicationState } from '../../store';
import * as CategoryStore from '../../store/configuration/Categories';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  categories: state.categories,
});
const mapDispatch = {
  ...CategoryStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { categoryid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  id: number;
  name: string;
  default: number;
  confirmationModal:boolean;
  formIsSubmitted: boolean;
}

class EditItemCategoryGroup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      name: '',
      default: 0,
      confirmationModal: false,
      formIsSubmitted: false,
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: {
          categoryid,
        },
      },
      requestCategory,
    } = this.props;

    if (categoryid !== '0') {
      requestCategory(categoryid);
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { categories: { selectedCategory, isLoading, isUpdated, isErrored }, history } = this.props;
    const { id } = this.state;

    if (selectedCategory !== prevProps.categories.selectedCategory && !isLoading) {
      if (selectedCategory !== undefined) {
        this.setState({
          id: selectedCategory.id,
          name: selectedCategory.name,
          default: selectedCategory.default,
        });
      } else {
        this.setState({
          id: 0,
          name: '',
          default: 0,
        });
      }
    }

    if (isErrored && !prevProps.categories.isErrored) {
      toast.error(`There was an error ${ id === 0 ? 'creating' : 'updating' } category`, {
        className: 'toast-popup error',
      });
    } else if (isUpdated && !prevProps.categories.isUpdated) {
      toast.info(`Successfully ${ id === 0 ? 'created' : 'updated' } category`);
      history.goBack();
    }
  }

  public handleTextInputUpdate(value: string, fieldName: string) {
    const update: object = { [fieldName]: value };

    this.setState(update);
  }

  public handleSave() {
    const { saveCategory } = this.props;
    const { id, name } = this.state;

    this.setState({ formIsSubmitted: true });

    if (name) {
      saveCategory({ id, name });
    }
  }

  public handleDelete() {
    const { deleteCategory } = this.props;
    const { id } = this.state;
    deleteCategory(id.toString());
  }

  public render() {
    const {
      id,
      name,
      default: defaultSelection,
      formIsSubmitted,
      confirmationModal,
    } = this.state;

    const { categories: { isLoading } } = this.props;

    return (
      <Page mode=""
        pageNumber={1}>

        { isLoading && <Loading/> }

        {!isLoading && <Form title={`${id === 0 ? 'Create' : 'Edit'} Item Category`}>
          <Input
            name="name"
            label="Name"
            placeholderText="Name"
            inputType="text"
            onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
            value={name}
            valid={formIsSubmitted && name.length === 0}
          />
          <label>
            Default Category:
            {defaultSelection === 1 ? ' True' : ' False'}
          </label>

          <Button
            text="Save"
            onClick={() => this.handleSave()}
            enabled
          />
          {id > 0 && defaultSelection === 0
          && (
            <Button
              text="Delete"
              onClick={() => this.setState({ confirmationModal: true })}
              enabled
            />
          )}
        </Form> }

        {confirmationModal
        && (
          <ConfirmationModal
            continue={() => this.handleDelete()}
            cancel={() => this.setState({ confirmationModal: false })}
            inProgress={isLoading}
          />
        )}
      </Page>
    );
  }
}

export default connector(withRouter(EditItemCategoryGroup));
