import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import { MsalProvider } from '@azure/msal-react';
import { AuthManager } from './AuthManager';

const authManager = new AuthManager();

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0]
  .getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });

// Get the application-wide store instance, prepopulating with
// state from the server where available.
const store = configureStore(history);

window.addEventListener('load', () => {
  authManager.initialize(store);
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MsalProvider instance={authManager.getInstance()}>
        <App />
      </MsalProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
