import { AppThunkAction } from '../index';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a
// state transition.
// They don't directly mutate state, but they can have external side-effects (such as
// loading data).

export const actionCreators = {
  requestScore: (reportingDate: string, reportingEntityId?: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.score && appState.score.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCORE',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/score?reportingStartDate=${reportingDate}${reportingEntityId ? `&reportingEntityId=${reportingEntityId}` : ''}`,
          successAction: 'RECEIVE_SCORE',
          failureAction: 'FAILED_SCORE',
        },
      });
    }
  },
};
