import React, { useEffect } from 'react';
import { ScoreArea } from '../../../store/authentication/types';
import { Suggestion } from '../../../store/score/types';

import ReactGA from 'react-ga';

interface IProps{
    suggestion: Suggestion,
    scoreArea: ScoreArea,
    clickEvent: Function
    
}

const SuggestionItem  = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { goodResult, message,  sustainable } = props.suggestion;
  const { name } = props.scoreArea;
  const { clickEvent } = props;
    
  return (
    <button 
      className="suggestion-item ui-toolkit"
      type= 'button'
      onClick={() => clickEvent()}
    >
      <div className={`suggestion-score suggestion-score-${goodResult? '2' : '0'}`}>
        <svg viewBox="0,0 80,40">
          <path d={`M 0,0 
                        L 20,0  
                        A 2.5,2.5 0 1,0 60,0
                        L 80,0
                        A 12.5,12.5 0 1,1 0,0
                        `}
          />
        </svg>
      </div>
       
      <div className="suggestion-content">
        <div className= "suggestion-link">
          {message}
          <span className={`area area-${goodResult ? '2' : '0'}`}>
            {` (${name})`}
          </span>
        </div>
        <div> 
          {sustainable ? 
            <img className="leaf-icon"
              src="/assets/leaf.svg"
              alt="sustainable leaf icon"
              title= "Sustainable Suggestion">
            </img>
            : null}
        </div> 
      </div>
    </button>
  );

};

export default SuggestionItem;