import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import ToolTips from '../../Common/ToolTips/toolTips';

type IProps = {
    id: number,
    text: string,
    tooltip: string,
    grandparentClicked: boolean,
    handleGrandparentClicked: Function,
    editMode: boolean,
    onInputChange: Function,
    onDelete: Function,
}

const ScoreTextMetric = (props: IProps) => {
  const [tipToggled, setTipToggled] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent, value: boolean) => {
    e.stopPropagation();
    setTipToggled(value);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (props.grandparentClicked){
      setTipToggled(false);
    }
  },[props.grandparentClicked]);

  const { text, tooltip, editMode, onInputChange, onDelete, id, handleGrandparentClicked, grandparentClicked } = props; 

  return (
        
    <div className={`score-text-metric ui-toolkit ${editMode ? 'edit' : ''}`}>
      <div>
        {
          editMode ? 
            <div className='score-text-metric ui-toolkit container'>
              <input type="text"
                className='metric-sequence'
                value={id} 
                onChange={(e) => onInputChange(e.target.value, 'id')}/>
              <input type="text"
                placeholder="Text"
                value={text} 
                onChange={(e) => onInputChange(e.target.value, 'text')}/>
            </div>

            : 
            text
        } </div>
      <div className ="toolTip-container">
        {editMode || tooltip !== '' ?
          <ToolTips
            handleParentClick={handleClick}
            handleGrandparentClick={handleGrandparentClicked}
            tipToggled={editMode ? true : grandparentClicked? false : tipToggled}
            text = {tooltip} 
            onInputChange={(value: string, fieldName: string) => onInputChange(value, fieldName)} 
            editMode={editMode}
          /> : null}
      </div>
      {
        editMode &&
          <button onClick={() => onDelete()}><img src='/assets/cross.png' /></button>
      }
    </div>
  );
};

export default ScoreTextMetric;