import * as Cron from 'cron-parser';
import moment from 'moment';

export const getLatestValidDate = (cronString?: string) : string => {
  const today = new Date();

  if (!cronString) {
    return moment(today).format('YYYY-MM-DD');
  }

  let cron = Cron.parseExpression(cronString, { currentDate: today });
  const previousDate = moment(cron.prev().toDate());

  if (previousDate.isSame(today, 'day')) {
    cron = Cron.parseExpression(cronString, { currentDate: previousDate.toString() }); 
  }

  return moment(cron.prev().toDate()).format('YYYY-MM-DD');
};
