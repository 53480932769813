import * as React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ReferenceLine, Label } from 'recharts';
import { scoreTrendPoint } from '../../../store/score/types';
import moment from 'moment';


interface IProps{
   scoreTrend : scoreTrendPoint[],
   threshold: number
}

export default class ScoreTrendChart extends React.PureComponent< IProps ,{}> {
  public render() {
    
    const {
      scoreTrend,
      threshold,
    }=this.props;
    
    let formattedData =scoreTrend; 

    formattedData = formattedData.sort((a,b) => moment(a.reportingWindowStart).valueOf() - moment(b.reportingWindowStart).valueOf());

    formattedData =formattedData.map(d => { 
      return { 
        ...d, 
        reportingWindowStart: moment(d.reportingWindowStart).format('ddd Do MMM YYYY'),
      };
    }); 

    const thresholdLine = (threshold / 100) * 5;
    
    return(
      <div className = 'chart-wrapper'>
        <LineChart width={600}
          height={350}
          data={formattedData}
          margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
          <Line type="monotone"
            dataKey={(v) => Math.floor(v.rawScore *100)  /100}
            stroke= '#28B440'
            name="Score"
            dot={{ stroke: '#28B440', strokeWidth: 7 }} 
            activeDot={{ r: 10 }}
            strokeWidth = {3}
          />
        
          <CartesianGrid stroke="#ccc"
            strokeDasharray="5 5"
          />
          <XAxis
            dataKey="reportingWindowStart" 
          />
          <YAxis
            allowDecimals = {false}
            type="number"
            ticks={[0, 1, 2, 3, 4, 5]}
            domain={[0, 5]}
          />
          <Tooltip 
            labelFormatter={() => ''}
          />
          <ReferenceLine 
            y = {thresholdLine}
            stroke="red"
            strokeDasharray="3 3" 
            strokeWidth = {2}
            label={<Label value="Target"
              fill={'black'} 
              position = {'top'}
              offset = {12}
              
            /> }
           
          />
        </LineChart>
      </div> 
    );
  }
}