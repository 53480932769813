import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CompanyState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    items: Company[];
}



export interface Company {
    id: number;
    name: string;
    storesCount: number;
    areaManagersCount: number;
    operationalManagersCount: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestCompaniesAction {
    type: 'REQUEST_COMPANIES';
}

interface ReceiveCompaniesAction {
    type: 'RECEIVE_COMPANIES';
    payload: any;
}

interface FailedCompaniesAction {
    type: 'FAILED_COMPANIES';
}

interface RequestSetCompanyAction {
    type: 'REQUEST_SETCOMPANY';
}

interface ReceiveSetCompanyAction {
    type: 'RECEIVE_SETCOMPANY';
    payload: any;
}

interface FailedSetCompanyAction {
    type: 'FAILED_SETCOMPANY';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties
// contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCompaniesAction | ReceiveCompaniesAction | FailedCompaniesAction | RequestSetCompanyAction | 
    FailedSetCompanyAction | ReceiveSetCompanyAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state
// transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestCompanies: (filter: string, page: number, pageSize: number):
        AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.items && appState.items.isLoading === false) {
      dispatch({
        type: 'REQUEST_COMPANIES',
        http: {
          verb: 'GET',
          endpoint: `/api/companyConfiguration/companies?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_COMPANIES',
          failureAction: 'FAILED_COMPANIES',
        },
      });
    }
  },
  saveCompany: (name: string):
        AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SETCOMPANY',
        http: {
          verb: 'POST',
          endpoint: '/api/companyConfiguration/save',
          successAction: 'RECEIVE_SETCOMPANY',
          failureAction: 'FAILED_SETCOMPANY',
          body: {
            name,
          },
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: CompanyState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  items: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<CompanyState> = (state: CompanyState |
    undefined, incomingAction: Action): CompanyState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_COMPANIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      items: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_COMPANIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      items: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_COMPANIES':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      items: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'REQUEST_SETCOMPANY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SETCOMPANY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SETCOMPANY':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
};
