import * as React from 'react';

interface IProps {
    title: string,
    content: string | React.ReactNode,
}

interface IState {
    isActive: boolean
}

class Accordion extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isActive: false,
    };
  }

  handleAccordionToggle() {
    this.setState({ isActive: !this.state.isActive });
  }

  public render() {
    const {
      title,
      content,
    } = this.props;

    return (
      <div className="accordion-item">
        <div className="accordion-title"
          onClick={() => this.handleAccordionToggle()}>
          <div>{title}</div>
          <div className="accordion-toggler">{this.state.isActive ? '-' : '+'}</div>
        </div>
        {this.state.isActive && <div className="accordion-content">{content}</div>}
      </div>

    );
  }
}

export default Accordion;
