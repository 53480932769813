import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as CompanyStore from '../../store/configuration/Companies';
import Error from '../Common/Error/error';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  companies: state.companies,
});
const mapDispatch = {
  ...CompanyStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    filter: string;
    page: number;
    pageSize: number;
    selectedIds: number[];
}

class Companies extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: '',
      page: 1,
      pageSize: 10,
      selectedIds: [],
    };
  }

  public componentDidMount() {
    const { history, requestCompanies } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());

    const queryFilter: string = (query.filter && query.filter.toString()) || '';
    const queryPage: number = parseInt((query.page && query.page.toString()) || '1', 10) || 1;

    const newState: IState = {
      ...this.state,
    };

    let updateState: boolean = false;

    if (newState.filter !== queryFilter && queryFilter.length > 0) {
      updateState = true;
      newState.filter = queryFilter;
    }

    if (newState.page !== queryPage && queryPage > 0) {
      updateState = true;
      newState.page = queryPage;
    }

    if (updateState) {
      this.setState(newState);
    }

    requestCompanies(newState.filter, newState.page, newState.pageSize);
  }

  public doSearch(filter: string) {
    const { location, history, requestCompanies } = this.props;
    const { pathname } = location;
    const { pageSize } = this.state;

    this.setState({ filter, page: 1 });

    history.replace(`${pathname}?filter=${filter}&page=${1}`);

    requestCompanies(filter, 1, pageSize);
  }

  public doPaging(page: number) {
    const { location, history, requestCompanies } = this.props;
    const { pathname } = location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    history.replace(`${pathname}?filter=${filter}&page=${page}`);

    requestCompanies(filter, page, pageSize);
  }

  public render() {
    const { companies } = this.props;
    const {
      filter,
      page,
      pageSize,
    } = this.state;

    const entries = companies.items.sort((a, b) => a.id - b.id);

    return (
      <Page mode=" center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Companies</h1>
            <p>Companies can be created here. Companies can be linked to both users and stores.</p>
            <p>Please note changes to groups will be reflected only when data is processed within the current or future reporting periods.</p>
          </div>

          <Table
            add
            addClick={() => { this.props.history.push('/configuration/companies/0'); }}
            addLabel="Add Company"
            search
            searchClick={(f: string) => this.doSearch(f)}
            initialValue={filter}
            pagination
            pageSize={pageSize}
            pageNumber={page}
            pageChange={(p: number) => this.doPaging(p)}
            totalRecords={companies.totalRecords}
          >
            <thead>
              <tr>
                <th className="padding title">Company</th>
                <th className="padding title">No. of Stores </th>
                <th className="padding title">Admin Users</th>
                <th className="padding title">Operations Managers</th>
              </tr>
            </thead>
            <tbody>

              {companies.isLoading
                && <tr><td colSpan={4}><Loading/></td></tr>}

              {companies.isErrored
                && <tr><td colSpan={4}><Error text={companies.errorMessage} /></td></tr>}

              {!companies.isLoading && entries.map((b) => (
                <tr key={b.id.toString()} >
                  <td className="padding">{b.name}</td>
                  <td className="padding">{b.storesCount}</td>
                  <td className="padding">{b.areaManagersCount}</td>
                  <td className="padding">{b.operationalManagersCount}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(Companies));
