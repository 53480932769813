/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import Layout from './components/Common/Layout/Layout';
import ScoreCard from './components/ScoreCard/ScoreCard';
import ScoreArea from './components/ScoreArea/ScoreArea';
import ProductLeaderboard from './components/ProductLeaderboard/ProductLeaderboard';
import Leaderboard from './components/Leaderboard/Leaderboard';
import Product from './components/Product/ProductScoreCard';
import Configuration from './components/Configuration/Configuration';
import Login from './components/Login/Login';
import EmbeddedLogin from './components/Login/EmbeddedLogin';
import EmbeddedScoreCard from './components/ScoreCard/EmbeddedScoreCard';
import { ApplicationState } from './store';
import ReactGA from 'react-ga';
import * as AuthenticationStore from './store/authentication/reducer';
import PermissionHelper from './uiToolkit/helpers/PermissionHelper';
import './App.scss';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { hotjar } from 'react-hotjar';
import Dashboard from './components/Dashboard/Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Help from './components/Help/Help';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { BrowserRouter } from 'react-router-dom';
import { Permissions } from './store/authentication/types';
import NotFound from './components/NotFound/NotFound';
 
hotjar.initialize(2179644, 6);

ReactGA.initialize('G-PWK647T7C8');
ReactGA.addTrackers('G-NFMGV64FBQ');
ReactGA.pageview(window.location.pathname + window.location.search);

const devKey = 'dc7fffe0-9686-48fa-a014-db6307c83901';
const prodKey= 'b87ade3d-4543-43d0-8384-467d73674196';

const appInsights = new ApplicationInsights({ config: {
  instrumentationKey: window.location.href.toLowerCase().indexOf('local') > -1 
    || window.location.href.toLowerCase().indexOf('dev') > -1 ? devKey: prodKey,
  disableFetchTracking: false,
  enableCorsCorrelation: true,
  enableRequestHeaderTracking: true,
  enableResponseHeaderTracking: true,
  enableAutoRouteTracking: true,
} });
appInsights.loadAppInsights();
appInsights.trackPageView();

interface IProps {
  authentication: AuthenticationStore.AuthenticationState;
}

function App(props: IProps){
  const { authentication } = props;
  const permissions = authentication && authentication.permissions;
  const scoringEntityLookup = authentication && authentication.scoringEntityLookup;
  const permHelper = new PermissionHelper(permissions);
  let dataId : string | null = null;
  if (!permHelper.HasAccessToMultipleStores() && scoringEntityLookup && Object.keys(scoringEntityLookup).length === 1) {
    dataId = Object.values(scoringEntityLookup)[0].dataId;
  }

  return (
    <>
      <BrowserRouter>
        <AuthenticatedTemplate>
          {           
            permissions.length === 0 &&   
             <Layout></Layout>
          }
          {
            permissions.length > 0 &&
              <Switch>
                <Route path="/embedded/:scoreid/:datekey" 
                  component={EmbeddedScoreCard} />
                <Route path="/">
                  <Layout>
                    <Route exact 
                      path="/">
                      {permHelper.HasAccessToMultipleStores() ? (
                        <Leaderboard /> 
                      ) : (
                        <ScoreCard scoreId={dataId ?? '-'} />
                      )}
                    </Route>
                    <Route path="/leaderboard"
                      component={Leaderboard}
                    />
                    <Route exact 
                      path="/score/:scoreid/"
                      component={ScoreCard} />
                      
                    <Route exact
                      path="/score/:scoreid/area/:areaid/"
                      component={ScoreArea} />

                    <Route exact
                      path="/product/score/:productid"
                      component={Product} />

                    {permissions.length > 0 && permissions.indexOf(Permissions.EditItemCategories) > -1 && (
                      <Route path="/configuration"
                        component={Configuration} /> 
                    )}
                    {permissions.length > 0 && (
                      <Route path="/dashboard"
                        component={Dashboard} />
                    )}
                    {permissions.length > 0 && (
                      <Route path="/product/leaderboard"
                        component={ProductLeaderboard} />
                    )}
                    <Route path="/help" 
                      component={Help} />
                  </Layout>
                </Route>
              </Switch>
          }
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Switch>
            <Route exact
              path="/embedded/:scoreid/:datekey"
              component={EmbeddedLogin} />
            <Route path="/">
              <Layout>
                <Route path="/"
                  component={Login} />
              </Layout>
            </Route>
          </Switch>
        </UnauthenticatedTemplate>
      </BrowserRouter>

      <ToastContainer
        toastClassName="toast-popup"
        position={toast.POSITION.BOTTOM_CENTER}
        autoClose={2000}
        hideProgressBar
        closeButton={false}
      />
    </>
  );
} 
export default connect(
  (state: ApplicationState) => ({
    authentication: state.authentication,
  }),
)(App as any);

