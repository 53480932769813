import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScoringEntitiesState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    scoringEntities: ScoringEntity[];

    selectedScoringEntitity?: ScoringEntity;
}

export interface ScoringEntity {
    id: number;
    name: string;
    type: string;
    dataID: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestScoringEntriesAction {
    type: 'REQUEST_SCORING_ENTITIES';
}

interface ReceiveScoringEntriesAction {
    type: 'RECEIVE_SCORING_ENTITIES';
    payload: any;
}

interface FailedScoringEntriesAction {
    type: 'FAILED_SCORING_ENTITIES';
}

interface RequestScoringEntrieAction {
    type: 'REQUEST_SCORING_ENTITY';
}

interface ReceiveScoringEntrieAction {
    type: 'RECEIVE_SCORING_ENTITY';
    payload: any;
}

interface FailedScoringEntrieAction {
    type: 'FAILED_SCORING_ENTITY';
}

interface RequestSaveScoringEntrieAction {
    type: 'REQUEST_SAVE_SCORING_ENTITY';
}

interface ReceiveSaveScoringEntrieAction {
    type: 'RECEIVE_SAVE_SCORING_ENTITY';
    payload: any;
}

interface FailedSaveScoringEntrieAction {
    type: 'FAILED_SAVE_SCORING_ENTITY';
}


// Declare a 'discriminated union' type. This guarantees that
// all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestScoringEntriesAction | ReceiveScoringEntriesAction | FailedScoringEntriesAction | RequestScoringEntrieAction
    | ReceiveScoringEntrieAction | FailedScoringEntrieAction | RequestSaveScoringEntrieAction | ReceiveSaveScoringEntrieAction
    | FailedSaveScoringEntrieAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestScoringEntities: (tenantId: string):
        AppThunkAction<any> => (dispatch, getState) => {

    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCORING_ENTITIES',
        http: {
          verb: 'GET',
          endpoint: `/api/ScoringEntityConfiguration/GetScoringEntities/${tenantId}`,
          successAction: 'RECEIVE_SCORING_ENTITIES',
          failureAction: 'FAILED_SCORING_ENTITIES',
        },
      });
    }
  },

  requestScoringEntity: (id: string):
        AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCORING_ENTITY',
        http: {
          verb: 'GET',
          endpoint: `/api/ScoringEntityConfiguration/GetScoringEntity/${id}`,
          successAction: 'RECEIVE_SCORING_ENTITY',
          failureAction: 'FAILED_SCORING_ENTITY',
        },
      });
    }
  },

  saveScoringEntity: (data: any):
        AppThunkAction<any> => (dispatch, getState) => {

    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_SCORING_ENTITY',
        http: {
          verb: 'POST',
          endpoint: '/api/ScoringEntityConfiguration/Save',
          successAction: 'RECEIVE_SAVE_SCORING_ENTITY',
          failureAction: 'FAILED_SAVE_SCORING_ENTITY',
          body: {
            ...data,
          },
        },
      });
    }
  },
};



// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: ScoringEntitiesState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  scoringEntities: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<ScoringEntitiesState> = (state: ScoringEntitiesState | undefined, incomingAction: Action): ScoringEntitiesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_SCORING_ENTITIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      scoringEntities: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_SCORING_ENTITIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      scoringEntities: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_SCORING_ENTITIES':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      scoringEntities: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'REQUEST_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      selectedScoringEntitity: undefined,
    };
  case 'RECEIVE_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      selectedScoringEntitity: action.payload,
    };
  case 'FAILED_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      selectedScoringEntitity: undefined,

    };
  case 'REQUEST_SAVE_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_SCORING_ENTITY':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }

};
