import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import CustomPrompt from '../../uiToolkit/components/customPrompt';
import { ApplicationState } from '../../store';
import * as ScoringEntriesStore from '../../store/dashboard/ScoringEntities';
import * as ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,

  scoringEntities: state.scoringEntities,
});

const mapDispatch = {
  ...ScoringEntriesStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoringEntityId: string, tenantId: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    id: number;
    name: string;
    type: string;
    tenantId: string;
    dataID: string;
    formIsUpdated: boolean;
    formIsSubmitted: boolean;
}

class EditScoringEntities extends React.PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      name: '',
      type: '',
      tenantId: '',
      dataID: '',
      formIsUpdated: false,
      formIsSubmitted: false,
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: {
          tenantId,
          scoringEntityId,
        },
      },
      requestScoringEntity
      ,
    } = this.props;

    if (tenantId) {
      this.setState({ tenantId: tenantId });
    }

    if (scoringEntityId !== '0') {
      requestScoringEntity(scoringEntityId);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { match: {
      params: {
        scoringEntityId,
      },
    }, scoringEntities: { selectedScoringEntitity, isLoading, isUpdated, isErrored } } = this.props;

    if (selectedScoringEntitity !== prevProps.scoringEntities.selectedScoringEntitity && !isLoading) {
      if (selectedScoringEntitity !== undefined) {
        this.setState({
          id: selectedScoringEntitity.id,
          name: selectedScoringEntitity.name,
          type: selectedScoringEntitity.type,
          dataID: selectedScoringEntitity.dataID,
        });
      } else {
        this.setState({
          id: 0,
          name: '',
          type: '',
          dataID: '',
        });
      }
    }

    if(isErrored && !prevProps.scoringEntities.isErrored) {
      this.showNotification('There was an error saving scoring entity');
    } else if (isUpdated && !prevProps.scoringEntities.isUpdated) {
      this.showNotification(`Successfully ${ scoringEntityId === '0' ?  'created' : 'updated'} scoring entity`);
    }
  }

  public showNotification(message: string) {
    const { history } = this.props;
    const { scoringEntities: { isErrored } } = this.props;

    this.setState({ formIsUpdated: false });

    if(isErrored){
      toast.error(message, {
        className: 'toast-popup error',
      });
    } else {
      toast.info(message);
      history.goBack();
    }
  }

  public handleTextInputUpdate(value: React.ChangeEvent<HTMLInputElement> | string, fieldName: string) {
    const update: object = { [fieldName]: value };
    this.setState(update);
    this.setState({ formIsUpdated: true });
  }

  public handleSave() {
    const { saveScoringEntity } = this.props;
    const { id, name, type, tenantId, dataID } = this.state;

    this.setState({ formIsSubmitted: true });

    const data = { id, name, type, tenantId, dataID };
    if (name && type && tenantId && dataID) {
      saveScoringEntity(data);
    }
  }

  public cancel() {
    this.setState({ formIsUpdated: false, formIsSubmitted: false }, () => {
      if (!this.state.formIsUpdated) {
        this.props.history.goBack();
      }
    });
  }

  public render() {
    const {
      id,
      name,
      type,
      dataID,
      formIsUpdated,
      formIsSubmitted,
    } = this.state;

    const { scoringEntities: { isLoading } } = this.props;

    return (
      <>
        <CustomPrompt
          when={formIsUpdated}
          navigate={(path: string) => {
            this.props.history.push(path);
          }}
          shouldBlockNavigation={() => {
            if (formIsUpdated) {
              return true;
            }
            return false;
          }}
        />
        <div className='scoring-entries-form'>
          <div className="text-wrapper">
            <h1>{`${id === 0 ? 'Create' : 'Edit'} Scoring Entity`}</h1>
          </div>

          { isLoading && <Loading/> }

          { !isLoading && <Form title={''}>
            <div className='input-container'>
              <Input
                name="name"
                label="Name"
                placeholderText="Name"
                inputType="text"
                onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
                value={name}
                valid={formIsSubmitted && name.length === 0}
              />
            </div>

            <div className='input-container'>
              <Input
                name="type"
                label="Type"
                placeholderText="Type"
                inputType="text"
                onChange={(e: string) => this.handleTextInputUpdate(e, 'type')}
                value={type}
                valid={formIsSubmitted && type.length === 0}
              />
            </div>

            <div className='input-container'>
              <Input
                name="dataId"
                label="Data ID"
                placeholderText="Data ID"
                inputType="text"
                onChange={(e: string) => this.handleTextInputUpdate(e, 'dataID')}
                value={dataID}
                valid={formIsSubmitted && dataID.length === 0}
              />
            </div>

            <div className='actions'>
              <Button
                className='button ui-toolkit'
                text="Save"
                onClick={() => this.handleSave()}
                enabled
              />
              <span className="cancel-btn">
                <Button
                  className='button ui-toolkit'
                  text="Cancel"
                  onClick={() => this.cancel()}
                  enabled
                />
              </span>
            </div>
          </Form> }
        </div>
      </>
    );
  }
}

export default connector(withRouter(EditScoringEntities));