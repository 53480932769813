import * as React from 'react';
import ReactGA from 'react-ga';

export default class SplashHero extends React.PureComponent<{}, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    return (
      <div className="splash-hero ui-toolkit">
        <div className="splash">
          <div className="influence">Influence</div>
          <div className="responsibility">Responsibility</div>
          <svg viewBox="0 0 80 40">
            <path d={`M 0,0
            L 16,0
            A 16,16 0 0,0 64,0
            L 80,0
            A 40,40 0 0,1 0,0
            `}
            />
          </svg>
        </div>
      </div>
    );
  }
}
