
import React from 'react';
import { Suggestion } from '../../../store/score/types';

interface IProps{
  suggestion: Suggestion;
}

export default class SuggestionProgressBar extends React.Component< IProps ,{}> {
  render() {
    const {
      suggestion,
    } = this.props;

    const { maximum, average, metric, highPositive } = suggestion;

    const percentage = (average / maximum) * 100;
    let percentageMetric = (metric / maximum) * 100;

    if(percentageMetric > 100) {
      percentageMetric = 100;
    }

    return (
      <div>
        <div className="bar-wrapper">
          <div 
            className="progress-bar"></div>
          <div 
            className="progress-filler"
            style={{ width: `${percentageMetric}%` }}
          ></div>
          <div className="store"
            style={{ left: `${percentageMetric-4}%` }}
          >You</div>
          <div className="label-line"
            style={{ left: `${percentageMetric-1}%` }}
          ></div>
          <div className={`dot left dot-${highPositive? 'bad': 'good'}`}></div>
          <div className={`label label-${highPositive?  'left': 'rightspace'}`}>Worst</div>
          <div 
            className="label-line avg"
            style={{ left:`${percentage + 0.70}%` }}
          ></div>
          <div 
            className="line average"
            style={{ left:`${percentage}%` }}
          ></div>
          <div 
            className="label average"
            style={{ left:`${percentage - 5}%` }}
          >Average</div>
          <div className={`dot right dot-${!highPositive? 'bad': 'good'}`}></div>
          <div className={`label label-${!highPositive? 'left': 'right'}`}>Best</div>
        </div>
        <div className="stats">
          <div>{`The average score is at: ${Math.round(percentage)}%`}</div>
          <div>{`Your store's score is at: ${Math.round(percentageMetric)}%`}</div>
        </div>
      </div>
    );
  }
}