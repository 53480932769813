import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps, Switch, Route } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import FrequentQuestions from './FrequentQuestions';
import ContactUs from './Contact';
import UserGuide from './UserGuide';
import { ApplicationState } from '../../store';
import ReactGA from 'react-ga';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});
const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

class Help extends React.PureComponent<IProps, {}> {

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public render() {
    const { history } = this.props;
    return (
      <div className="help-page ui-toolkit">
        <Page mode="center help-page"
          pageNumber={1}
          padding>
          <div className="help-wrapper">
            <div className="menu">
              <button
                type="button"
                onClick={() => {
                  return history.push('/help/frequent-questions');
                }}
              >  FAQ&apos;s
                <div className="chevron" />
              </button>
              <button
                type="button"
                onClick={() => {
                  return history.push('/help/user-guide');
                }}
              >
               User Guides
                <div className="chevron" />
              </button>
              <button
                type="button"
                onClick={() => {
                  return history.push('/help/contact-us');
                }}
              >  Contact Us
                <div className="chevron" />
              </button>
            </div>

            <div className="help-content">
              <Switch>
                <Route exact
                  path="/help/frequent-questions"
                  component={FrequentQuestions} />
                <Route path="/help/contact-us"
                  component={ContactUs} />
                <Route exact
                  path="/help/user-guide"
                  component={UserGuide} />
              </Switch>
            </div>
          </div>
        </Page>
      </div>
    );
  }
}

export default connector(withRouter(Help));