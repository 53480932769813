import * as Authentication from './authentication/reducer';
import * as Score from './score/reducer';
import * as ScoreArea from './area/reducer';
import * as Leaderboard from './leaderboard/reducer';
import * as Product from './product/reducer';

import * as Banding from './configuration/Banding';
import * as Categories from './configuration/Categories';
import * as Items from './configuration/Items';
import * as Companies from './configuration/Companies';
import * as Users from './configuration/Users';
import * as ReportingEntities from './configuration/ReportingEntities';
import * as ScoreWeightings from './configuration/ScoreWeightings';
import * as Tenants from './dashboard/Tenants';
import * as RolePermissions from './configuration/EditRolePermissions';
import * as ScoringItems  from './dashboard/ScoringItems';
import * as ScoreEntities from './dashboard/ScoringEntities';
import * as FaqsEntities from './help/frequentQuestions';
import * as ProductLeaderboard from './productLeaderboard/ProductLeaderboard';
   
// The top-level state object
export interface ApplicationState {
    //  counter: Counter.CounterState | undefined;
    authentication: Authentication.AuthenticationState;
    score: Score.ScoreState | undefined;
    scoreArea: ScoreArea.ScoreAreaState;
    leaderboard: Leaderboard.LeaderboardState;
    product: Product.ProductState;
    bandings: Banding.BandingState;
    categories: Categories.CategoryState;
    items: Items.ItemState;
    users: Users.UserState;
    companies: Companies.CompanyState;
    rolePermisions: RolePermissions.EditRolePermissionsState;
    reportingEntities: ReportingEntities.ReportingEntitiesState;
    scoreWeightings: ScoreWeightings.ScoreWeightingState;
    tenants: Tenants.TenantsState;
    scoringItems: ScoringItems.ScoringItemState;
    scoringEntities: ScoreEntities.ScoringEntitiesState,
    faqs: FaqsEntities.FaqState,
    productLeaderboard: ProductLeaderboard.ProductLeaderboardState;
}

// Whenever an action is dispatched, Redux will update each top-level
// application state property using
// the reducer with the matching name. It's important that the names
// match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  authentication: Authentication.reducer,
  score: Score.reducer,
  scoreArea: ScoreArea.reducer,
  leaderboard: Leaderboard.reducer,
  product: Product.reducer,
  bandings: Banding.reducer,
  categories: Categories.reducer,
  items: Items.reducer,
  companies: Companies.reducer,
  users: Users.reducer,
  reportingEntities: ReportingEntities.reducer,
  scoreWeightings: ScoreWeightings.reducer,
  rolePermisions:RolePermissions.reducer,
  tenants: Tenants.reducer,
  scoringItems: ScoringItems.reducer,
  scoringEntities: ScoreEntities.reducer,
  faqs: FaqsEntities.reducer,
  productLeaderboard : ProductLeaderboard.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch'
// and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
