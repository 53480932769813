import {  AnyAction, Reducer } from 'redux';
import { AppThunkAction } from '..';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductLeaderboardState {
  isLoading: boolean;
  isErrored: boolean;
  errorMessage: string;
  productLeaderboard?: ProductLeaderboard;
}

export interface ProductLeaderboard {
    id: string;
    tenantId: string;
    siteId : string;
    period: Period;
    entries: ProductEntry[];
}

export interface Period {
    end: string,
    start: string
}

export interface ProductEntry {
    rank?: number;
    totalScore: number;
    itemNumber : string;
    itemDescription: string;
    category: string;
    scores: ScoreArea[];
}

export interface ScoreArea {
    area: string;
    rawScore: number;
}

export interface ProductLeaderboardResponse{
    productLeaderboard: ProductLeaderboard
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions
// of state transitions.
// They do not themselves have any side-effects; they just describe
// something that is going to happen.

export interface RequestProductLeaderboardAction extends AnyAction {
    type: 'REQUEST_PRODUCT_LEADERBOARD';
}

export interface RequestProductLeaderboardAction extends AnyAction {
  type: 'REQUEST_PRODUCT_LEADERBOARD';
}

export interface ReceiveProductLeaderboardAction extends AnyAction {
    type: 'RECEIVE_PRODUCT_LEADERBOARD';
    payload: ProductLeaderboardResponse;
}

export interface FailedProductLeaderboardAction extends AnyAction {
    type: 'FAILED_PRODUCT_LEADERBOARD';
}

// Declare a 'discriminated union' type. This guarantees that all references to
// 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type ProductLeaderboardAction =  RequestProductLeaderboardAction | ReceiveProductLeaderboardAction | FailedProductLeaderboardAction;
    
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will
// trigger a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestProductLeaderboard: (reportingDate: string, reportingEntity: string):
    AppThunkAction<ProductLeaderboardAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.productLeaderboard && appState.productLeaderboard.isLoading === false) {
      dispatch({
        type: 'REQUEST_PRODUCT_LEADERBOARD',
        http: {
          verb: 'GET',
          endpoint: `/api/leaderboard/productLeaderboard?reportingStartDate=${reportingDate}&reportingEntity=${reportingEntity}`,
          successAction: 'RECEIVE_PRODUCT_LEADERBOARD',
          failureAction: 'FAILED_PRODUCT_LEADERBOARD',
        },
      });
    }
  },

  requestAverageProductLeaderboard: (reportingDate: string):
  AppThunkAction<ProductLeaderboardAction> => (dispatch, getState) => {
  // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.productLeaderboard && appState.productLeaderboard.isLoading === false) {
      dispatch({
        type: 'REQUEST_PRODUCT_LEADERBOARD',
        http: {
          verb: 'GET',
          endpoint: `/api/leaderboard/productLeaderboardAverage?reportingStartDate=${reportingDate}`,
          successAction: 'RECEIVE_PRODUCT_LEADERBOARD',
          failureAction: 'FAILED_PRODUCT_LEADERBOARD',
        },
      });
    }
  },
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const initialState: ProductLeaderboardState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  productLeaderboard: undefined,
};

export const reducer: Reducer<ProductLeaderboardState, ProductLeaderboardAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {

  case 'REQUEST_PRODUCT_LEADERBOARD':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
    };
  case 'RECEIVE_PRODUCT_LEADERBOARD':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      productLeaderboard: action.payload.productLeaderboard,
    };
  case 'FAILED_PRODUCT_LEADERBOARD':
    return {
      ...state,
      errorMessage: 'An error occurred while receiving product leaderboard.',
      isErrored: true,
      isLoading: false,
      productLeaderboard: undefined,
    };
  default:
    return state;
  }
};


  