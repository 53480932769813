import * as React from 'react';
import ReactGA from 'react-ga';

export default class Loading extends React.PureComponent<{}, {}> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    return (
      <div className="loading-wrapper">
        <img src='/assets/loading-icon.gif'/>
      </div>
    );
  }
}
