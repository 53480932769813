/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-case-declarations */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import CustomPrompt from '../../uiToolkit/components/customPrompt';
import { ApplicationState } from '../../store';
import * as ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';
import * as ScoringAreasScore from '../../store/area/actions';
import ScoreValueMetric from '../ScoreArea/ScoreValueMetric/scoreValueMetric';
import { ComponentValue, ListMetric, MetricType, MetricVariable, MetricVariableType, 
  SortOption, Template, TemplateUpdateActionType, TextMetric, ValueMetric } from '../../store/area/types';
import ScoreListMetric from '../ScoreArea/ScoreListMetric/scoreListMetric';
import ScoreListItemMetric from '../ScoreArea/ScoreListItemMetric/scoreListItemMetric';
import ScoreListItemValueMetric from '../ScoreArea/ScoreListItemValueMetric/scoreListItemValueMetric';
import ScoreTextMetric from '../ScoreArea/ScoreTextMetric/scoreTextMetric';
import * as ScoringEntriesStore from '../../store/dashboard/ScoringEntities';
import * as ScoringItemsStore from '../../store/dashboard/ScoringItems';
import Accordion from '../Common/Accordion/accordion';
import { Suggestion } from '../../store/score/types';
import * as ScoreAreaTypes from '../../store/area/types';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  scoringAreaState: state.scoreArea,
  scoringEntities: state.scoringEntities,
  scoringItems: state.scoringItems,
});

const mapDispatch = {
  ...ScoringAreasScore.actionCreators,
  ...ScoringEntriesStore.actionCreators,
  ...ScoringItemsStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoringAreaId: string, tenantId: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

const valueFormats = ['integer', 'float', 'text', 'percentage', 'date', 'currency', 'timelapse'];

interface IconOption {
  name: string;
  src: string;
}

interface IState {
    id: string;
    name: string;
    dataId: string;
    tenantId: string,
    sequence: number;
    template: Template,
    formIsUpdated: boolean;
    formIsSubmitted: boolean;
    metricVariables : MetricVariable[];
    mainDetailsSectionActive: boolean;
    variablesSectionActive: boolean;
    valueMetricsSectionActive: boolean;
    listMetricsSectionActive: boolean;
    textMetricsSectionActive: boolean;
    areaDataSectionActive: boolean;
    selectedScoringIcon: string;
    selectedScoringIconName: string;
    scoringIconOptions: IconOption[]
}

class EditScoringArea extends React.PureComponent<IProps, IState> {
  public areaIconUploader: any = React.createRef();

  constructor(props: IProps) {
    super(props);

    this.state = {
      id: '0',
      name: '',
      dataId: '',
      sequence: 0,
      tenantId: '',
      formIsUpdated: false,
      formIsSubmitted: false,
      metricVariables: [],
      template: { components: { sequence:[], listMetrics:[], textMetrics:[], valueMetrics:[] }, componentValues: [] },
      mainDetailsSectionActive: true,
      variablesSectionActive: false,
      valueMetricsSectionActive: false,
      listMetricsSectionActive: false,
      textMetricsSectionActive: false,
      areaDataSectionActive: false,
      selectedScoringIcon: '',
      selectedScoringIconName: '',
      scoringIconOptions: [],
    };
  }

  public componentDidMount() {
    const {
      match: {
        params: {
          tenantId,
          scoringAreaId,
        },
      },
      requestScoreAreaByTenantId,
      requestScoringItems,
      requestScoringEntities
      ,
    } = this.props;

    if (tenantId) {
      this.setState({ tenantId: tenantId });
    }

    if (scoringAreaId !== '0') {
      requestScoreAreaByTenantId(tenantId, scoringAreaId);
    } else {
      requestScoringEntities(tenantId);
    }

    requestScoringItems(tenantId);
    this.loadStaticImages();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { metricVariables } = this.state;
    const { match: {
      params: {
        scoringAreaId,
      },
    }, scoringAreaState: { id, name, dataId, image, sequence, template, isLoading, isUpdated, isErrored },
    scoringEntities: { scoringEntities } } = this.props;

    if (parseInt(this.props.scoringAreaState.id) > 0 && this.state.id === '0' && !isLoading && !isUpdated && !isErrored) {
      this.setState({
        id: id,
        name: name,
        selectedScoringIcon: image,
        dataId: dataId,
        sequence: sequence,
        template: { ...template },
      }, () => {
        this.getIconName(this.state.selectedScoringIcon);
      });

      if(metricVariables.length === 0) {
        this.populateAreaVariables();
      }
    }

    if(scoringEntities.length > 0 && prevProps.scoringEntities.scoringEntities.length === 0) {
      this.populateTemplateValues();
    }

    if(isErrored && !prevProps.scoringAreaState.isErrored) {
      this.showNotification('There was an error saving scoring area');
    } else if (isUpdated && !prevProps.scoringAreaState.isUpdated) {
      this.showNotification(`Successfully ${ scoringAreaId === '0' ?  'created' : 'updated'} scoring area`);
    }    
  }

  public addVariable(variable: MetricVariable, metricVariables: MetricVariable[]) {
    if(!metricVariables.map(x => x.label).includes(variable.label)) {
      metricVariables.push(variable);
    }
  }

  public populateAreaVariables() {
    const { metricVariables } = this.state;
    const {
      match: {
        params: {
          tenantId,
        },
      },
      scoringAreaState: { template },
      requestScoringEntities,
    } = this.props;

    let id: number = 0;

    template.components.valueMetrics.forEach(valueMetric => {
      if(valueMetric.label.startsWith('{{')) {
        this.addVariable({
          id,
          label: valueMetric.label,
          value: valueMetric.format,
          type: MetricVariableType.Value,
        }, metricVariables);
        id++;
      }

      if(valueMetric.value.startsWith('{{')) {
        this.addVariable({
          id,
          label: valueMetric.value,
          value: valueMetric.format,
          type: MetricVariableType.Value,
        }, metricVariables);
        id++;
      }
    });

    template.components.listMetrics.forEach(listMetric => {
      listMetric.sortOptions.forEach(sortOpt => {
        metricVariables.push({
          id,
          label: sortOpt.label,
          value: sortOpt.data,
          type: MetricVariableType.Sort,
        });
        id++;
      });
      
      if(listMetric.items.title.startsWith('{{')) {
        this.addVariable({
          id,
          label: listMetric.items.title,
          value: 'text',
          type: MetricVariableType.ListItem,
        }, metricVariables);
        id++;
      }

      listMetric.items.metrics.forEach(metric => {
        if(metric.label.startsWith('{{')) {
          this.addVariable({
            id,
            label: metric.label,
            value: metric.format || 'text',
            type: MetricVariableType.ListItem,
          }, metricVariables);
          id++;
        }

        if(metric.suffix && metric.suffix.startsWith('{{')) {
          this.addVariable({
            id,
            label: metric.suffix,
            value: metric.format,
            type: MetricVariableType.ListItem,
          }, metricVariables);
          id++;
        }

        if(metric.value.startsWith('{{')) {
          this.addVariable({
            id,
            label: metric.value,
            value: metric.format || 'text',
            type: MetricVariableType.ListItem,
          }, metricVariables);
          id++;
        }
      });
    });
    this.setState({ metricVariables: [...metricVariables] });
    requestScoringEntities(tenantId);
  }

  public populateTemplateValues() {
    const { scoringEntities: { scoringEntities } } = this.props;
    const { template, metricVariables } = this.state;

    const newTemplate = { ...template };
    
    scoringEntities.forEach(entity => {
      if(!newTemplate.componentValues.find(x => x.scoringEntityDataId === entity.dataID)) {
        const templateValue: ComponentValue = {
          scoringEntityDataId: entity.dataID,
          listMetrics: {},
          metrics: {},
          rawScore: [0.0, 0.0],
          suggestions: [],
        };

        metricVariables.filter(x => x.type === MetricVariableType.Value).forEach(variable => {
          templateValue.metrics[variable.label.replace('{{', '').replace('}}', '')] = ['', ''];
        });

        newTemplate.components.listMetrics.forEach(listMetric => {
          templateValue.listMetrics[listMetric.label.replace(/\s/g, '')] = [];
        });

        newTemplate.componentValues.push(templateValue);
      }
    });
    this.setState({ template: newTemplate });
  }

  public showNotification(message: string, error?: boolean) {
    const { history } = this.props;
    const { scoringAreaState: { isErrored } } = this.props;

    this.setState({ formIsUpdated: false });

    if(isErrored || error){
      toast.error(message, {
        className: 'toast-popup error',
      });
    } else {
      toast.info(message);
      history.goBack();
    }
  }

  public handleSave() {
    const { saveScoreArea,
      match: {
        params: {
          tenantId,
        },
      } } = this.props;
    const { id, name, selectedScoringIcon, sequence , template, metricVariables } = this.state;

    this.setState({ formIsSubmitted: true });

    // add sequence on the template

    template.components.sequence = template.components.valueMetrics.map(x => x.id)
      .concat(template.components.listMetrics.map(x => x.id))
      .concat(template.components.textMetrics.map(x => x.id)).sort();

    //add format on metrics
    template.components.valueMetrics.forEach(metric => {
      const variable = metricVariables.find(x => x.label === metric.value);
      if(variable) {
        metric.format = variable.value;
      }
    });
    
    //remove stores with empty data from the template
    template.componentValues = template.componentValues.filter(x => x.rawScore[1] > 0);

    template.components.listMetrics.forEach(listMetric => {
      listMetric.sortOptions.forEach(option => {
        option.data = option.data.replace('{{', '').replace('}}', '');
      });
      
      listMetric.items.metrics.forEach(metric => {
        const variable = metricVariables.find(x => x.label === metric.value);
        if(variable) {
          metric.format = variable.value;
        }
      });
    });


    //add variable types in the template

    template.componentValues.forEach(component => {
      Object.keys(component.metrics).forEach(metric => {
        const variable = metricVariables.find(x => x.label === '{{' + metric + '}}');
        if(variable) {
          if(component.metrics[metric].length > 2) {
            component.metrics[metric][2] = variable.value;
          } else {
            component.metrics[metric] = [...component.metrics[metric], variable.value];
          }
        }
      });

      Object.keys(component.listMetrics).forEach(listMetric => {
        component.listMetrics[listMetric].forEach((row: any) => {
          Object.keys(row).forEach(metric => {
            if(metricVariables.map(x => x.label).includes('{{' + metric + '}}')) {
              const variable = metricVariables.find(x => x.label === '{{' + metric + '}}');

              if(variable) {
                if(row[metric].length > 2) {
                  row[metric][2] = variable.value;
                } else {
                  row[metric] = [...row[metric], variable.value];
                }
              }
            }
          });
        });
      });
    });

    const data = {
      id,
      tenantId,
      name,
      dataId: name.replace(/\s/g, '-').toLowerCase(),
      image: selectedScoringIcon,
      sequence,
      template: JSON.stringify(template),
    };

    if(this.dataIsValid()) {
      saveScoreArea(data);
    }
    
  }

  public dataIsValid() {
    const { name, selectedScoringIcon, sequence } = this.state;

    if(name.length > 0 && selectedScoringIcon.length > 0 && sequence > 0) {
      return true;
    }
    this.showNotification('Please make sure to fill all mandatory fields', true);
    return false;
  }

  public cancel() {
    this.setState({ formIsUpdated: false, formIsSubmitted: false }, () => {
      if (!this.state.formIsUpdated) {
        this.props.history.goBack();
      }
    });
  }

  //#region Generic functions

  public replacePlaceholders(text:string, values: {[id:string]: any}, type: string, extraValues?: ScoreAreaTypes.ItemData): string{

    if(typeof(text) !== 'string'){
      return text;
    }

    const placeholders = text.match(/\{\{[a-z0-9]+\}\}/ig);
    const replaceValues = (placeholders && placeholders.map((match) => match.replace('{{', '').replace('}}', ''))) || [];

    for (let i = 0; i < replaceValues.length; i += 1) {
      let value = values[replaceValues[i]];
      
      if(replaceValues[i] === 'description' && extraValues && extraValues.description){
        value = extraValues.description;
      }

      if (value !== undefined) {
        text = text.replace(`{{${replaceValues[i]}}}`, type === 'min' ? value[0] : value[1]);
      }else{
        text = text.replace(`{{${replaceValues[i]}}}`, '');
      }
    }

    return text;
  }

  public handleTextInputUpdate(value: React.ChangeEvent<HTMLInputElement> | string, fieldName: string) {
    if (typeof value !== 'string') {
      value = value.target.value;
      if (!(Number(value) >= 0 && Number(value) <= 100)) {
        value = value.slice(0, -1); 
      }
    }

    const update: object = { [fieldName]: value, formIsUpdated: true };
    this.setState(update);
    
  }

  public addKeyToObject(object: any, key: string, value: any) {
    if(!(key in object)) {
      object[key] = value;
    }
  }

  public updateObjectKey(object: any, newKey: string, oldKey: string) {
    if(oldKey in object && newKey !== oldKey) {
      delete Object.assign(object, { [newKey]: object[oldKey] })[oldKey];
    }
  }

  public updateObjectValue(object: any, key: string, value:any) {
    if(key in object) {
      object[key] = value;
    }
  }

  public deleteObjectKey(object: any, key: string) {
    if(key in object) {
      delete object[key];
    }
  }

  public keyExistsInTemplateValues(key: string, metricType: string, listMetricLabel?: string): boolean {
    const { template } = this.state;

    if(metricType === MetricType.Value) {
      template.componentValues.forEach(component => {
        if(key in component.metrics) {
          return true;
        }
      });
    } else if(metricType === MetricType.ListItem) {
      template.componentValues.forEach(component => {
        if(listMetricLabel) {
          component.listMetrics[listMetricLabel].forEach((metric: any) => {
            if(key in metric) {
              return true;
            }
          });
        } else {
          Object.keys(component.listMetrics).forEach(listMetric => {
            component.listMetrics[listMetric].forEach((metric: any) => {
              if(key in metric) {
                return true;
              }
            });
          });
        }
      });
    }
    return false;
  }

  public updateTemplateValueKeys(
    key: string, 
    oldKey: string, 
    metricType: string, 
    actionType: TemplateUpdateActionType, 
    listMetricId?: string,
    extraData?: any) {
    const { template } = this.state;

    const newTemplate = { ...template };

    let objectToUpdate;
    let value;

    if(metricType === MetricType.Value || metricType === MetricType.List) {
      newTemplate.componentValues.forEach(component => {
        objectToUpdate = metricType === MetricType.Value ? component.metrics : component.listMetrics;
        value = metricType === MetricType.Value ? ['', ''] : [];

        switch(actionType) {
        case TemplateUpdateActionType.Add:
          this.addKeyToObject(objectToUpdate, key, value);
          break;
        case TemplateUpdateActionType.Update:
          this.updateObjectKey(objectToUpdate, key, oldKey);
          break;
        case TemplateUpdateActionType.UpdateValue:
          this.updateObjectValue(objectToUpdate, key, extraData);
          break;
        case TemplateUpdateActionType.Delete:
          this.deleteObjectKey(objectToUpdate, key);
          break;
        }
      });
    } else if(metricType === MetricType.ListItem) {
      newTemplate.componentValues.forEach(component => {
        if(listMetricId) {
          component.listMetrics[listMetricId].forEach((metric: any) => {
            switch(actionType) {
            case TemplateUpdateActionType.Add:
              this.addKeyToObject(metric, key, ['', '']);
              break;
            case TemplateUpdateActionType.Update:
              this.updateObjectKey(metric, key, oldKey);
              break;
            case TemplateUpdateActionType.Delete:
              this.deleteObjectKey(metric, key);
              break;
            }
          }); 
        }
        Object.keys(component.listMetrics).forEach(listMetric => {
          component.listMetrics[listMetric].forEach((metric: any) => {
            
            switch(actionType) {
            case TemplateUpdateActionType.Add:
              this.addKeyToObject(metric, key, ['', '']);
              break;
            case TemplateUpdateActionType.Update:
              this.updateObjectKey(metric, key, oldKey);
              break;
            case TemplateUpdateActionType.UpdateValue:
              this.updateObjectValue(metric, key, extraData);
              break;
            case TemplateUpdateActionType.Delete:
              this.deleteObjectKey(metric, key);
              break;
            }
          });
        });
      });
    }
    this.setState({ template: newTemplate, formIsUpdated: true });
  }

  //#endregion

  //#region Variable functions

  public handleAddVariable(type: MetricVariableType) {
    const { metricVariables } = this.state;
    const id = metricVariables.length > 0 ? metricVariables.map((m) => m.id).reduce(function(a, b) {
      return Math.max(a, b);
    }, -Infinity) : 0;

    let label = '';
    let value = '';

    if(type === MetricVariableType.Sort) {
      label = 'Sort option ' + (metricVariables.length + 1);
      if(metricVariables.filter(x => x.type === MetricVariableType.ListItem).length > 0) {
        value = metricVariables.filter(x => x.type === MetricVariableType.ListItem)[0].label;
      }
    } else {
      label = '{{Variable' + (metricVariables.length + 1) + '}}';
      value = valueFormats[0];
    }

    metricVariables.push({
      id: id + 1,
      label,
      type,
      value,
    });

    this.setState({ metricVariables: [...metricVariables], formIsUpdated: true });
  }

  public formatVariableLabel(value: string) {
    const newValue = value.replace(/\{/g, '').replace(/\}/g, '').replace(/\s/g, '');

    return '{{' + newValue + '}}';
  }

  public isVariableLabelValid(label: string) {
    const { metricVariables } = this.state;

    if(label.replace('{{', '').replace('}}', '').length === 0) {
      this.showNotification('Variable label cannot be empty', true);
      return false;
    }
    if(metricVariables.find(x => x.label === label)) {  
      this.showNotification('A variable already exists with that label', true);
      return false;
    }
    return true;
  }

  public handleVariableInputUpdate(id: number, value: string, field: string, type: MetricType) {
    const { metricVariables, template } = this.state;

    const variable = metricVariables.find((v) => v.id === id);
    if(variable) {
      if(field === 'label') {
        const newLabel = this.formatVariableLabel(value);
        if(variable.label !== newLabel) {
          if(this.isVariableLabelValid(newLabel)) {
            this.updateTemplateValueKeys(
              newLabel.replace(/\{/g, '').replace(/\}/g, ''), 
              variable.label.replace(/\{/g, '').replace(/\}/g, ''), 
              type, 
              TemplateUpdateActionType.Update,
            );

            if(type === MetricType.Value) {
              const metric = template.components.valueMetrics.find(x => x.value === variable.label);
              if(metric) {
                metric.value = value;
              }
              variable.label = newLabel;
            } else if(type === MetricType.ListItem) {
              template.components.listMetrics.forEach(listMetric => {
                listMetric.items.metrics.forEach(metric => {
                  if(metric.value === variable.label) {
                    metric.value = value;
                  }
                });
              });
              variable.label = newLabel;
            } else if(type === MetricType.Sort) {
              template.components.listMetrics.forEach(listMetric => {
                listMetric.sortOptions.forEach(option => {
                  if(variable.label === option.label) {
                    option.label = value;
                  }
                });
              });
              variable.label = value;
            }
          }
        }
      } else {
        if(type === MetricType.Sort) {
          template.components.listMetrics.forEach(listMetric => {
            listMetric.sortOptions.forEach(option => {
              if(variable.label === option.label) {
                option.data = value;
              }
            });
          });
        }
        this.updateTemplateValueKeys(
          variable.label.replace(/\{/g, '').replace(/\}/g, ''),
          '',
          type,
          TemplateUpdateActionType.UpdateValue,
          '',
          value === 'text' ? [''] : ['', ''],
        );
        variable.value = value;
      }
      this.setState({ metricVariables: [...metricVariables], formIsUpdated: true, template });
    }
  }

  public handleDeleteVariable(id: number) {
    const { metricVariables } = this.state;
    const variable = metricVariables.find(mv => mv.id === id);

    if(variable) {
      const variableIndex = metricVariables.findIndex(mv => mv.id === id);
      metricVariables.splice(variableIndex, 1);

      this.updateTemplateValueKeys(variable.label.replace('{{', '').replace('}}', ''), '', variable.type, TemplateUpdateActionType.Delete);
      this.setState({ metricVariables: [...metricVariables], formIsUpdated: true });
    }
  }
  
  //#endregion
  
  //#region Metric functions

  public handleAddMetric(type: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const idList =  newTemplate.components.valueMetrics.map(x => x.id).concat(newTemplate.components.listMetrics.map(x => x.id)).concat(newTemplate.components.textMetrics.map(x => x.id));
    const id = idList.length > 0 ? idList.reduce(function(a, b) {
      return Math.max(a, b);
    }, -Infinity) : 0;

    if(type === 'value') {        
      newTemplate.components.valueMetrics.push({
        id: id + 1,
        format: '',
        increaseGood: false,
        label: 'Value metric ' + (id + 1),
        tooltip: 'Value metric tooltip',
        total: '',
        value: '',
      });

    } else if(type === 'list') {
      const label = 'List metric ' + (newTemplate.components.listMetrics.length + 1);
      newTemplate.components.listMetrics.push({
        id: id + 1,
        label: label,
        tooltip: 'List metric tooltip',
        sortOptions: [],
        style: 'numbered',
        flavouredData: '',
        data: label.replace(/\s/g, ''),
        items: { title: '', metrics: [] },
      });

      this.updateTemplateValueKeys(label.replace(/\s/g, ''), '', MetricType.List, TemplateUpdateActionType.Add);
    } else {
      newTemplate.components.textMetrics.push({
        id: id + 1,
        text: 'Text metric ' + (id + 1),
        tooltip: 'Text metric tooltip',
      });
    }
    this.setState({ template: newTemplate, formIsUpdated: true });
  }

  public handleDeleteMetric(type:string, id: number) {
    const { template } = this.state;

    const newTemplate = { ...template };
    if(type === 'value') {
      const valueMetricIndex = newTemplate.components.valueMetrics.findIndex(x => x.id === id);
      newTemplate.components.valueMetrics.splice(valueMetricIndex, 1);
    } else if(type === 'list') {
      const listMetric = newTemplate.components.listMetrics.find(x => x.id === id);

      if(listMetric) {
        const listMetricIndex = newTemplate.components.listMetrics.findIndex(x => x.id === id);
        newTemplate.components.listMetrics.splice(listMetricIndex, 1);

        this.updateTemplateValueKeys(listMetric.label.replace(/\s/g, ''), '', MetricType.List, TemplateUpdateActionType.Delete);
      }
    } else {
      const textMetricIndex = newTemplate.components.textMetrics.findIndex(x => x.id === id);
      newTemplate.components.textMetrics.splice(textMetricIndex, 1);
    }
    this.setState({ template: newTemplate, formIsUpdated: true });
  }

  public assignMetricId(template: Template, metric: ValueMetric | ListMetric | TextMetric , id: any) {
    if(id.length <= 0) {
      this.showNotification('Metric id cannot be empty', true);
    } else if(template.components.valueMetrics.filter(x => x.id.toString() === id).length > 0 ||
      template.components.listMetrics.filter(x => x.id.toString() === id).length > 0 ||
      template.components.textMetrics.filter(x => x.id.toString() === id).length > 0) {
      this.showNotification('Two metrics cannot have the same id', true);
    } else {
      metric.id = id;
    }
  }
  
  public handleValueMetricInputUpdate(value: any, valueMetricId: any, fieldName: string) {
    const { template, metricVariables } = this.state;

    const newTemplate = { ...template };

    const valueMetric = newTemplate.components.valueMetrics.find(x => x.id === valueMetricId);
    if (valueMetric) {
      switch(fieldName) {
      case 'id':
        if(value !== valueMetric.id) {
          this.assignMetricId(newTemplate, valueMetric, value);
        }
        break;
      case 'label':
        if(value.length <= 0) {
          this.showNotification('Value metric label cannot be empty', true);
        } else {
          valueMetric.label = value;
        }
        break;
      case 'value':
        if(!this.keyExistsInTemplateValues(value, MetricType.Value) && value.length > 0) {
          this.updateTemplateValueKeys(value.replace('{{', '').replace('}}', ''), '', MetricType.Value, TemplateUpdateActionType.Add);
        }
        valueMetric.value = value;
        break;
      case 'tooltip':
        valueMetric.tooltip = value;
        break;
      case 'total':
        if(metricVariables.map(x => x.label).includes(value) && !this.keyExistsInTemplateValues(value, MetricType.Value) && value.length > 0) {
          this.updateTemplateValueKeys(value.replace('{{', '').replace('}}', ''), '', MetricType.Value, TemplateUpdateActionType.Add);
        }
        valueMetric.total = value;
        break;
      case 'increaseGood':
        valueMetric.increaseGood = value;
        break;
      }
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public isListMetricLabelValid(label: string) {
    const { template } = this.state;

    if(label.length === 0) {
      this.showNotification('List metric label cannot be empty', true);
      return false;
    }

    if(template.components.listMetrics.find(x => x.label === label)) {
      this.showNotification('A list metric already exists with that label', true);
      return false;
    }

    return true;
  }

  public handleListMetricInputUpdate(listMetricId: number, type: string, fieldName: string, value:any, id: number) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const listMetric = newTemplate.components.listMetrics.find(x => x.id === listMetricId);
    if (listMetric) {
      switch(type) {
      case 'listMetric':
        this.updateListMetric(newTemplate, listMetric, value, fieldName);
        break;
      case 'listItemMetric':
        this.updateListItemMetric(newTemplate, listMetric, id, value, fieldName);
        break;
      }
    }
  }

  public updateListMetric(template: Template, listMetric: ListMetric, value: any, fieldName: string) {
    switch(fieldName) {
    case 'id':
      if(listMetric.id !== value) {
        this.assignMetricId(template, listMetric, value);
      }
      break;
    case 'sort':
      this.updateSortOption(template, listMetric, value as SortOption[]);
      break;
    case 'label':
      if(this.isListMetricLabelValid(value)) {
        this.updateTemplateValueKeys(
          value.replace(/\s/g, ''), 
          listMetric.label.replace(/\s/g, ''), 
          MetricType.List, 
          TemplateUpdateActionType.Update);    
        listMetric.label = value;
        listMetric.data = value.replace(/\s/g, '');
      }
      break;
    case 'tooltip':
      listMetric.tooltip = value;
      break;
    case 'title':
      let key: string = '';
      let oldKey: string = '';
      let actionType: TemplateUpdateActionType = TemplateUpdateActionType.Add;
      const valueFormatted =  value.replace('{{', '').replace('}}', '');

      if(value.length === 0) {
        key = listMetric.label.replace('{{', '').replace('}}', '');
        actionType = TemplateUpdateActionType.Delete;
      } else {
        if(this.keyExistsInTemplateValues(value, MetricType.ListItem, listMetric.label.replace(/\s/g, ''))) {
          key = valueFormatted;
          oldKey = listMetric.items.title.replace('{{', '').replace('}}', '');
          actionType = TemplateUpdateActionType.Update;
        } else {
          key = valueFormatted;
          actionType = TemplateUpdateActionType.Add;
        }
      }
      this.updateTemplateValueKeys(
        key, 
        oldKey, 
        MetricType.ListItem, 
        actionType,
        listMetric.label.replace(/\s/g, ''));
          
      listMetric.items.title = value;

      if(value === '') {
        listMetric.style = 'numbered';
      } else {
        listMetric.style = '';
      }
      break;
    }
    this.setState({ template, formIsUpdated: true });
  }

  public updateListItemMetric(template: Template, listMetric: ListMetric, id: number, value: string, fieldName: string) {
    const { metricVariables } = this.state;
    const metric = listMetric.items.metrics.find(x => x.id === id);
    if(metric) {
      let key: string = '';
      let oldKey: string = '';
      let actionType: TemplateUpdateActionType = TemplateUpdateActionType.Add;
      const valueFormatted =  value.replace('{{', '').replace('}}', '');

      switch(fieldName) {
      case 'label':
        //value is not variable, check deleting it from componentValues
        if(!metricVariables.map(x => x.label).includes(value)) {
          key = metric.label.replace('{{', '').replace('}}', '');
          actionType = TemplateUpdateActionType.Delete;
        } else {
          //if metric exists in component values, update it
          if(this.keyExistsInTemplateValues(metric.label.replace(/\s/g, ''), MetricType.ListItem, listMetric.label.replace(/\s/g, ''))) {
            key = valueFormatted;
            oldKey = metric.label.replace('{{', '').replace('}}', '');
            actionType = TemplateUpdateActionType.Update;
          } else {
            key = valueFormatted;
            actionType = TemplateUpdateActionType.Add;
          }
        }
        metric.label = value;
        break;
      case 'value':
        if(!metricVariables.map(x => x.label).includes(value)) {
          key = metric.value.replace('{{', '').replace('}}', '');
          actionType = TemplateUpdateActionType.Delete;
        } else {
          if(this.keyExistsInTemplateValues(metric.value.replace(/\s/g, ''), MetricType.ListItem, listMetric.label.replace(/\s/g, ''))) {
            key = metric.value.replace('{{', '').replace('}}', '');
            oldKey = valueFormatted;
            actionType = TemplateUpdateActionType.Update;
          } else {
            key = valueFormatted;
            actionType = TemplateUpdateActionType.Add;
          }
        }
        metric.value = value;
        break;
      case 'suffix':
        if(metric.suffix) {
        //if metric exists in component values, update it
          if(this.keyExistsInTemplateValues(metric.value.replace(/\s/g, ''), MetricType.ListItem, listMetric.label.replace(/\s/g, ''))) {
            key = metric.suffix.replace('{{', '').replace('}}', '');
            oldKey = valueFormatted;
            actionType = TemplateUpdateActionType.Update;
          } else {
            key = metric.suffix.replace('{{', '').replace('}}', '');
            actionType = TemplateUpdateActionType.Update;
          }
          metric.suffix = value;
          break;
        }

      }
      
      if(key.length > 0) {
        this.updateTemplateValueKeys(
          key, 
          oldKey, 
          MetricType.ListItem, 
          actionType,
          listMetric.label.replace(/\s/g, ''));
      }
      this.setState({ template, formIsUpdated: true });
    }
  }

  public updateSortOption(template: Template, listMetric: ListMetric, value: SortOption[]) {
    listMetric.sortOptions = value.map(v => ({ data: v.data, label: v.label }));
    this.setState({ template, formIsUpdated: true });
  }

  public handleAddListItemMetricEntry(id: number){
    const { template } = this.state;

    const newTemplate = { ...template };
    const listMetric = newTemplate.components.listMetrics.find(x => x.id === id);
    if(listMetric) {
      const id = listMetric.items.metrics.length > 0 ? listMetric.items.metrics.map((v) => v.id).reduce(function(a, b) {
        return Math.max(a, b);
      }, -Infinity) : 0;

      listMetric.items.metrics.push({
        id: id + 1,
        format: '',
        label: '',
        value: '',
      });
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public handleDeleteListItemMetric(id?: number, listMetricId?: number) {
    const { template, metricVariables } = this.state;

    const newTemplate = { ...template };
    const listMetric = newTemplate.components.listMetrics.find(x => x.id === listMetricId);
    if (listMetric) {
      const listItemMetric = listMetric.items.metrics.find(x => x.id === id);
      if(listItemMetric) {
        const index = listMetric.items.metrics.findIndex(x => x.id === id);
        listMetric.items.metrics.splice(index, 1);

        if(metricVariables.map(x => x.label).includes(listItemMetric.label)) {
          this.updateTemplateValueKeys(
            listItemMetric.label.replace('{{', '').replace('}}', ''),
            '',
            MetricType.ListItem,
            TemplateUpdateActionType.Delete,
            listMetric.label.replace(/\s/g, ''));
        }   

        if(metricVariables.map(x => x.label).includes(listItemMetric.value)) {
          this.updateTemplateValueKeys(
            listItemMetric.value.replace('{{', '').replace('}}', ''),
            '',
            MetricType.ListItem,
            TemplateUpdateActionType.Delete,
            listMetric.label.replace(/\s/g, ''));
        }
        this.setState({ template: newTemplate, formIsUpdated: true });
      }
    }
  }

  public handleTextMetricInputUpdate(value: any, textMetricId: number, fieldName: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const textMetric = newTemplate.components.textMetrics.find(x => x.id === textMetricId);
    if (textMetric) {
      switch(fieldName) {
      case 'id':
        if(value !== textMetric.id) {
          this.assignMetricId(newTemplate, textMetric, value);
        }
        break;
      case 'text':
        if(value.length <= 0) {
          this.showNotification('Text metric label cannot be empty', true);
        } else {
          textMetric.text = value;
        }
        break;
      case 'tooltip':
        textMetric.tooltip = value;
        break;
      }
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }
  
  //#endregion

  //#region Metric values
  public constructValues(scoringEntityDataId: string) {
    const { template, metricVariables } = this.state;
    
    const component = template.componentValues.find(x => x.scoringEntityDataId);

    if(component) {
      return (
        <div className='metric-values-wrapper'>
          <div
            className='value-metrics-wrapper'>
            {
              metricVariables.filter(x => x.type === MetricVariableType.Value).length === 0 ?
                <p>This scoring area does not contain any value variables</p>
                :
                metricVariables.filter(x => x.type === MetricVariableType.Value).map(metric => (
                  metric.label.replace('{{', '').replace('}}', '') in component.metrics &&
                  <div className='value-metrics-container'
                    key={metric.id}>
                    <label>{metric.label}</label>
                    <input type={metric.value === 'text' ||  metric.value === 'date' || metric.value === 'currency' ? 'text' : 'number' }
                      placeholder='min'
                      className={metric.value === 'text' ||  metric.value === 'date' || metric.value === 'currency' ? 'text' : '' }
                      value={this.getVariableValue(template, scoringEntityDataId, metric, 'min')}
                      onChange={(e) => this.handleVariableValueUpdate(scoringEntityDataId, e.target.value, metric.label, 'min')}
                    />
                    {
                      metric.value !== 'text' && metric.value !== 'date' &&
                    <input type='number'
                      placeholder='max'
                      value={this.getVariableValue(template, scoringEntityDataId, metric, 'max')}
                      onChange={(e) => this.handleVariableValueUpdate(scoringEntityDataId, e.target.value, metric.label, 'max')}
                    />
                    }
                  </div>
                ))
            }
          </div>
          <h3 className='values-section-header'>List Metric Values</h3>
          <div className='list-metrics-wrapper'>
            {
              template.components.listMetrics.filter(x => x.label.length > 0).length === 0 ?
                <p>This scoring area does not contain any valid list metrics</p>
                :
                template.components.listMetrics.filter(x => x.label.length > 0 && x.items.metrics.length > 0).map(listMetric => (
                  <div key={listMetric.id}
                    className='list-metrics-container'>
                    <label>{listMetric.label}</label>
                    {
                      this.constructListMetricValues(template, scoringEntityDataId, listMetric)
                    }
                    <button onClick={() => this.handleListMetricItemAdd(scoringEntityDataId, listMetric)}
                      className='list-metrics-container add-item'>+ Add item to the list</button>
                  </div>
                ))
            }
          </div>
        </div>
      );
    }
  }

  public constructListMetricValues(template: Template, scoringEntityDataId: string, listMetric: ListMetric) {
    const { metricVariables } = this.state;
    const inputFields: any = [];

    const metricVariableLabels = metricVariables.filter(x => x.type !== MetricVariableType.Sort).map(x => x.label);

    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    if(component) {
      const values = component.listMetrics[listMetric.label.replace(/\s/g, '')];

      if(values) {
        for (let i = 0; i < values.length; i++) {
          inputFields.push(
            <div className='list-metric-values'
              key={i + listMetric.id}>
              <button>
                <img onClick={() => this.handleListMetricValueDelete(scoringEntityDataId, listMetric.label, i)}
                  src='/assets/cross.png'/>
              </button>
              {
                listMetric.items.title.length > 0 && 
                  <input
                    className='list-metric-title'
                    type='text'
                    placeholder={listMetric.items.title}
                    value={metricVariableLabels.includes(listMetric.items.title) ? 
                      this.replacePlaceholders(listMetric.items.title, values[i], 'min') : listMetric.items.title }
                    onChange={(e) => this.handleListMetricValueChange(scoringEntityDataId, e.target.value, listMetric.label, i, listMetric.items.title, 'min')}
                    disabled={!metricVariableLabels.includes(listMetric.items.title)}
                  />
              }
              {
                listMetric.items.metrics.map(metric => (
                  <div key={metric.id}
                    className='list-metric-value'>
                    <>
                      <input
                        type='text'
                        placeholder={metric.label}
                        value={metricVariableLabels.includes(metric.label) ? 
                          this.replacePlaceholders(metric.label, values[i], 'min') : metric.label }
                        onChange={(e) => this.handleListMetricValueChange(scoringEntityDataId, e.target.value, listMetric.label, i, metric.label, 'min')}
                        disabled={!metricVariableLabels.includes(metric.label)}
                      />
                    </>
                    <>
                      <input
                        type={this.getListMetricValueFormat(metric.value)}
                        value={metricVariableLabels.includes(metric.value) ? 
                          this.replacePlaceholders(metric.value, values[i], 'min') : metric.value }
                        placeholder={metric.value}
                        onChange={(e) => this.handleListMetricValueChange(scoringEntityDataId, e.target.value, listMetric.label, i, metric.value, 'min')}
                        disabled={!metricVariableLabels.includes(metric.value)}
                      />
                      {
                        this.getListMetricValueFormat(metric.value) !== 'text' &&
                        <input
                          type='number'
                          value={metricVariableLabels.includes(metric.value) ? 
                            this.replacePlaceholders(metric.value, values[i], 'max') : metric.value }
                          placeholder={metric.value}
                          onChange={(e) => this.handleListMetricValueChange(scoringEntityDataId, e.target.value, listMetric.label, i, metric.value, 'max')}
                          disabled={!metricVariableLabels.includes(metric.value)}
                        />
                      }

                    </>
                  </div>
                ))
              }
            </div>,
            <hr key={i}/>,
          );
        }
      }
    }
    return inputFields;
  }

  public getRawScore(template: Template, scoringEntityDataId: string, type: string) {
    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    if(component) {
      return type === 'min' ? component.rawScore[0] : component.rawScore[1];
    }
    return 0.0;
  }

  public handleVariableValueUpdate(scoringEntityDataId: string, value: string, variable: string, type: string) {
    const { template } = this.state;

    const newTemplate = { ...template };
    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    variable = variable.replace('{{', '').replace('}}', '');

    if(component) {
      if(!(variable in component.metrics)) {
        component.metrics[variable] = {};
      }
      if(type === 'min') {
        component.metrics[variable][0] = value; 
      } else {
        component.metrics[variable][1] = value;
      }
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public getVariableValue(template: Template, scoringEntityDataId: string, metric: MetricVariable, type: string) {
    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    const metricLabel = metric.label.replace('{{', '').replace('}}', '');

    if(component) {
      if(component.metrics[metricLabel]) {
        return type === 'min' ? 
          component.metrics[metricLabel][0] 
          : 
          component.metrics[metricLabel][1];
      }
    }
    return '';
  }

  public handleListMetricValueChange(scoringEntityDataId: string, value: any, listMetricLabel: string, itemIndex: number, fieldName: string, type: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const component = newTemplate.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    if(component) {
      type === 'min' ? 
        component.listMetrics[listMetricLabel.replace(/\s/g, '')][itemIndex][fieldName.replace('{{', '').replace('}}', '')][0] = value
        :
        component.listMetrics[listMetricLabel.replace(/\s/g, '')][itemIndex][fieldName.replace('{{', '').replace('}}', '')][1] = value;
      
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public handleListMetricValueDelete(scoringEntityDataId: string, listMetricLabel: string, itemIndex: number) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const component = newTemplate.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    if(component) {
      component.listMetrics[listMetricLabel.replace(/\s/g, '')].splice(itemIndex, 1);
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public handleListMetricItemAdd(scoringEntityDataId: string, listMetric: ListMetric) {
    const { template } = this.state;

    const newTemplate = { ...template };
    const component = newTemplate.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    const value: any = {};

    listMetric.items.metrics.forEach(metric => {
      if(metric.label.startsWith('{{')) {
        value[metric.label.replace('{{', '').replace('}}', '')] = ['', ''];    
      }

      if(metric.value.startsWith('{{')) {
        value[metric.value.replace('{{', '').replace('}}', '')] = ['', ''];
      }
    });

    if(listMetric.items.title.length > 0) {
      value[listMetric.items.title.replace('{{', '').replace('}}', '')] = ['', ''];
    }

    if(component) {
      component.listMetrics[listMetric.label.replace(/\s/g, '')].push(value);

      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public getListMetricValueFormat(listMetricLabel: string) {
    const { metricVariables } = this.state;

    const variable = metricVariables.find(x => x.label === listMetricLabel);
    if(variable) {
      return variable.value !== 'text' ? 'number' : 'text';
    }
    return 'text';
  }

  public handleRawScoreUpdate(value: number, scoringEntityDataId: string, type: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const component = newTemplate.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    if(component) {
      type === 'min' ? component.rawScore[0] = value : component.rawScore[1] = value;
    }

    this.setState({ template: newTemplate, formIsUpdated: true });
  }
  //#endregion

  //#region Suggestions

  public constructSuggestions(scoringEntityDataId: string) {
    const { template } = this.state;

    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);

    if(component) {
      const suggestions = component.suggestions.map(suggestion => (
        <div className='suggestions-wrapper' 
          key={suggestion.id}>
          <div
            className='suggestions-container'>
            <Input 
              inputType='text'
              value={suggestion.message}
              label='Message'
              placeholderText='Message'
              name='message'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'message')}
            />
            <Input 
              inputType='text'
              value={suggestion.suggestion}
              label='Why am I seeing this?'
              placeholderText='Suggestion'
              name='suggestion'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'suggestion')}
            />
            <Input 
              inputType='text'
              value={suggestion.explanation}
              label='Why does this matter?'
              placeholderText='Explanation'
              name='explanation'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'explanation')}
            />
          </div>
          <div className='suggestions-container-2'>
            <Input 
              inputType='number'
              value={suggestion.metric.toString()}
              label='Value'
              placeholderText='Value'
              name='value'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'metric')}
            />
            <Input 
              inputType='number'
              value={suggestion.minimum.toString()}
              label='Minimum'
              placeholderText='Minimum'
              name='minimum'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'minimum')}
            />
            <Input 
              inputType='number'
              value={suggestion.average.toString()}
              label='Average'
              placeholderText='Average'
              name='average'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'average')}
            />
            <Input 
              inputType='number'
              value={suggestion.maximum.toString()}
              label='Maximum'
              placeholderText='Maximum'
              name='maximum'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'maximum')}
            />
            <Input 
              inputType='number'
              value={suggestion.weighting.toString()}
              label='Weighting'
              placeholderText='Weight'
              name='weighting'
              onChange={(e: string) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e, 'weighting')}
            />
            <div className='checkbox'>
              <label>Good result</label>
              <input 
                type='checkbox'
                checked={suggestion.goodResult}
                name='goodResult'
                onChange={(e) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e.target.checked, 'goodResult')}
              />
            </div>
            <div className='checkbox'>
              <label>High positive</label>
              <input 
                type='checkbox'
                checked={suggestion.highPositive}
                name='highPositive'
                onChange={(e) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e.target.checked, 'highPositive')}
              />
            </div>
            <div className='checkbox'>
              <label>Sustainable</label>
              <input 
                type='checkbox'
                checked={suggestion.sustainable}
                name='sustainable'
                onChange={(e) => this.handleSuggestionUpdate(suggestion.id, scoringEntityDataId, e.target.checked, 'sustainable')}
              />
            </div>
          </div>
          <div className='delete-suggestion-btn'>
            <button onClick={() => this.handleDeleteSuggestion(suggestion.id, scoringEntityDataId)}><img src='/assets/cross.png'/></button>
          </div>
        </div>
      ));

      return <div>
        {suggestions} 
        {component.suggestions && component.suggestions.length <= 5 && 
        <div className='add-suggestion-btn'>
          <button onClick={() => this.handleAddSuggestion(scoringEntityDataId)}>+ Add suggestion</button>
        </div>
        }
      </div>;
    }
  }

  public handleAddSuggestion(scoringEntityDataId: string) {
    const { template, name } = this.state;

    const newTemplate = { ...template };
    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    if(component) {
      const id = component.suggestions.length > 0 ? component.suggestions.map((s) => parseInt(s.id)).reduce(function(a, b) {
        return Math.max(a, b);
      }, -Infinity) : 0;
      
      component.suggestions.push({
        id: (id + 1).toString(),
        areaName: name.toLowerCase().trim().replace(' ', '-'),
        animationId: 0,
        average: 0,
        explanation: '',
        goodResult: false,
        highPositive: false,
        maximum: 0,
        message: '',
        metric: 0,
        metricName: '',
        minimum: 0,
        rank: 0,
        suggestion: '',
        sustainable: false,
        weighting: 0,
      });
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public handleSuggestionUpdate(id: string, scoringEntityDataId: string, value: any, fieldName: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const component = newTemplate.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    if(component) {
      const suggestion = component.suggestions.find(x => x.id === id);
      if(suggestion) {
        suggestion[fieldName as keyof Suggestion] = value as never;

        this.setState({ template: newTemplate, formIsUpdated: true });
      }
    }
  }

  public handleDeleteSuggestion(id: string, scoringEntityDataId: string) {
    const { template } = this.state;

    const newTemplate = { ...template };

    const component = template.componentValues.find(x => x.scoringEntityDataId === scoringEntityDataId);
    if(component) {
      const suggestionIndex = component.suggestions.findIndex(x => x.id === id);
      component.suggestions.splice(suggestionIndex, 1);
      this.setState({ template: newTemplate, formIsUpdated: true });
    }
  }

  public getIconName(imageSrc: string) {
    const selectedIcon = this.state.scoringIconOptions.find((icon) => icon.src === imageSrc);
    if( selectedIcon ) this.setState({ selectedScoringIconName: selectedIcon.name });
  }
  
  public loadStaticImages() {
    const images = this.importAll(require.context('../../../public/assets/areas/', false, /\.(png|jpe?g|svg)$/));

    const iconOptions: IconOption[] = Object.keys(images).map((key) => {
      const index = key.lastIndexOf('.');
      const name = key.slice(0, index);
      return {
        name,
        src: key,
      };   
    });

    this.setState({ scoringIconOptions: iconOptions });
  }

  public importAll(items: any) {
    const images: any = {};
    items.keys().map((item: string) => { images[item.replace('./', '')] = items(item); });
    return images;
  }

  //#endregion

  public render() {
    const {
      name,
      sequence,
      selectedScoringIcon,
      selectedScoringIconName,
      scoringIconOptions,
      formIsUpdated,
      formIsSubmitted,
      template,
      metricVariables,
      mainDetailsSectionActive,
      variablesSectionActive,
      valueMetricsSectionActive,
      listMetricsSectionActive,
      textMetricsSectionActive,
      areaDataSectionActive,
    } = this.state;

    const {
      match: {
        params: {
          scoringAreaId,
        },
      }, scoringAreaState: { isLoading }, scoringEntities: { scoringEntities } } = this.props;

    return (
      <>
        <CustomPrompt
          when={formIsUpdated}
          navigate={(path: string) => {
            this.props.history.push(path);
          }}
          shouldBlockNavigation={() => {
            if (formIsUpdated) {
              return true;
            }
            return false;
          }}
        />
        <div className='scoring-areas-form'>
          <div className="text-wrapper">
            <h1>{`${scoringAreaId === '0' ? 'Create' : 'Edit'} Scoring Area`}</h1>
          </div>

          { isLoading && <Loading/> }

          { 
            !isLoading && 
            <Form title={''}>

              {/* Main Details */}
              <h2 onClick={() => this.setState({ mainDetailsSectionActive: !mainDetailsSectionActive })}
                className='section-header'>Main details</h2> 
              <div className={`section-wrapper ${mainDetailsSectionActive ? 'active' : ''}`}>
                <div className='input-container'>
                  <Input
                    name="name"
                    label="Area Name"
                    placeholderText="Name"
                    inputType="text"
                    onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
                    value={name}
                    valid={ formIsSubmitted && name.length === 0 }
                  />
                </div>

                <div className='input-container'>
                  <Input
                    name="sequence"
                    label="Area Sequence"
                    placeholderText="Sequence"
                    inputType="number"
                    onChange={(e: string) => this.handleTextInputUpdate(e, 'sequence')}
                    value={sequence.toString()}
                    valid={ formIsSubmitted && name.length === 0 }
                  />
                </div>

                <div className='input-container'>
                  <p>Area Icon</p>
                  <div className='scoring-icon'>
                    <select className='scoring-icon-select'
                      value={selectedScoringIcon}
                      onChange={(e) => this.setState({ selectedScoringIcon: e.target.value })}>
                      <option value="">Select an icon</option>
                      { scoringIconOptions.map((img: IconOption) => <option value = { img.src }
                        key={img.src}> { img.name } </option>)
                      }
                    </select>
                    { selectedScoringIcon &&
                    <div className='image-uploader-preview'>
                      <img src={ `/assets/areas/${selectedScoringIcon}` } />
                    </div>
                    }
                  </div>
                </div>
              </div>

              {/* Variables */}
              <h2 onClick={() => this.setState({ variablesSectionActive: !variablesSectionActive })}
                className='section-header'>Variables</h2>
              <div className={`section-wrapper ${variablesSectionActive ? 'active' : ''}`}>
                
                <h3 className='variable-sub-title'>Value variables</h3>
                
                <div className='variables-wrapper'>
                  { metricVariables.filter((v) => v.type === MetricVariableType.Value).map((v) => (
                    <div key={v.id}
                      className='variables-container'>                
                      <div className='input-container'>
                        <Input
                          name="value"
                          label=""
                          placeholderText="Value label"
                          inputType="text"
                          onChange={(e: string) => this.handleVariableInputUpdate(v.id, e, 'label', MetricType.Value)}
                          value={v.label}
                        />
                      </div>

                      <div className='input-container'>
                        <select onChange={(e) => this.handleVariableInputUpdate(v.id, e.target.value, 'value', MetricType.Value)}
                          value={v.value}>
                          {valueFormats.map((format) => (
                            <option key={format}
                              value={format}>{format}</option>
                          ))}
                        </select>
                      </div>
                      <button onClick={() => this.handleDeleteVariable(v.id)}
                        className='delete-variable'><img src='/assets/cross.png'/></button>
                    </div>
                  )) }
                </div>
                
                <div className='add-variable'>
                  <button onClick={() => this.handleAddVariable(MetricVariableType.Value)}>+ Add variable</button>
                </div>

                <h3 className='variable-sub-title'>List Item variables</h3>
                <div className='variables-wrapper'>
                  { metricVariables.filter((v) => v.type === MetricVariableType.ListItem).map((v) => (
                    <div key={v.id}
                      className='variables-container'>                
                      <div className='input-container'>
                        <Input
                          name="value"
                          label=""
                          placeholderText="Value label"
                          inputType="text"
                          onChange={(e: string) => this.handleVariableInputUpdate(v.id, e, 'label', MetricType.ListItem)}
                          value={v.label}
                        />
                      </div>

                      <div className='input-container'>
                        <select onChange={(e) => this.handleVariableInputUpdate(v.id, e.target.value, 'value', MetricType.ListItem)}
                          value={v.value || 'text'}>
                          {valueFormats.map((format) => (
                            <option key={format}
                              value={format}>{format}</option>
                          ))}
                        </select>
                      </div>
                      <button onClick={() => this.handleDeleteVariable(v.id)}
                        className='delete-variable'><img src='/assets/cross.png'/></button>
                    </div>
                  )) }
                </div>

                <div className='add-variable'>
                  <button onClick={() => this.handleAddVariable(MetricVariableType.ListItem)}>+ Add variable</button>
                </div>

                <h3 className='variable-sub-title'>List sort options</h3>
                <div className='variables-wrapper'>
                  { metricVariables.filter((v) => v.type === MetricVariableType.Sort).map((v) => (
                    <div key={v.id}
                      className='variables-container'>                
                      <div className='input-container'>
                        <Input
                          name="value"
                          label=""
                          placeholderText="Value label"
                          inputType="text"
                          onChange={(e: string) => this.handleVariableInputUpdate(v.id, e, 'label', MetricType.Sort)}
                          value={v.label}
                        />
                      </div>

                      <div className='input-container'>
                        <select onChange={(e) => this.handleVariableInputUpdate(v.id, e.target.value, 'value', MetricType.Sort)}
                          value={ v.value.startsWith('{{') ? v.value : `{{${v.value}}}`}>
                          {metricVariables.filter(m => m.type === MetricVariableType.ListItem && m.label.length > 0).map((listVariable) => (
                            <option key={listVariable.id}
                              value={listVariable.label}>{listVariable.label}</option>
                          ))}
                        </select>
                      </div>
                      <button onClick={() => this.handleDeleteVariable(v.id)}
                        className='delete-variable'><img src='/assets/cross.png'/></button>
                    </div>
                  )) }
                </div>

                <div className='add-variable'>
                  <button onClick={() => this.handleAddVariable(MetricVariableType.Sort)}>+ Add variable</button>
                </div>
              </div>
              

              {/* Value Metrics */}
              <h2 onClick={() => this.setState({ valueMetricsSectionActive: !valueMetricsSectionActive })}
                className='section-header'>Value Metrics</h2>
              <div className={`section-wrapper ${valueMetricsSectionActive ? 'active' : ''}`}>
                
                {
                  template && template.components.valueMetrics.length === 0 &&
                    <p className='info'>This score area does not contain any value metrics</p>
                }
                {
                  template && 
                  template.components.valueMetrics.sort(x => x.id).map((v) => (
                    <ScoreValueMetric
                      key={v.id}
                      id={v.id}
                      label={v.label}
                      value={v.value}
                      total={v.total}
                      format={v.format}
                      change={0}
                      increaseGood={v.increaseGood}
                      tooltip = {v.tooltip}
                      editMode={true}
                      grandparentClicked={false}
                      handleGrandparentClicked={() => null}
                      metricVariables={metricVariables.filter((v) => v.type === MetricVariableType.Value).map((v) => v.label)}
                      onInputChange={(e: any, fieldName: string) => this.handleValueMetricInputUpdate(e, v.id, fieldName)}
                      onDelete={() => this.handleDeleteMetric('value', v.id)}
                      showGraph={false} 
                      onToggleGraph={() => {}}
                    />
                  ))
                }
                <div className='add-metric-btn'><button onClick={() => this.handleAddMetric('value')}>+ Add value metric</button></div>
              </div>


              {/* List Metrics */}
              <h2 onClick={() => this.setState({ listMetricsSectionActive: !listMetricsSectionActive })}
                className='section-header'>List Metrics</h2>
              <div className={`section-wrapper ${listMetricsSectionActive ? 'active' : ''}`}>
                
                {
                  template && template.components.listMetrics.length === 0 &&
                    <p className='info'>This score area does not contain any list metrics</p>
                }
                {
                  template && template.components.listMetrics.map((listMetric) => (
                    <ScoreListMetric
                      key={listMetric.id}
                      id={listMetric.id}
                      label={listMetric.label}
                      tooltip = {listMetric.tooltip}
                      grandparentClicked={false}
                      handleGrandparentClicked={() => null}
                      flavourOptions={[]}
                      sortOptions={listMetric.sortOptions}
                      selectedFlavourKey={''}
                      onFlavourChange={() => null}
                      onSortChange={() => null}
                      editMode={true}
                      metricVariables={metricVariables.filter((v) => v.type === MetricVariableType.Sort).map((v) => ({ label: v.label, data: v.value, value:v.value }))}
                      onInputChange={(e: any, fieldName: string) => this.handleListMetricInputUpdate(listMetric.id, 'listMetric',  fieldName, e, 0)}
                      onDelete={() => this.handleDeleteMetric('list', listMetric.id)}
                    >
                      <ScoreListItemMetric
                        key={listMetric.id}
                        label={listMetric.items.title}
                        style={listMetric.style}
                        addMetricHandler={() => this.handleAddListItemMetricEntry(listMetric.id)}
                        onInputChange={(e: string) => this.handleListMetricInputUpdate(listMetric.id, 'listMetric', 'title', e, 0)}
                        metricVariables={metricVariables.filter((v) => v.type === MetricVariableType.ListItem).map((v) => v.label)}
                        editMode={true}>
                        {
                          listMetric.items.metrics.map((i) => (
                            <ScoreListItemValueMetric 
                              key={i.id}
                              label={i.label}
                              value={i.value}
                              format={i.format}
                              suffix={i.suffix}
                              editMode={true}
                              onInputChange={(e: string, fieldName: string) => this.handleListMetricInputUpdate(listMetric.id, 'listItemMetric', fieldName, e, i.id)}
                              onDelete={() => this.handleDeleteListItemMetric(i.id, listMetric.id)}
                              metricValues={metricVariables.filter(m => m.type === MetricVariableType.ListItem).map((v) => v.label)} />
                          ))
                        }
                      </ScoreListItemMetric>
                    </ScoreListMetric>
                  ))
                }
                <div className='add-metric-btn'><button onClick={() => this.handleAddMetric('list')}>+ Add list metric</button></div>
              </div>

              {/* { Text Metrics } */}
              <h2 onClick={() => this.setState({ textMetricsSectionActive: !textMetricsSectionActive })}
                className='section-header'>Text Metrics</h2>
              <div className={`section-wrapper ${textMetricsSectionActive ? 'active' : ''}`}>
                

                {
                  template && template.components.textMetrics.length === 0 &&
                    <p className='info'>This score area does not contain any text metrics</p>
                }
                {
                  template && template.components.textMetrics.map(t => (
                    <ScoreTextMetric key={t.id}
                      id={t.id}
                      text={t.text} 
                      grandparentClicked={false}
                      handleGrandparentClicked={() => null}
                      tooltip = {t.tooltip}
                      editMode={true}
                      onDelete={() => this.handleDeleteMetric('text', t.id)}
                      onInputChange={(e: any, fieldName: string) => this.handleTextMetricInputUpdate(e, t.id, fieldName)}
                    />
                  ))
                }

                <div className='add-metric-btn'><button onClick={() => this.handleAddMetric('text')}>+ Add text metric</button></div>
              </div>

              {/* Metric Data */}
              <h2 onClick={() => this.setState({ areaDataSectionActive: !areaDataSectionActive })}
                className='section-header'>Area Data</h2>
              {
                scoringEntities &&
                <div className={`section-wrapper ${areaDataSectionActive ? 'active' : ''}`}>
                  {
                    scoringEntities.map(entity => (
                      <Accordion 
                        key={entity.dataID}
                        title={entity.name}
                        content={
                          <>
                            <h3>Raw Score</h3>
                            <div className='raw-score-wrapper'>
                              <input type='number'
                                className='raw-score-input'
                                min={0}
                                max={5.0} 
                                value={this.getRawScore(template, entity.dataID, 'min')}
                                onChange={(e) => this.handleRawScoreUpdate(parseFloat(e.target.value), entity.dataID, 'min')} />
                              <input type='number'
                                className='raw-score-input'
                                min={0}
                                max={5.0} 
                                value={this.getRawScore(template, entity.dataID, 'max')}
                                onChange={(e) => this.handleRawScoreUpdate(parseFloat(e.target.value), entity.dataID, 'max')} />
                            </div>

                            <h3 className='values-section-header'>Values</h3>
                            {
                              this.constructValues(entity.dataID)
                            }
                            <h3 className='values-section-header'>Suggestions</h3>
                            {
                              this.constructSuggestions(entity.dataID)
                            }
                          </>
                        }
                      /> 
                    ))
                  }
                </div>
              }

              <div className='actions'>
                <Button
                  text="Save"
                  onClick={() => this.handleSave()}
                  enabled
                />
                <Button
                  text="Cancel"
                  className='cancel-btn'
                  onClick={() => this.cancel()}
                  enabled
                />
              </div>
            </Form> }
        </div>
      </>
    );
  }
}

export default connector(withRouter(EditScoringArea));