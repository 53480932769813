const AzureADClientIdVersions = {
  'test': '28cff2bb-2ebf-4426-a0e0-f2b68b709134',
  'uat': '6f90df2a-7cb8-49b9-a2ab-00c8648ea8fd',
  'prod': 'c8f94592-66b8-4a5d-aba8-ba01adbac019',
};

export const MsalConfig = {
  auth: {
    clientId: getClientId(),
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

function getClientId() {
  const domain = window.location.hostname;

  switch(domain) {
  case 'localhost':
  case 'score-qa.orderly.io':
    return AzureADClientIdVersions.test;
  case 'score-uat.orderly.io':
    return AzureADClientIdVersions.uat;
  default:
    return AzureADClientIdVersions.prod;
  }
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const LoginRequest = {
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  scopes: ['openid', 'profile', 'User.Read'],
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const ApiRequest = {
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
  scopes: [`api://${getClientId()}/Data.Access`],
};