import * as React from 'react';
import Page from '../../uiToolkit/containers/page';

export const DefaultConfigPage = () => {
  return (
    <Page mode='default'
      pageNumber={1}
      padding>
      <div className='config-body'>
        <h1 className='title'>Configuration</h1>
        <div className='body'>Customise Scorecards for your business. Configure all aspects of your Scores, Stores and Users.</div>
      </div>
    </Page>
  );
};
