import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as UserStore from '../../store/configuration/Users';
import Error from '../Common/Error/error';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  users: state.users,
});
const mapDispatch = {
  ...UserStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  filter: string;
  page: number;
  pageSize: number;
}

class Users extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: '',
      page: 1,
      pageSize: 10,
    };
  }

  public componentDidMount() {
    const { history, requestUsers } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());

    const queryFilter: string = (query.filter && query.filter.toString()) || '';
    const queryPage: number = parseInt((query.page && query.page.toString()) || '1', 10) || 1;

    const newState: IState = {
      ...this.state,
    };

    let updateState: boolean = false;

    if (newState.filter !== queryFilter && queryFilter.length > 0) {
      updateState = true;
      newState.filter = queryFilter;
    }

    if (newState.page !== queryPage && queryPage > 0) {
      updateState = true;
      newState.page = queryPage;
    }

    if (updateState) {
      this.setState(newState);
    }

    requestUsers(newState.filter, newState.page, newState.pageSize);
  }

  public doSearch(filter: string) {
    const { location, history, requestUsers } = this.props;
    const { pathname } = location;
    const { pageSize } = this.state;

    this.setState({ filter, page: 1 });

    history.replace(`${pathname}?filter=${filter}&page=${1}`);

    requestUsers(filter, 1, pageSize);
  }

  public doPaging(page: number) {
    const { location, history, requestUsers } = this.props;
    const { pathname } = location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    history.replace(`${pathname}?filter=${filter}&page=${page}`);

    requestUsers(filter, page, pageSize);
  }

  public render() {
    const { users, history } = this.props;
    const {
      filter,
      page,
      pageSize,
    } = this.state;

    const entries = users.users.sort((a, b) => a.id - b.id);

    return (
      <Page mode=" center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Users</h1>
            <p>
            Depending upon your access you will be able to change user permissions,
            which stores they can see and also which areas they can access.
            </p>
            <p>
            Please note changes to users will be reflected within 15 minutes or when
            the user next logs in.
            </p>
          </div>
          <Table
            add={false}
            addClick={() => null}
            addLabel=""
            search
            searchClick={(f: string) => this.doSearch(f)}
            initialValue={filter}
            pagination
            pageSize={pageSize}
            pageNumber={page}
            pageChange={(p: number) => this.doPaging(p)}
            totalRecords={users.totalRecords}
          >

            <thead>
              <tr>
                <th className="padding title">User</th>
                <th className="padding title">Scoring Entities</th>
                <th className="padding title">Scoring Areas</th>
              </tr>
            </thead>
            <tbody>

              {users.isLoading && 
                <tr><td colSpan={3}><Loading/></td></tr>}

              {users.isErrored && 
                <tr><td colSpan={3}><Error text={users.errorMessage} /></td></tr>}

              {entries.map((b) => (
                <tr
                  key={b.id.toString()}
                  onClick={() => history.push(`/configuration/users/${b.id}`)}
                >
                  <td className="padding">{b.name}</td>
                  <td className="padding">{b.scores}</td>
                  <td className="padding">{b.areas }</td>
                </tr>
              ))}

            </tbody>

          </Table>
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(Users));
