import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EditRolePermissionsState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

  
    totalRecords: number;
    items: RolePermission[];
}


export interface RolePermission {
    id: number;
    permission: Permission;
    roles: Role[];
}

export interface Permission {
    id: number;
    name: string;
}

export interface Role {
    id: number;
    name: string;
    hasPermission: boolean;
    isSuperUser: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects;
// they just describe something that is going to happen.

interface RequestRolePermissionsAction {
    type: 'REQUEST_ROLE_PERMISSIONS';
}

interface ReceiveRolePermissionsAction {
    type: 'RECEIVE_ROLE_PERMISSIONS';
    payload: any;
}

interface FailedRolePermissionsAction {
    type: 'FAILED_ROLE_PERMISSIONS';
}

interface RequestRolePermissionsAction {
    type: 'REQUEST_ROLE_PERMISSIONS';
}

interface ReceiveRolePermissionsAction {
    type: 'RECEIVE_ROLE_PERMISSIONS';
    payload: any;
}

interface FailedRolePermissionsAction {
    type: 'FAILED_ROLE_PERMISSIONS';
}

interface RequestSaveRolePermissionsAction {
    type: 'REQUEST_SAVE_ROLE_PERMISSIONS';
}

interface ReceiveSaveRolePermissionsAction {
    type: 'RECEIVE_SAVE_ROLE_PERMISSIONS';
    payload: any;
}

interface FailedSaveRolePermissionsAction {
    type: 'FAILED_SAVE_ROLE_PERMISSIONS';
}

// Declare a 'discriminated union' type.
// This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestRolePermissionsAction | ReceiveRolePermissionsAction | FailedRolePermissionsAction |
    RequestRolePermissionsAction | ReceiveRolePermissionsAction | FailedRolePermissionsAction |
    RequestSaveRolePermissionsAction | ReceiveSaveRolePermissionsAction | FailedSaveRolePermissionsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestRolePermisions: ():
        AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.users && appState.users.isLoading === false) {
      dispatch({
        type: 'REQUEST_ROLE_PERMISSIONS',
        http: {
          verb: 'GET',
          endpoint: '/api/Role/GetRolePermissions',
          successAction: 'RECEIVE_ROLE_PERMISSIONS',
          failureAction: 'FAILED_ROLE_PERMISSIONS',
        },
      });
    }
  },

  saveRolePermisions: (data: any[]): AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.users && appState.users.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_ROLE_PERMISSIONS',
        http: {
          verb: 'POST',
          endpoint: '/api/Role/Save',
          successAction: 'RECEIVE_SAVE_ROLE_PERMISSIONS',
          failureAction: 'FAILED_SAVE_ROLE_PERMISSIONS',
          body: {
            data,
          },
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: EditRolePermissionsState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  items: [],
  totalRecords: 0,
};

export const reducer: Reducer<EditRolePermissionsState> = (state: EditRolePermissionsState |
    undefined, incomingAction: Action): EditRolePermissionsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_ROLE_PERMISSIONS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      items: [],
      totalRecords: 0,

    };
  case 'RECEIVE_ROLE_PERMISSIONS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      items: action.payload.data,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_ROLE_PERMISSIONS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      items: [],
      totalRecords: 0,
    };
  case 'REQUEST_SAVE_ROLE_PERMISSIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_ROLE_PERMISSIONS':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_ROLE_PERMISSIONS':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
};

