import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as ReactGA from 'react-ga';
import * as ScoringAreasScore from '../../store/area/actions';
import Table from '../../uiToolkit/containers/table';
import Error from '../Common/Error/error';
import Loading from '../Common/Loading/loading';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  scoringAreaState: state.scoreArea,
});
const mapDispatch = {
  ...ScoringAreasScore.actionCreators,
};

type TParams = { tenantId: string, scoringAreaId: string };
const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

class ScoringAreas extends React.PureComponent<IProps, {}> {

  public componentDidMount() {
    const { match: {
      params: {
        tenantId,
      },
    }, requestScoreAreasByTenantId } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    requestScoreAreasByTenantId(tenantId);
  }

  public render() {
    const { match: {
      params: {
        tenantId,
      },
    }, history, scoringAreaState } = this.props;

    return (
      <>
        <div className="text-wrapper scoring-entries">
          <h1>Scoring Areas</h1>
          <p>A scoring area defines how your Orderly score get&apos;s calculated </p>
        </div>
        {!scoringAreaState.isLoading && !scoringAreaState.isErrored && scoringAreaState.scoringAreas.length === 0 &&
                    <div className="scoring-entries">
                      <div className='actions'>
                        <p>The current tenant does not have any scoring areas</p>
                        <button
                          type="button"
                          onClick={() => history.push(`/dashboard/tenant/${tenantId}/editScoringArea/0`)}
                        >
                          <div className="label">Create new Scoring Area</div>
                          <div className="plus-icon">
                                    +
                            <div className="circle-plus-icon"></div>
                          </div>
                        </button>
                      </div>
                    </div>
        }
        <div className="dashboard ui-toolkit">
          <Page mode="center dashboard"
            pageNumber={1}
            padding>
            <div className="dashboard-wrapper">
              <div className="dashboard-content">

                <Page mode="center"
                  pageNumber={1}>
                  <Table
                    add
                    addClick={() => this.props.history.push(`/dashboard/tenant/${tenantId}/editScoringArea/0`)}
                    addLabel="Create Scoring Area"
                    search={false}
                    searchClick={() => null}
                    initialValue=""
                    pagination={false}
                    pageSize={1}
                    pageNumber={1}
                    pageChange={() => null}
                    totalRecords={1}
                  >
                    <thead>
                      <tr>
                        <th className="padding title">Area Name</th>
                        <th className="padding title">Area Image</th>
                        <th className="padding title">Area DataID</th>
                        <th className="padding title">Sequence</th>
                        <th className="padding title">Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scoringAreaState.isLoading
                        && <tr><td colSpan={6}><Loading/></td></tr>}

                      {scoringAreaState.isErrored
                        && <tr><td colSpan={6}><Error text={scoringAreaState.errorMessage} /></td></tr>}

                      {scoringAreaState.scoringAreas.map((a) => (
                        <tr
                          key={a.id.toString()}
                          onClick={() => history.push(`/dashboard/tenant/${tenantId}/editScoringArea/${a.id}`)}
                        >
                          <td className="padding">{a.name}</td>
                          <td className="padding"><img src={`/assets/areas/${a.image}`}/></td>
                          <td className="padding">{a.dataId}</td>
                          <td className="padding">{a.sequence}</td>
                          <td className="padding"><button><img className='edit'
                            src='/assets/edit-icon.svg' /></button></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Page>
              </div>
            </div>
          </Page>
        </div>
      </>
    );
  }
}

export default connector(withRouter(ScoringAreas));
