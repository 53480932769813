import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as AuthActions from '../../../store/authentication/actions';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AuthManager } from '../../../AuthManager';
import { Avatar } from '../Avatar/avatar';
import { WaveScore } from '../WaveScore/waveScore';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  score: state.score,
});
const mapDispatch = AuthActions.actionCreators;

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps {
    children?: React.ReactNode;
}

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

class MenuBar extends React.PureComponent<IProps, { isOpen: boolean }> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  public componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  public render() {
    const {
      children,
      history,
      authentication: authState,
      score: scoreState,
    } = this.props;
    const { isOpen } = this.state;

    let id: string = '';
    let logo: string = '';
    let label: string = '';
    let tenant: string = '';
    let owner: string = '';
    let phoneNumber: string = '';
    let emailAddress: string = '';
    let score: number = 0;
    const maxScore: number = 5;
    const index: number = 0;

    id = (authState && authState.selectedReportingEntity) || '-';
    score = (scoreState && scoreState.rawScore) || 0;
    logo = (authState && authState.tenantDetails && authState.tenantDetails.logo) || '';
    tenant = (authState && authState.tenantDetails && authState.tenantDetails.name) || '-';

    const scoreEntity = scoreState && scoreState.siteId
            && authState && authState.scoringEntityLookup
            && authState.scoringEntityLookup[scoreState.siteId]
            && authState.scoringEntityLookup[scoreState.siteId];

    label = (scoreEntity && scoreEntity.name) || '-';
    owner = (scoreEntity && scoreEntity.owner.name) || '-';

    phoneNumber = (scoreEntity && scoreEntity.owner.phoneNumber) || '';
    emailAddress = (scoreEntity && scoreEntity.owner.emailAddress) || '';
    
    const authManager = new AuthManager();

    return (
      <>
        <header className="menu-bar ui-toolkit">
          <AuthenticatedTemplate>
            <button className="menu-button"
              type="button"
              onClick={() => this.setState({ isOpen: true })}>
              <img
                src="/assets/menu-icons/menu.svg"
                alt="Burger menu icon"
              />
            </button>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div className="counter-balance" />
          </UnauthenticatedTemplate>

          <div className="logo">
            <img className="img-logo"
              srcSet="/assets/logo-orderly-inverse.png 1x,
                             /assets/logo-orderly-inverse@2x.png 2x,
                             /assets/logo-orderly-inverse@3x.png 3x,
                            "
              src="/assets/logo-orderly-inverse.png"
              alt="Orderly logo"
              onClick={() => {history.push('/');}}
            />
          </div>
          <div className="flex-container">
            {window.location.href.indexOf('score/') > -1 && window.location.href.indexOf('product') === -1
                            && (
                              <div className="full-menu ui-toolkit">
                                <Avatar label={id}
                                  companyImageLink={logo} />
                                <WaveScore maxScore={maxScore}
                                  score={score}
                                  index={index} />
                                <div className="details">
                                  <div className="label">
                                    {label.replace(id, '').trim()}
                                  </div>
                                  <div className="tenant">
                                    {tenant}
                                  </div>
                                  <div className="owner-details">
                                    <div className="owner">
                                      {owner}
                                    </div>
                                    <div className="phoneNumber">
                                      {phoneNumber ?
                                        <a href={`tel:${phoneNumber}`}>Phone</a>
                                        : null}
                                    </div>
                                    <div className="email">
                                      {emailAddress ?
                                        <a href={`mailto:${emailAddress}`}>Email</a>
                                        : null}
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="logout-button"
                                  onClick={() => authManager.logout()}
                                >
                                  <img src="/assets/menu-icons/logout.svg"
                                    alt="exit doorway" />
                                </button>
                              </div>
                            )}
            <AuthenticatedTemplate>
              <div>
                <Link to="/help/frequent-questions">
                  <img className='help-logo'
                    src="/assets/menu-icons/help.png"
                    alt="Help" />
                </Link></div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <div className="counter-balance" />
            </UnauthenticatedTemplate>
          </div>
        </header>
        {isOpen && (
          <div
            className="side-menu ui-toolkit"
            aria-roledescription="background mask"
            onClick={() => this.setState({ isOpen: false })}
            onKeyDown={(e) => (e.keyCode === 27 ? this.setState({ isOpen: false }) : null)}
            role="mask"
          >
            <div
              className="side-menu"
              onClick={(e) => {
                e.preventDefault();

                if (e.target === e.currentTarget) e.stopPropagation();
              }}
            >
              {children}
            </div>
          </div>
        )}

      </>
    );
  }
}

export default connector(withRouter(MenuBar));
