import { min } from 'moment';
import * as React from 'react';

type PaginationProps = {
    pageSize: number,
    pageNumber: number,
    totalRecords: number,
    pageChange: Function
}

export default class Pagination extends React.PureComponent<PaginationProps, {}> {
    public render() {
        const {
            totalRecords, pageSize, pageNumber, pageChange,
        } = this.props;

        const minPage = 1;
        const maxPage = Math.ceil(totalRecords / pageSize);

        return (
            <>
                {totalRecords > 10 &&
                    <div className="pagination ui-toolkit">
                        <button
                            type="button"
                            className="previous"
                            disabled={pageNumber === minPage}
                            onClick={() => pageChange(minPage)}
                        >
                            {minPage}
                        </button>

                        <div className={pageNumber - 2 > minPage ? "" : "disabled"} >...</div>

                        <button
                            type="button"
                            className="previous"
                            disabled={(pageNumber - 1) <= minPage}
                            onClick={() => pageChange(pageNumber - 1)}
                        >
                            {pageNumber - 1}
                        </button>

                        <button
                            type="button"
                            className="current-page"
                            onClick={() => pageChange(pageNumber)}
                        >
                            {pageNumber}
                        </button>

                        <button
                            type="button"
                            className="next"
                            disabled={(pageNumber + 1) >= maxPage}
                            onClick={() => pageChange(pageNumber + 1)}
                        >
                            {pageNumber + 1}
                        </button>

                        <div className={pageNumber + 2 < maxPage ? "" : "disabled"} >...</div>

                        <button
                            type="button"
                            className="next"
                            disabled={pageNumber === maxPage}
                            onClick={() => pageChange(maxPage)}
                        >
                            {maxPage}
                        </button>
                    </div>
                }
            </>       
        );
  }
}
