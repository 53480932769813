import { Action, Reducer } from 'redux';
import { ScoreAction, Period, scoreArea, Suggestion,trendData  } from './types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ScoreState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;

    siteId:string,
    rawScore: number;
    period?: Period;
    scoreAreas?: scoreArea[];
    suggestions?: Suggestion[];

    trend?: trendData;
    prevTrend?:trendData;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const unloadedState: ScoreState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  
  siteId: '',
  rawScore: 0,

  scoreAreas: [],
  suggestions: [],
};

export const reducer: Reducer<ScoreState> = (state: ScoreState | undefined, incomingAction: Action):
ScoreState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as ScoreAction;
  switch (action.type) {
  case 'REQUEST_SCORE':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      
      siteId: '',
      rawScore: 0,

      scoreAreas: [],
      suggestions: [],
    };
  case 'RECEIVE_SCORE':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,

      siteId: action.payload.data.siteId,
      rawScore: action.payload.data.rawScore,
      period: action.payload.data.period,

      scoreAreas: action.payload.data.scoreAreas,
      suggestions: action.payload.data.suggestions.map((s, idx) => { return { ...s, id: 'suggestion' + idx, animationId: idx };}),

      trend: action.payload.trend,
      prevTrend: action.payload.prevTrend,
    };
  case 'FAILED_SCORE':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      
      siteId: '',
      rawScore: 0,

      scoreAreas: [],
      suggestions: [],
    };
  default:
    return state;
  }
};
