/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import CustomPrompt from '../../uiToolkit/components/customPrompt';
import { ApplicationState } from '../../store';
import * as TenantStore from '../../store/dashboard/Tenants';
import * as ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  tenants: state.tenants,
});

const mapDispatch = {
  ...TenantStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { id: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface TenantLogo {
  src: string;
  name: string;
}

interface IState {
    id: number;
    name: string;
    tenantLogo: any;
    threshold: string;
    tenantDataId: string;
    formIsUpdated:boolean;
    formIsSubmitted: boolean;
    tenantLogos: TenantLogo[];
    selectedTenantLogoName: string;
}

class EditTenant extends React.PureComponent<IProps, IState> {
    public logoUploader: any = React.createRef();

    constructor(props: IProps) {
      super(props);

      this.state = {
        id: 0,
        name: '',
        tenantLogo: '',
        threshold: '80',
        tenantDataId: '',
        formIsUpdated:false,
        formIsSubmitted: false,
        tenantLogos: [],
        selectedTenantLogoName: '',
      };
    }

    public componentDidMount() {
      const { match, requestTenant } = this.props;

      if (match.params.id !== '0') {
        requestTenant(match.params.id);
      }

      this.loadTenantImages();
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    public componentDidUpdate(prevProps: IProps) {
      const { tenants: { selectedTenant, isLoading, isUpdated, isErrored }, match: { params: { id } } } = this.props;

      if (selectedTenant !== prevProps.tenants.selectedTenant && !isLoading) {
        if (selectedTenant !== undefined) {
          this.setState({
            id: selectedTenant.id,
            tenantLogo: selectedTenant.tenantLogo,
            threshold: selectedTenant.threshold,
            name: selectedTenant.name,
            tenantDataId: selectedTenant.dataId,
          }, () => {
            this.getSelectedIconName(this.state.tenantLogo);
          });
        } else {
          this.setState({
            id: 0,
            name: '',
            tenantLogo: '',
            threshold: '',
            tenantDataId: '',
            tenantLogos: [],
            selectedTenantLogoName: '',
          });
        }
      }

      if (isUpdated && !prevProps.tenants.isUpdated) {
        this.showNotification(`Successfully ${ id === '0' ?  'created' : 'updated'} tenant`);
      } else if (isErrored && !prevProps.tenants.isErrored) {
        this.showNotification('There was an error saving tenant');
      }   
    }
 
    public showNotification(message: string) {
      const { history } = this.props;
      const { tenants: { isErrored } } = this.props;
      
      this.setState({ formIsUpdated: false });

      if(isErrored){
        toast.error(message, {
          className: 'toast-popup error',
        });
      } else {
        toast.info(message);
        history.goBack();
      }
    }

    public handleTextInputUpdate(value: React.ChangeEvent<HTMLInputElement> | string, fieldName: string) {
      if (typeof value !== 'string') {
        value = value.target.value;
        if (!(Number(value) >= 0 && Number(value) <= 100)) {
          value = value.slice(0, -1); 
        }
      }

      const update: object = { [fieldName]: value };
      this.setState(update);
      this.setState({ formIsUpdated: true });
    }

    public handleSave() {
      const { saveTenant } = this.props;
      const { id, name, tenantLogo, threshold, tenantDataId } = this.state;

      this.setState({ formIsSubmitted: true });
        
      const data = { id, name, tenantLogo,threshold, tenantDataId };
      if (name && threshold && tenantLogo) {
        saveTenant(data);
      } 
    }

    public cancel() {
      this.setState({ formIsUpdated: false, formIsSubmitted: false }, () => {
        if (!this.state.formIsUpdated) {
          this.props.history.goBack();
        }
      });
    }

    
    public getBackgroundSize = () => {
      const { threshold } = this.state;
      return { backgroundSize: `${(parseInt(threshold) * 100) / 100}% 100%` };
    };

    public importAllImages(imgsPath: any) {
      const images: any = {};
      imgsPath.keys().map((item: string) => { images[item.replace('./', '')] = imgsPath(item); });
      return images;
    }

    public getSelectedIconName(imgSrc: string) {
      const { tenantLogos } = this.state;
      const  logo =  tenantLogos.find((img) => img.src === imgSrc);
      if(logo) this.setState({ selectedTenantLogoName: logo.name });
    } 

    public loadTenantImages(){
      const images = this.importAllImages(require.context('../../../public/assets/tenants/', false, /\.(png|jpe?g|svg)$/));
      const tenantLogos = Object.keys(images).map((image) => {
        const index = image.lastIndexOf('.');
        return {
          src: image,
          name: image.slice(0, index),
        };
      });

      this.setState({ tenantLogos });
    }

    public render() {
      const {
        id,
        name,
        threshold,
        tenantLogo,
        formIsUpdated,
        formIsSubmitted,
        tenantLogos,
      } = this.state;

      const { isLoading } = this.props.tenants;

      return (
        <>
          <CustomPrompt
            when={formIsUpdated}
            navigate={(path:string) => {
              this.props.history.push(path);
            }}
            shouldBlockNavigation={() => {
              if (formIsUpdated) {
                return true;
              }
              return false;
            }}
          />
               
          <div className='tenant-form-layout'>
            <div className="text-wrapper">
              <h1>{`${id === 0 ? 'Create' : 'Edit'} Tenant`}</h1>
              <p>In this page you can edit or create new tenants used for demo purposes. Each user can be assigned to only one tenant. </p>
            </div>

            {isLoading && <Loading/>}

            { !isLoading && <Form title={''}>
              <div className='input-container'>
                <Input
                  name="name"
                  label="Name"
                  placeholderText="Name"
                  inputType="text"
                  onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
                  value={name}
                  valid={ formIsSubmitted && name.length === 0 }
                />
              </div>
                   
              <div className='input-container'>
                <p>Tenant Logo </p>
                <div className='tenant-icon'>
                  <select className='tenant-icon-select' value={tenantLogo}
                    onChange={(e) => this.setState({ tenantLogo: e.target.value })}>
                    <option value="">Select a logo for the tenant</option>
                    {tenantLogos.map((a) => <option value={a.src}
                      key={a.src}>{a.name}</option>)}
                  </select>
                  {tenantLogo &&
                    <div className='image-uploader-preview'>
                      <img src={`./assets/tenants/${tenantLogo} `} />
                    </div>
                  }
                </div>
              </div>
              <div className='input-container'>  
                <p>Tenant Chart Threshold</p>
                <div className='threshold-input-wrapper'>
                  <label className="threshold-input-value">{ threshold }%</label>
                  <input
                    type="range"
                    min="1"
                    max="100"
                    onChange={(e) => this.setState({ threshold: e.target.value, formIsUpdated: true })}
                    style={this.getBackgroundSize()}
                    value={threshold}
                  />
                </div>
              </div>

              <div className='actions'>
                <Button
                  text="Save"
                  onClick={() => this.handleSave()}
                  enabled
                />
                <Button
                  text="Cancel"
                  className='cancel-btn'
                  onClick={() => this.cancel()}
                  enabled
                />
              </div>
            </Form> }
          </div>
        </>
      );
    }
}

export default connector(withRouter(EditTenant));