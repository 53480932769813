import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TenantsState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;
    showSuccsessMsg?: boolean;
    switchedTenant?:boolean

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    tenants: Tenant[];

    selectedTenant?: Tenant;
}

export interface Tenant {
    id: number;
    name: string;
    threshold: string;
    reportingWindow:string;
    tenantLogo: string;
    selected: boolean;
    dataId: string;
}

interface TenantData {
    id: number;
    name: string;
    threshold: string;
    tenantLogo: any;
    tenantDataId: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestTenantsAction {
    type: 'REQUEST_TENANTS';
}

interface ReceiveTenantsAction {
    type: 'RECEIVE_TENANTS';
    payload: any;
}

interface FailedTenantsAction {
    type: 'FAILED_TENANTS';
}

interface RequestTenantAction {
    type: 'REQUEST_TENANT';
}

interface ReceiveTenantAction {
    type: 'RECEIVE_TENANT';
    payload: any;
}

interface FailedTenantAction {
    type: 'FAILED_TENANT';
}

interface RequestSaveTenantAction {
    type: 'REQUEST_SAVE_TENANT';
}

interface ReceiveSaveTenantAction {
    type: 'RECEIVE_SAVE_TENANT';
    payload: any;
}

interface FailedSaveTenantAction {
    type: 'FAILED_SAVE_TENANT';
}

interface RequestSwitchTenantAction {
  type: 'REQUEST_SWITCH_TENANT';
}

interface ReceiveSwitchTenantAction {
  type: 'RECEIVE_SWITCH_TENANT';
  payload: any;
}

interface FailedSwitchTenantAction {
  type: 'FAILED_SWITCH_TENANT';
}

// Declare a 'discriminated union' type. This guarantees that
// all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestTenantsAction | ReceiveTenantsAction | FailedTenantsAction | RequestTenantAction
    | ReceiveTenantAction | FailedTenantAction | RequestSaveTenantAction | ReceiveSaveTenantAction
    | FailedSaveTenantAction | RequestSwitchTenantAction | ReceiveSwitchTenantAction | FailedSwitchTenantAction; 

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestTenants: ():
  AppThunkAction<any> => (dispatch, getState) => {
      
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_TENANTS',
        http: {
          verb: 'GET',
          endpoint: '/api/tenant/tenants',
          successAction: 'RECEIVE_TENANTS',
          failureAction: 'FAILED_TENANTS',
        },
      });
    }
  },

  requestTenant: (id:string):
        AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_TENANT',
        http: {
          verb: 'GET',
          endpoint: `/api/tenant/tenant/${id}`,
          successAction: 'RECEIVE_TENANT',
          failureAction: 'FAILED_TENANT',
        },
      });
    }
  },

  saveTenant: (data:TenantData):
        AppThunkAction<any> => (dispatch, getState) => {
            
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.tenants && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_TENANT',
        http: {
          verb: 'POST',
          endpoint: '/api/tenant/save/',
          successAction: 'RECEIVE_SAVE_TENANT',
          failureAction: 'FAILED_SAVE_TENANT',
          body: {
            ...data,
          },
        },
      });
    }
  },
    
  switchTenant: (tenantDataId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.reportingEntities && appState.tenants.isLoading === false) {
      dispatch({
        type: 'REQUEST_SWITCH_TENANT',
        http: {
          verb: 'PUT',
          endpoint: '/api/tenant/switch/',
          successAction: 'RECEIVE_SWITCH_TENANT',
          failureAction: 'FAILED_SWITCH_TENANT',
          body: { tenantDataId },
        },
      });
    }
  },
};



// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: TenantsState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  tenants: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<TenantsState> = (state: TenantsState |undefined, incomingAction: Action): TenantsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_TENANTS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      tenants: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_TENANTS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      tenants: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_TENANTS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      tenants: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'REQUEST_TENANT':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      selectedTenant: undefined,
    };
  case 'RECEIVE_TENANT':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      selectedTenant: action.payload,
    };
  case 'FAILED_TENANT':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      selectedTenant: undefined,
    };
  case 'REQUEST_SAVE_TENANT':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_TENANT':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_TENANT':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  case 'REQUEST_SWITCH_TENANT':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      tenants: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_SWITCH_TENANT':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
      switchedTenant : true,

      tenants: state.tenants,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize,
      totalRecords: state.totalRecords,
    };
  case 'FAILED_SWITCH_TENANT':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      tenants: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  default:
    return state;
  }  
};
