import ReactGA from 'react-ga';
import * as LeaderboardTypes from '../../../store/leaderboard/types';
import * as AuthTypes from '../../../store/authentication/types';
import ToolTips from '../../Common/ToolTips/toolTips';
import { useEffect, useState } from 'react';
import React from 'react';
import { WaveScore } from '../../Common/WaveScore/waveScore';

type IProps = {
    maxScore: number,
    onClick: Function,
    franchise:string,
    franchiseData: LeaderboardTypes.LeaderboardEntry[],
    numOfStores:number,
    averageTotalScore: number,
    averageAreaScores: { area: string; averageScore: number; }[],
    banding?:AuthTypes.Banding,
    tenantDetails?: AuthTypes.TenantDetails,
    scoringAreaLookup?: { [id: string]: AuthTypes.ScoreArea },
    selectedScoringArea?: string,
    scoringEntity?: AuthTypes.ScoringEntity,
}

const FranchiseEntry  = (props: IProps) => {
  const[averageRank, setAverageRank] = useState<number>(0);
  const[scoringAreaTooltipsLookup, setScoringAreaTooltipsLookup] = useState<{ [area: string]: boolean }>({});

  useEffect(() => {
    const { scoringAreaLookup, franchiseData } = props;

    const averageRank = franchiseData.reduce((total,score) => total + score.rank!, 0) / franchiseData.length;
    setAverageRank(parseFloat(averageRank.toFixed(1)));

    const scoringAreaTooltipsLookup: { [area: string]: boolean } = {};
    if(scoringAreaLookup) {
      Object.keys(scoringAreaLookup).forEach((scoringArea) => {
        scoringAreaTooltipsLookup[scoringArea] = false;
      });
      setScoringAreaTooltipsLookup(scoringAreaTooltipsLookup);
    }

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [props.franchiseData]);

  const getAreaScore = (scoringArea: string): string => {
    const areaScore = props.averageAreaScores?.find(x=>x.area == scoringArea);
    return areaScore?.averageScore.toFixed(1) || '0.0';
  };

  const toggleTooltip = (scoringArea: string, toggle: boolean) => {
    const newScoringAreaLookup = { ...scoringAreaTooltipsLookup };
    if(scoringAreaTooltipsLookup) {
      newScoringAreaLookup[scoringArea] = toggle;
    }
    setScoringAreaTooltipsLookup(newScoringAreaLookup);
  }; 

  return (
    <div className='leaderboard-wrapper'>
      <div className={'leaderboard-entry ui-toolkit'}
        onClick={() =>  props.onClick()}>
        <div className="metrics">
          <button className="leaderboard-details-info">
            <div className={'details'}>
              <div className="franchise-details">
                <div className="franchise-name">
                  {`[${props.numOfStores}] `}{props.franchise}
                </div>
              </div>
            </div>
          </button>
          <div className="areas">
            {props.scoringAreaLookup && Object.keys(props.scoringAreaLookup).map((scoringArea) => (
              <div
                onMouseOver={() => toggleTooltip(scoringArea, true)}
                onMouseOut={() => toggleTooltip(scoringArea, false)}
                className={'area-score'}
                key={props.scoringAreaLookup?.[scoringArea]?.dataId}>
                <img
                  className='area-image'
                  src={ `/assets/areas/${props.scoringAreaLookup?.[scoringArea]?.image}` }
                ></img>
                <ToolTips
                  text={props.scoringAreaLookup?.[scoringArea]?.name ?? ''}
                  tipToggled={Object.keys(scoringAreaTooltipsLookup).length > 0 ? scoringAreaTooltipsLookup[scoringArea] : false}
                  handleParentClick={() => null}
                  onInputChange={() => null}
                  hideButton={true}
                />
                <div>
                  {getAreaScore(scoringArea)}
                </div>
              </div>
            ))}
          </div>
          <div>
            <WaveScore maxScore={props.maxScore}
              score={props.averageTotalScore}
              index={averageRank + 10} />
          </div>
          <div>
            <button className="arrow-button">
              <div className="arrow-down" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FranchiseEntry;
