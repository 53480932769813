import { TrendFilter } from '../../enums/TrendFilter';
import { AppThunkAction } from '../index';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger
// a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestScoreArea: (
    reportingDate: string,
    reportingArea: string,
    reportingEntityId?: string,
  ):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreArea && appState.scoreArea.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCOREAREA',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/areaScore?reportingStartDate=${reportingDate}&reportingArea=${reportingArea}${reportingEntityId ? `&reportingEntityId=${reportingEntityId}` : ''}`,
          successAction: 'RECEIVE_SCOREAREA',
          failureAction: 'FAILED_SCOREAREA',
        },
      });
    }
  },
  requestScoreAreaByTenantId: (tenantId: string, areaId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreArea && appState.scoreArea.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCOREAREA',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/scoringAreaByTenantId?tenantId=${tenantId}&areaId=${areaId}`,
          successAction: 'RECEIVE_SCOREAREA',
          failureAction: 'FAILED_SCOREAREA',
        },
      });
    }
  },
  requestScoreAreasByTenantId: (tenantId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreArea && appState.scoreArea.isLoading === false) {
      dispatch({
        type: 'REQUEST_SCOREAREAS',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/ScoringAreasByTenantId?tenantId=${tenantId}`,
          successAction: 'RECEIVE_SCOREAREAS',
          failureAction: 'FAILED_SCOREAREAS',
        },
      });
    }
  },
  saveScoreArea: (data: any):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreArea && appState.scoreArea.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_SCOREAREA',
        http: {
          verb: 'POST',
          endpoint: '/api/scorecard/SaveScoringArea',
          successAction: 'RECEIVE_SAVE_SCOREAREA',
          failureAction: 'FAILED_SAVE_SCOREAREA',
          body: {
            ...data,
          },
        },
      });
    }
  },

  requestAreaMetricTrend: (reportingEntityID: string, areaId: string, reportingStartDate: string, trendFilter: TrendFilter):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.scoreArea && appState.scoreArea.isLoading === false) {
      dispatch({
        type: 'REQUEST_AREA_METRIC_TRENDS',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/areaMetricTrends?reportingEntityID=${reportingEntityID}&areaId=${areaId}&reportingStartDate=${reportingStartDate}&trendFilter=${trendFilter}`,
          successAction: 'RECEIVE_AREA_METRIC_TRENDS',
          failureAction: 'FAILED_AREA_METRIC_TRENDS',
        },
      });
    }
  },

};