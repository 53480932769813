import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { TrendFilter } from '../../../enums/TrendFilter';

export interface MetricDataPoint  {
  reportingWindowStart: string;
  value: number;
}

interface ChildProps {
  onClose: () => void;
  metricData: MetricDataPoint[];
  reportingStartDate: string;
}

const MetricsChart = (props:ChildProps) => {
  
  const [timePeriod, setTimePeriod] = useState<TrendFilter|null>(null);

  const handleClearOption = () => {
    props.onClose();
  };
  
  let formattedData =props.metricData; 

  formattedData = formattedData.sort((a, b) => moment(a.reportingWindowStart).valueOf() - moment(b.reportingWindowStart).valueOf());


  let filterPeriod: TrendFilter = timePeriod as TrendFilter;
  filterPeriod = filterPeriod ?? 3;

  formattedData = formattedData.filter((d) => moment(d.reportingWindowStart).isSameOrAfter(moment(props.reportingStartDate).subtract(filterPeriod, 'month'))
  && moment(d.reportingWindowStart).isSameOrBefore(moment(props.reportingStartDate)),
  );

  formattedData = formattedData.map((d) => { 
    return { 
      ...d, 
      reportingWindowStart: moment(d.reportingWindowStart).format('ddd Do MMM YYYY'),
    };
  });


  return (
    <div className='main-container'>
      <div className='container-style select-wrapper'>
        <select 
          value={timePeriod?.toString()}  // Convert the enum value to string
          onChange={(event) => setTimePeriod(parseInt(event.target.value))}>
          <option value=''
            disabled={timePeriod !== null}>
          Quick Filters
          </option>
          <option value={TrendFilter.ONE_MONTH.toString()}>1 Month</option>
          <option value={TrendFilter.THREE_MONTH.toString()}>3 Months</option>
          <option value={TrendFilter.SIX_MONTH.toString()}>6 Months</option>
          <option value={TrendFilter.ONE_YEAR.toString()}>1 Year</option>
        </select>
        <span onClick={handleClearOption}
          className='cross-style'>
          X
        </span>
      </div>
      <div className='graph-container'>
        {formattedData?.length > 0 ? (
          <ResponsiveContainer width="99%"
            height={375}
          >

            <LineChart
              margin={{ top: 12, right: 35, left: 10, bottom: 5 }}
              data={formattedData}
              className='graph'
            >
              <XAxis dataKey='reportingWindowStart' />
              <YAxis />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip formatter={(value) => [value]} />
              <Line
                dataKey='value'
                type='monotone'
                stroke='#28B440'
                name='WasteValue'
                dot={{ stroke: '#28B440', strokeWidth: 7 }}
                activeDot={{ r: 10 }}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <h3 className='centered-message'>No data available for the selected time period!</h3>
        )}
      </div>

    </div>
  );
};

export default MetricsChart;
