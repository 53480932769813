import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  withRouter, RouteComponentProps, Switch, Route, useHistory,
} from 'react-router';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as AuthenticationTypes from '../../store/authentication/types';
import ScoringEntities from './ScoringEntities';
import Items from './Items';
import Companies from './Companies';
import AddCompany from './AddCompany';
import Users from './Users';
import EditUser from './EditUser';
import ItemCategories from './ItemCategories';
import EditItemCategories from './EditItemCategory';
import Grouping from './Grouping';
import EditGroup from './EditGroup';
import ScoreWeightings from './ScoreWeightings';
import EditRolePermissions from './EditRolePermissions';
import ReactGA from 'react-ga';
import { DefaultConfigPage } from './DefaultConfigPage';
import { useEffect } from 'react';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});
const mapDispatch = {
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

const Configuration = (props: IProps) => {
  const permissions = (props.authentication && props.authentication.permissions) || [];
  const allPermissions = AuthenticationTypes.Permissions;
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <div className="configuration ui-toolkit">
      <Page mode="center configuration"
        pageNumber={1}
        padding>
        <div className="config-wrapper">
          <div className="menu">

            {permissions.indexOf(allPermissions.EditItemCategories) > -1
            && (
              <button
                type="button"
                onClick={() => history.push('/configuration/categories')}
              >
              Configure Categories
                <div className="chevron" />
              </button>
            )}

            {permissions.indexOf(allPermissions.EditItemCategorisation) > -1
            && (
              <button
                type="button"
                onClick={() => history.push('/configuration/items')}
              >
              Configure Items
                <div className="chevron" />
              </button>
            )}
            {permissions.indexOf(allPermissions.EditCompanies) > -1
            && (
              <button
                type="button"
                onClick={() => history.push('/configuration/companies')}
              >
              Configure Companies
                <div className="chevron" />
              </button>
            )}

            {permissions.indexOf(allPermissions.EditStoreBandings) > -1
            && (
              <>
                <button
                  type="button"
                  onClick={() => history.push('/configuration/grouping')}
                >
                Configure Scoring Groups
                  <div className="chevron" />
                </button>
                <button
                  type="button"
                  onClick={() => history.push('/configuration/scoringEntities')}
                >
                Configure Scoring Entities
                  <div className="chevron" />
                </button>
                <button
                  type="button"
                  onClick={() => history.push('/configuration/scoreWeightings')}
                >
                Configure Score Weightings
                  <div className="chevron" />
                </button>
              </>
            )}

            {permissions.indexOf(allPermissions.EditUserPermissions) > -1
            && permissions.indexOf(allPermissions.EditStoreAccess) > -1
            && permissions.indexOf(allPermissions.EditAreaAccess) > -1
            && (
              <button
                type="button"
                onClick={() => history.push('/configuration/users')}
              >
              Configure Users
                <div className="chevron" />
              </button>
            )}
            {permissions.indexOf(allPermissions.EditUserPermissions) > -1
             && (
               <button
                 type="button"
                 onClick={() => history.push('/configuration/rolePermissions')}
               >
              Configure Role Permissions
                 <div className="chevron" />
               </button>
             )}
          </div>
          <div className="config-content">
            <Switch>
              <Route exact
                path="/configuration/"
                component={DefaultConfigPage} />

              {permissions.indexOf(allPermissions.EditItemCategories) > -1
                        && (<Route exact
                          path="/configuration/categories"
                          component={ItemCategories} />)}

              {permissions.indexOf(allPermissions.EditItemCategories) > -1
                        && (<Route exact
                          path="/configuration/categories/:categoryid"
                          component={EditItemCategories} />)}

              {permissions.indexOf(allPermissions.EditItemCategorisation) > -1
                        && <Route exact
                          path="/configuration/items"
                          component={Items} />}

              {permissions.indexOf(allPermissions.EditCompanies) > -1
                    && <Route exact
                      path="/configuration/companies"
                      component={Companies} />}

              {permissions.indexOf(allPermissions.EditCompanies) > -1
                    && <Route exact
                      path="/configuration/companies/:companiesid"
                      component={AddCompany} />}

              {permissions.indexOf(allPermissions.EditStoreBandings) > -1
                        && (<Route exact
                          path="/configuration/grouping"
                          component={Grouping} />)}

              {permissions.indexOf(allPermissions.EditUserPermissions) > -1
                        && (<Route exact
                          path="/configuration/rolePermissions"
                          component={EditRolePermissions} />)}

              {permissions.indexOf(allPermissions.EditStoreBandings) > -1
                        && (<Route exact
                          path="/configuration/grouping/:groupid"
                          component={EditGroup} />)}

              {permissions.indexOf(allPermissions.EditStoreBandings) > -1
                        && (<Route exact
                          path="/configuration/scoringEntities"
                          component={ScoringEntities} />)}
                
              {permissions.indexOf(allPermissions.EditScoreWeightings) > -1
                        && (<Route exact
                          path="/configuration/scoreWeightings"
                          component={ScoreWeightings} />)}

              {(permissions.indexOf(allPermissions.EditUserPermissions) > -1
          || permissions.indexOf(allPermissions.EditStoreAccess) > -1
          || permissions.indexOf(allPermissions.EditAreaAccess) > -1)
                        && (<Route exact
                          path="/configuration/users"
                          component={Users} />)}

              {(permissions.indexOf(allPermissions.EditUserPermissions) > -1
          || permissions.indexOf(allPermissions.EditStoreAccess) > -1
          || permissions.indexOf(allPermissions.EditAreaAccess) > -1)
                        && (<Route exact
                          path="/configuration/users/:userid"
                          component={EditUser} />)}
            </Switch>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default connector(withRouter(Configuration));
