import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReportingEntitiesState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    reportingEntities: ReportingEntities[];
    availableBandings: Banding[];
    availableOwners: Owner[];
    availableCompanies: Company[];
}

export interface ReportingEntities {
    bandingID: number;
    bandingName: string;
    ownerName:string;
    description: string;
    id: number;
    label: string;
    companyName: string;
}

export interface Banding {
    id: number;
    name: string;
    competingLabel: string;
    default: number;
}

export interface Owner {
    id: number;
    name: string;
}

export interface Company {
    id: number;
    name: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestReportingEntitiesAction {
    type: 'REQUEST_REPORTINGENTITIES';
}

interface ReceiveReportingEntitiesAction {
    type: 'RECEIVE_REPORTINGENTITIES';
    payload: any;
}

interface FailedReportingEntitiesAction {
    type: 'FAILED_REPORTINGENTITIES';
}

interface RequestSetReportingEntitiesBandingAction {
    type: 'REQUEST_SETREPORTINGENTITIESBANDING';
}

interface ReceiveSetReportingEntitiesBandingAction {
    type: 'RECEIVE_SETREPORTINGENTITIESBANDING';
}

interface FailedSetReportingEntitiesBandingAction {
    type: 'FAILED_SETREPORTINGENTITIESBANDING';
}

interface RequestSetReportingEntitiesOwnerAction {
    type: 'REQUEST_SETREPORTINGENTITIESOWNER';
}

interface ReceiveSetReportingEntitiesOwnerAction {
    type: 'RECEIVE_SETREPORTINGENTITIESOWNER';
}

interface FailedSetReportingEntitiesOwnerAction {
    type: 'FAILED_SETREPORTINGENTITIESOWNER';
}

interface RequestSetReportingEntitiesCompanyAction {
    type: 'REQUEST_SETREPORTINGENTITIESCOMPANY';
}

interface ReceiveSetReportingEntitiesCompanyAction {
    type: 'RECEIVE_SETREPORTINGENTITIESCOMPANY';
}

interface FailedSetReportingEntitiesCompanyAction {
    type: 'FAILED_SETREPORTINGENTITIESCOMPANY';
}

// Declare a 'discriminated union' type. This guarantees that
// all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestReportingEntitiesAction | ReceiveReportingEntitiesAction |
    FailedReportingEntitiesAction |
    RequestSetReportingEntitiesBandingAction | ReceiveSetReportingEntitiesBandingAction |
    FailedSetReportingEntitiesBandingAction |
    RequestSetReportingEntitiesOwnerAction | ReceiveSetReportingEntitiesOwnerAction |
    FailedSetReportingEntitiesOwnerAction | ReceiveSetReportingEntitiesCompanyAction |
    RequestSetReportingEntitiesCompanyAction | FailedSetReportingEntitiesCompanyAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestReportingEntities: (filter: string, page: number, pageSize: number):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_REPORTINGENTITIES',
        http: {
          verb: 'GET',
          endpoint: `/api/ScoringEntityConfiguration/ReportingEntities?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_REPORTINGENTITIES',
          failureAction: 'FAILED_REPORTINGENTITIES',
        },
      });
    }
  },
  RequestSetReportingEntitiesBanding: (bandingId: string, reportingEntityIds: number[]):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SETREPORTINGENTITIESBANDING',
        http: {
          verb: 'POST',
          endpoint: '/api/ScoringEntityConfiguration/SetReportingEntityBanding',
          successAction: 'RECEIVE_SETREPORTINGENTITIESBANDING',
          failureAction: 'FAILED_SETREPORTINGENTITIESBANDING',
          body: {
            bandingId: parseInt(bandingId, 10),
            reportingEntityIds,
          },
        },
      });
    }
  },
  RequestSetReportingEntitiesOwner: (userId: string, reportingEntityIds: number[]):
  AppThunkAction<any> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
      dispatch({
        type: 'REQUEST_SETREPORTINGENTITIESOWNER',
        http: {
          verb: 'POST',
          endpoint: '/api/ScoringEntityConfiguration/SetReportingEntityOwner',
          successAction: 'RECEIVE_SETREPORTINGENTITIESOWNER',
          failureAction: 'FAILED_SETREPORTINGENTITIESOWNER',
          body: {
            userId: parseInt(userId, 10),
            reportingEntityIds,
          },
        },
      });
    }
  },
  RequestSetReportingEntitiesCompany: (companyId: string, reportingEntityIds: number[]):
    AppThunkAction<any> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.reportingEntities && appState.reportingEntities.isLoading === false) {
            dispatch({
                type: 'SETREPORTINGENTITIESCOMPANY',
                http: {
                    verb: 'POST',
                    endpoint: '/api/ScoringEntityConfiguration/SetReportingEntityCompany',
                    successAction: 'RECEIVE_SETREPORTINGENTITIESCOMPANY',
                    failureAction: 'FAILED_SETREPORTINGENTITIESCOMPANY',
                    body: {
                        companyId: parseInt(companyId, 10),
                        reportingEntityIds,
                    },
                },
            });
        }
    },

};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: ReportingEntitiesState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  reportingEntities: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
  availableBandings: [],
  availableOwners: [],
  availableCompanies: []
};

export const reducer: Reducer<ReportingEntitiesState> = (state: ReportingEntitiesState |
    undefined, incomingAction: Action): ReportingEntitiesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_REPORTINGENTITIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      reportingEntities: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      availableBandings: [],
      availableOwners: [],
      availableCompanies: []
    };
  case 'RECEIVE_REPORTINGENTITIES':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      reportingEntities: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,

      availableBandings: action.payload.availableBandings || [],
      availableOwners: action.payload.availableOwners || [],
      availableCompanies: action.payload.availableCompanies || [],
    };
  case 'FAILED_REPORTINGENTITIES':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      reportingEntities: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
      availableBandings: [],
      availableOwners: [],
      availableCompanies: []
    };
  case 'REQUEST_SETREPORTINGENTITIESBANDING':
  case 'REQUEST_SETREPORTINGENTITIESOWNER':
  case 'REQUEST_SETREPORTINGENTITIESCOMPANY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SETREPORTINGENTITIESBANDING':
  case 'RECEIVE_SETREPORTINGENTITIESOWNER':
  case 'RECEIVE_SETREPORTINGENTITIESCOMPANY':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SETREPORTINGENTITIESBANDING':
  case 'FAILED_SETREPORTINGENTITIESOWNER':
  case 'FAILED_SETREPORTINGENTITIESCOMPANY':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
};
