import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import ConfirmationModal from '../../uiToolkit/components/confirmationModal';
import { ApplicationState } from '../../store';
import * as UserStore from '../../store/configuration/Users';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';
import { toast } from 'react-toastify';


const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  users: state.users,
});
const mapDispatch = {
  ...UserStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { userid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  [selector: string]:number[] | number | string | boolean,
  id: number;
  name: string;
  phoneNumber: string;
  emailAddress: string;

  selectedAreaIds: number[],
  selectedRoleId: number,
  selectedScoreIds: number[],

  confirmationModal:boolean;
  formIsSubmitted: boolean;
}

class EditUser extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: 0,
      name: '',
      phoneNumber: '',
      emailAddress: '',
      selectedAreaIds: [],
      selectedRoleId: 0,
      selectedScoreIds: [],

      confirmationModal: false,
      formIsSubmitted: false,
    };
  }

  public componentDidMount() {
       
    const {
      match: {
        params: {
          userid,
        },
      },
      requestUser,
    } = this.props;

    if (userid !== '0') {
      requestUser(userid);

    }
    
    ReactGA.pageview(window.location.pathname + window.location.search);     
  }

  public componentDidUpdate(prevProps: IProps) {
    const { users: { selectedUser, isLoading, isUpdated, isErrored }, history } = this.props;

    // TODO redirect new to edit

    if (selectedUser !== prevProps.users.selectedUser && !isLoading) {
      if (selectedUser !== undefined) {
        this.setState({
          id: selectedUser.id,
          name: selectedUser.name || '',
          phoneNumber: selectedUser.phoneNumber || '',
          emailAddress: selectedUser.emailAddress || '',

          selectedAreaIds: selectedUser.areaIds,
          selectedRoleId: selectedUser.roleId,
          selectedScoreIds: selectedUser.scoreIds,
        });
      }
    }

    if (isUpdated && !prevProps.users.isUpdated) {
      toast.info('Successfully saved user');
      history.goBack();
    } else if(!isErrored && prevProps.users.isErrored) {
      toast.error('There was an error trying to save user', {
        className: 'toast-popup error',
      });
    }
  }

  public handleCheckInputUpdate(value: boolean, id: number, fieldName: string) {
        
    const { [fieldName]: list } = this.state;
    const ids :number[] = list as number[];

    if (value) { // add
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else { // remove
      const idx = ids.indexOf(id);

      if (idx > -1) {
        ids.splice(idx, 1);
      }
    }

    this.setState({ [fieldName]: ids });
  }

  public handleRadioInputUpdate(value: boolean, id: number, fieldName: string) {
        
    const { [fieldName]: list } = this.state;
    let selectedRoleId: number = list as number;

    if (value) { // add
      selectedRoleId = id;
      this.setState({ [fieldName]: selectedRoleId });
    }
  }
   

  public handleSave() {
    const { saveUser } = this.props;
    const {
      id,
      name,
      phoneNumber,
      emailAddress,
      selectedAreaIds,
      selectedRoleId,
      selectedScoreIds,
    } = this.state;

    if (this.validateFields()) {
      saveUser({
        id, 
        name, 
        phoneNumber,  
        emailAddress, 
        selectedAreaIds, 
        selectedRoleId,
        selectedScoreIds,
      });
    }
  }

  onSaveButtonClick() {
    this.setState({ formIsSubmitted: true });
    if(this.validateFields()) {
      this.setState({ confirmationModal: true });
    }    
  }

  public validateFields() {
    const {
      id,
      name,
      phoneNumber,
      emailAddress,
    } = this.state;

    return id && name && phoneNumber && emailAddress;
  }

  public render() {
    const {
      name,
      phoneNumber,
      emailAddress,
      formIsSubmitted,
      confirmationModal,
      selectedAreaIds,
      selectedRoleId,
      selectedScoreIds,
    } = this.state;

    const {
      users: {
        isLoading,
        availableRoles,
        availableScoringArea,
        availableScoringEntities,
      },
    } = this.props;

    return (
      <Page mode="edit-user"
        pageNumber={1}>
        {isLoading && <Loading/> }
        { !isLoading && 
        <>
          <Form title="Edit User">
            <Input
              maxLength= {256}
              name="name"
              label="Name"
              placeholderText="Name"
              inputType="text"
              onChange={(value: string) => this.setState({ name: value })}
              value={name}
              valid={formIsSubmitted && name.length === 0}
            />
            <Input
              maxLength= {15}
              name="phoneNumber"
              label="Phone Number"
              placeholderText="Phone Number"
              inputType="tel"
              onChange={(value: string) => this.setState({ phoneNumber: value })}
              value={phoneNumber}
              valid={formIsSubmitted && phoneNumber.length === 0}
            />
            <Input
              maxLength= {256}
              name="emailAddress"
              label="Email Address"
              placeholderText="Email Address"
              inputType="email"
              onChange={(value: string) => this.setState({ emailAddress: value })}
              value={emailAddress}
              valid={formIsSubmitted && emailAddress.length === 0}
            />
          </Form>
           
          <div className="permissionLists">
            <div className="permissionList">

              <h3>User Roles</h3>
              <div className="permissions">

                {availableRoles.map((p) => (
                  <label
                    key={p.id}
                  >
                    <input   
                      type="radio"
                      name="options"
                      checked={selectedRoleId===p.id }
                      value={p.id}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleRadioInputUpdate(e.target.checked, p.id, 'selectedRoleId')}
                    />
                    {p.name}
                  </label>
                ))}
              </div>
            </div>
            <div className="permissionList">
              <h3>Scoring Entities</h3>
              <div className="permissions">

                {availableScoringEntities.map((p) => (
                  <label
                    key={p.id}
                  >
                    <input
                      type="checkbox"
                      checked={selectedScoreIds && selectedScoreIds.indexOf(p.id) > -1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCheckInputUpdate(e.target.checked, p.id, 'selectedScoreIds')}
                    />
                    {p.description}
                  </label>
                ))}
              </div>
            </div>
            <div className="permissionList">
              <h3>Scoring Areas</h3>
              <div className="permissions">

                {availableScoringArea.map((p) => (
                  <label
                    key={p.id}
                  >
                    <input
                      type="checkbox"
                      checked={selectedAreaIds && selectedAreaIds.indexOf(p.id) > -1}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleCheckInputUpdate(e.target.checked, p.id, 'selectedAreaIds')}
                    />
                    {p.name}
                  </label>
                ))}
              </div>
            </div>
          </div> 
          <div className="button-holder">
            <Button
              text="Save"
              onClick={() => this.onSaveButtonClick()}
              enabled
            />
          </div>
        </> }
        {confirmationModal
        && (
          <ConfirmationModal
            continue={() => this.handleSave()}
            cancel={() => this.setState({ confirmationModal: false })}
            inProgress={isLoading}
          />
        )}
      </Page>
    );
  }
}

export default connector(withRouter(EditUser));
