import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';

interface IProps {
  score: number;
  maxScore: number;
  index: number;
}

export const WaveScore = (props: IProps) => {
  const [radius] = useState(25);
  const [centerX] = useState(25);
  const [centerY] = useState(0);
  const [angle, setAngle] = useState(0);

  const { score, maxScore, index } = props;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setAngle((((props.maxScore - props.score) / props.maxScore)) * (Math.PI));
  }, [props.score]);

  return (
    <div className="wave-score  ui-toolkit">
      <div className="score-values">
        <div className="current-score">{score.toFixed(1)}</div>
        <div className="total-score">
            /
          {maxScore}
        </div>
      </div>

      {score > 0 && (
        <svg viewBox="0,0 50,26">
          <mask id={`myMask${index}`}>
            <path
              id={`pieBig${index}`}
              fill="white"
              d={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(Math.PI))},${centerY + (radius * Math.sin(Math.PI))} z`}
            />
          </mask>

          <image
            className="score-image"
            href="/assets/logo-shape-gray.png"
          />
            
          <image
            className="score-image"
            href="/assets/logo-shape-beach.png"
            mask={`url(#myMask${index})`}
          />

          <animate
            href={`#pieBig${index}`}
            xlinkHref={`#pieBig${index}`}
            attributeName="d"
            dur="1s"
            from={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(Math.PI))},${centerY + (radius * Math.sin(Math.PI))} z`}
            to={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(angle))},${centerY + (radius * Math.sin(angle))} z`}
            fill="freeze"
          />

        </svg>
      )}
    </div>
  );
};
