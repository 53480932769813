import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import SplashHero from './SplashHero/splashHero';
import Form from '../../uiToolkit/containers/form';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as AuthActions from '../../store/authentication/actions';
import ReactGA from 'react-ga';
import Loading from '../Common/Loading/loading';
import { toast } from 'react-toastify';
import { SignInButton } from './SignInButton';

const mapState = (state: ApplicationState) => ({
  isAuthenticated: state.authentication.isAuthenticated, 
  isLoading: state.authentication.isLoading, 
  isErrored : state.authentication.isErrored, 
  errorMessage: state.authentication.errorMessage,
});

const mapDispatch = { 
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux;

interface IState {
  username: string, 
  password: string, 
  recaptcha: string
  submittedNoRecaptcha: boolean,
}


class Login extends React.PureComponent<IProps, IState> {
    public recaptchaRef: any = React.createRef();

    constructor(props: IProps) {
      super(props);

      this.state = {
        username: '',
        password: '',
        recaptcha: '',
        submittedNoRecaptcha: false,
      };
    }

    public componentDidMount(){
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    public componentDidUpdate(prevProps: IProps){
      const { isErrored } = this.props;
      if(isErrored && !prevProps.isErrored) {
        
        toast.error(this.state.submittedNoRecaptcha ? 'Recaptcha empty.' : 'An error has occurred', { className: 'toast-popup error' });
      }
    }

    public render() {
      const {
        isLoading,
      } = this.props;
      return (
        <Page pageNumber={1}
          mode="login">
          <SplashHero />
          <Form title={!isLoading ? 'Sign In - Orderly Scorecards' : ''}>
            { isLoading && <Loading/> }
            { !isLoading &&
              <SignInButton />
            }
          </Form>
        </Page>
      );
    }
}


export default connector(Login);

