import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FaqState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    frequentQuestions: Faq[];
}

export interface Faq {
    id: number,
    question: string,
    answer: string
}



// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is
// going to happen.

interface RequestFaqsAction {
    type: 'REQUEST_FAQS';
}

interface ReceiveFaqsAction {
    type: 'RECEIVE_FAQS';
    payload: any;
}

interface FailedFaqsAction {
    type: 'FAILED_FAQS';
}



// Declare a 'discriminated union' type. This guarantees that
// all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestFaqsAction | ReceiveFaqsAction | FailedFaqsAction ;
// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
    requestFaqs: ():
        AppThunkAction<any> => (dispatch, getState) => {

            // Only load data if it's something we don't already have (and are not already loading)
            const appState = getState();
            if (appState && appState.faqs && appState.faqs.isLoading === false) {
                dispatch({
                    type: 'REQUEST_FAQS',
                    http: {
                        verb: 'GET',
                        endpoint: `/api/help/GetFaqContent`,
                        successAction: 'RECEIVE_FAQS',
                        failureAction: 'FAILED_FAQS',
                    },
                });
            }
        },
};



// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: FaqState = {
    errorMessage: '',
    isErrored: false,
    isLoading: false,
    isUpdated: false,

    frequentQuestions: [],
};

export const reducer: Reducer<FaqState> = (state: FaqState | undefined, incomingAction: Action): FaqState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FAQS':
            return {
                errorMessage: '',
                isErrored: false,
                isLoading: true,
                isUpdated: false,

                frequentQuestions: [],
            };
        case 'RECEIVE_FAQS':
            return {
                errorMessage: '',
                isErrored: false,
                isLoading: false,
                isUpdated: false,
                frequentQuestions: action.payload.data,
            };
        case 'FAILED_FAQS':
            return {
                errorMessage: 'An error occurred.',
                isErrored: true,
                isLoading: false,
                isUpdated: false,

                frequentQuestions: [],
            };
        default:
            return state;
    }
};

