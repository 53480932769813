import Button from '../../uiToolkit/components/button';
import React from 'react';
import { AuthManager } from '../../AuthManager';

export const SignInButton = () => {
  const authManager = new AuthManager();

  return (
    <Button
      text='Sign In using my organization account'
      enabled={true}
      onClick={() => authManager.login()}>
    </Button>
  );
};

export default SignInButton;