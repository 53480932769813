import { Action, Reducer } from 'redux';
import { Product, ProductAction } from './types';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ProductState {
    isLoading: boolean;
    isErrored: boolean;
    errorMessage: string;
    product?: Product;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const unloadedState: ProductState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  product: undefined,
};

export const reducer: Reducer<ProductState> = (state: ProductState | undefined,
  incomingAction: Action): ProductState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as ProductAction;
  switch (action.type) {
  case 'REQUEST_PRODUCT':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      product: undefined,
    };
  case 'RECEIVE_PRODUCT':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      product: action.payload.product,
    };
  case 'FAILED_PRODUCT':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      product: undefined,
    };
  default:
    return state;
  }
};
