/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps, useHistory } from 'react-router';
import * as qs from 'qs';
import LeaderboardEntry from './LeaderboardEntry/leaderboardEntry';
import Page from '../../uiToolkit/containers/page';
import { ApplicationState } from '../../store';
import * as LeaderboardActions from '../../store/leaderboard/actions';
import * as LeaderboardTypes from '../../store/leaderboard/types';
import * as AuthActions from '../../store/authentication/actions';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import ReactGA from 'react-ga';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import Loading from '../Common/Loading/loading';
import TreeView from 'react-treeview';
import FranchiseEntry from './FranchiseEntry/franchiseEntry';
import PermissionHelper from '../../uiToolkit/helpers/PermissionHelper';
import { useState, useEffect, useRef } from 'react';
import { Podium } from './Podium/podium';
import { Message } from '../Common/Message/message';
import { ScoreAreaType } from '../../store/authentication/types';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  leaderboard: state.leaderboard,
});

const mapDispatch = {
  ...LeaderboardActions.actionCreators,
  ...AuthActions.actionCreators,
};

interface PodiumData {
  first: LeaderboardTypes.LeaderboardEntry | undefined,
  second: LeaderboardTypes.LeaderboardEntry | undefined,
  third: LeaderboardTypes.LeaderboardEntry | undefined,
}

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;


const Leaderboard = (props: IProps) => {
  const permHelper = new PermissionHelper(props.authentication.permissions);
  const query = qs.parse(props.history.location.search.replace('?', '').toLowerCase());
  const queryDateKey = query.datekey && query.datekey.toString() || '';
  
  const [dateKey, setDateKey] = useState<string>('');
  const [bandingId, setBandingId] = useState<string>('');
  const [area, setArea] = useState<string>('');
  const [selectedGroupByOption, setSelectedGroupByOption] = useState<string>(permHelper.HasAccessToMultipleStores() ? 'None' : 'Franchise');
  const [collapsedFranchiseList, setCollapsedFranchiseList] = useState<string[]>([]);
  const [isCollapsedAll, setIsCollapsedAll] = useState<boolean>(false);

  const [podiumData, setPodiumData] = useState<PodiumData>({
    first: undefined,
    second: undefined,
    third: undefined,
  });

  const [loading, setLoading] = useState(false);

  const tenantDetails = props.authentication && props.authentication.tenantDetails;
  const bandings = (props.authentication && props.authentication.bandings) || [];
  const areas = (props.authentication && props.authentication.scoreAreas) || [];
  const bandingLookup = props.authentication && props.authentication.bandingLookup;
  const scoringEntityLookup = props.authentication && props.authentication.scoringEntityLookup;
  const leaderboard = props.leaderboard;

  const [leaderboardEntries, setLeaderboardEntries] = useState<LeaderboardTypes.LeaderboardEntry[]>(
    leaderboard.leaderboard?.entries || []);

  const history = useHistory();
  const { requestLeaderboard, setCurrentDate } = props;
  
  const filteredAreasLookup = Object.fromEntries(
    Object.entries(props.authentication?.scoreAreaLookup!)
      .filter(([id, scoreArea]) => scoreArea.type === ScoreAreaType.store));

  const groupByOptions = [
    { label: 'None', value: 'None' },
    { label: 'Franchise', value: 'Franchise' },
  ];
  
  const handleGroupByOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setLoading(true);
    setTimeout(() => {
      setSelectedGroupByOption(event.target.value);
      setLoading(false);
    }, 1000);
  };

  const handleBandingIdChange = (selectedBandingId: string) => {
    setLoading(selectedGroupByOption === 'Franchise' && selectedBandingId === '');
    setBandingId(selectedBandingId);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleCollapseAllClick = () => {
    setIsCollapsedAll(!isCollapsedAll);

    if (isCollapsedAll) {
      setCollapsedFranchiseList([]);
    } else {
      const franchises = props.leaderboard.leaderboard?.entries ?
        Array.from(new Set(props.leaderboard.leaderboard?.entries.map((result) => result.franchise === null ? 'null' : result.franchise))) : [];
      setCollapsedFranchiseList(franchises);
    }
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let dateKey: string = queryDateKey;

    // Manage the date state
    if (queryDateKey.length > 0) {
      setCurrentDate(queryDateKey);
      setDateKey(queryDateKey);
    } else {
      const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
      dateKey = latestValidDate;
      setDateKey(latestValidDate);
      setCurrentDate(latestValidDate);
    }

    requestLeaderboard(dateKey);
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (dateKey) {
      history.replace(`/leaderboard?datekey=${dateKey}`);
      requestLeaderboard(dateKey);
    }

  }, [dateKey]);

  
  useEffect(() => {
    if (leaderboard.leaderboard?.entries) {
      const leaderboardData = generateLeaderboardContent(leaderboard.leaderboard?.entries) || [];
      setLeaderboardEntries(leaderboardData);
      setPodiumData({
        first: leaderboardData.find(l => l.rank === 1),
        second: leaderboardData.find(l => l.rank === 2),
        third: leaderboardData.find(l => l.rank === 3),
      });
    } else {
      setLeaderboardEntries([]);
      setPodiumData({
        first: undefined,
        second: undefined,
        third: undefined,
      });
    }

  }, [leaderboard.leaderboard]);

  const dateChanged = (dateKey: string) => {
    const { setCurrentDate } = props;
    setCurrentDate(dateKey);
    setDateKey(dateKey);
  };

  const viewStore = (scoreId: string, dateKey: string) => {
    const { setCurrentReportingEntity, history } = props;
    setCurrentReportingEntity(scoreId);
    history.push(`/score/${scoreId}?datekey=${dateKey}`);
  };

  const toggleCollapsedFranchise = (franchise: string) => {
    setCollapsedFranchiseList((prevCollapsedFranchiseList) => {
      const collapsed = new Set(prevCollapsedFranchiseList);
      if (!collapsed.has(franchise)) {
        collapsed.add(franchise);
      } else {
        collapsed.delete(franchise);
      }
      return Array.from(collapsed);
    });
  };

  const generateLeaderboardContent = (data: LeaderboardTypes.LeaderboardEntry[]) => {
    let returnData = data;

    //Filter
    if (bandingId === 'mine') {
      if (scoringEntityLookup) {
        returnData = returnData.filter(r => scoringEntityLookup[r.siteId]);
      }
    } else if (bandingId) {
      if (scoringEntityLookup) {
        returnData = returnData.filter(r =>
          scoringEntityLookup[r.siteId] &&
          scoringEntityLookup[r.siteId].bandingId.toString() !== bandingId,
        );
      }
    }

    //Sort
    if (!area) {
      returnData = returnData.sort((a, b) =>
        b.totalScore - a.totalScore,
      );
    } else {
      returnData = returnData.sort((a, b) => {
        const a2 = a.scores.find(z => z.area === area);
        const b2 = b.scores.find(y => y.area === area);

        if (!a2 || !b2) {
          return 0;
        }

        return b2.rawScore - a2.rawScore;
      });
    }

    //Set rank
    returnData = returnData.map((e, idx) => { return { ...e, rank: idx + 1 }; });

    return returnData;
  };

  const getScoringEntityName = (entry?: LeaderboardTypes.LeaderboardEntry) => {
    if (scoringEntityLookup && entry) {
      if (entry.siteId === '?') {
        return '?';
      }
      return scoringEntityLookup[entry.siteId].name;
    }
    return '';
  };

  const renderPodium = () => {
    if (scoringEntityLookup && podiumData) {

      const firstPlace = podiumData.first;
      const secondPlace = podiumData.second;
      const thirdPlace = podiumData.third;

      return <Podium
        companyLogo={(tenantDetails && tenantDetails.logo) || ''}
        first={getScoringEntityName(firstPlace)}
        firstScore={firstPlace?.totalScore || 0}
        second={getScoringEntityName(secondPlace)}
        secondScore={secondPlace?.totalScore || 0}
        third={getScoringEntityName(thirdPlace)}
        thirdScore={secondPlace?.totalScore || 0}
      />;
    }
  };

  const removeFadedEntries = (data: LeaderboardTypes.LeaderboardEntry[]) => {
    let current = false;
    let newData = data.reverse();
    newData = newData.filter(e => {
      if (e.siteId !== '?') {
        current = true;
        return true;
      }
      if (current) {
        current = false;
        return true;
      }
      return false;
    });

    return newData.reverse();
  };

  const renderContent = (data: LeaderboardTypes.LeaderboardEntry[]) => {
    const banding = bandingLookup && bandingLookup[bandingId];
    //Second filter to remove most faded entries
    const newData = removeFadedEntries(data);

    return newData.map((l, idx) => {
      const scoringEntity = scoringEntityLookup && scoringEntityLookup[l.siteId];
      return (
        <LeaderboardEntry
          key={l.siteId + idx}
          rank={l.rank || (idx + 1)}
          firstRow={idx === 0 ? true : false}
          maxScore={5}
          entry={l}
          prevEntry={undefined}
          banding={banding}
          tenantDetails={tenantDetails}
          scoringAreaLookup={filteredAreasLookup}
          selectedScoringArea={area}
          scoringEntity={scoringEntity}
          onClick={() => viewStore(l.siteId, dateKey)}
        />
      );
    });
  };

  const renderTreeViewContent = (data: LeaderboardTypes.LeaderboardEntry[]) => {
    const banding = bandingLookup && bandingLookup[bandingId];
    const sortedResult = (() => {
      const groupedDataByFranchise = groupLeaderboardEntriesByFranchise(data);
      if (!area) {
        return groupedDataByFranchise.sort((a, b) => b.averageScore - a.averageScore);
      } else {
        return groupedDataByFranchise.sort((a, b) => {
          const a2 = a.averageAreaScores.find(z => z.area === area);
          const b2 = b.averageAreaScores.find(y => y.area === area);

          if (!a2 || !b2) {
            return 0;
          }
          return b2.averageScore - a2.averageScore;
        });
      }
    })();

    const returnData = sortedResult && sortedResult.map(({ franchise, storesPerFranchise, averageScore, averageAreaScores }) => (
      <div className="leaderboard-tree-view-container" key={franchise}>
        <TreeView key={franchise}
          nodeLabel={
            <FranchiseEntry
              maxScore={5}
              franchise={(storesPerFranchise.find(x => x.showFranchise === true) && franchise) ? franchise : '???'}
              franchiseData={storesPerFranchise}
              numOfStores={storesPerFranchise.length}
              averageTotalScore={averageScore}
              averageAreaScores={averageAreaScores}
              banding={banding}
              scoringAreaLookup={filteredAreasLookup}
              selectedScoringArea={area}
              onClick={() => toggleCollapsedFranchise(franchise)}
            />}
          collapsed={collapsedFranchiseList.includes(franchise)}
        >
          {!collapsedFranchiseList.includes(franchise) && (
            <ul style={{ listStyleType: 'none' }}>
              {storesPerFranchise && storesPerFranchise.map((l, idx) => {
                const scoringEntity = scoringEntityLookup && scoringEntityLookup[l.siteId];
                return (
                  <li key={l.siteId + idx}>
                    <LeaderboardEntry
                      rank={l.rank || (idx + 1)}
                      firstRow={false}
                      maxScore={5}
                      entry={l}
                      prevEntry={undefined}
                      banding={banding}
                      tenantDetails={tenantDetails}
                      scoringAreaLookup={filteredAreasLookup}
                      selectedScoringArea={area}
                      scoringEntity={scoringEntity}
                      onClick={() => viewStore(l.siteId, dateKey)} />
                  </li>
                );
              })}
            </ul>)}
        </TreeView>
      </div>
    ));
    return returnData;
  };

  const groupLeaderboardEntriesByFranchise = (entries: LeaderboardTypes.LeaderboardEntry[]) => {
    const franchiseGroups = entries.reduce((groups: { [franchise: string]: LeaderboardTypes.FranchiseGroup }, entry) => {
      const { franchise, totalScore, scores } = entry;

      if (!groups[franchise]) {
        groups[franchise] = {
          storesPerFranchise: [],
          totalScore: 0,
          entriesPerFranchiseCount: 0,
          scores: {},
        };
      }

      groups[franchise].storesPerFranchise.push(entry);
      groups[franchise].totalScore += totalScore;
      groups[franchise].entriesPerFranchiseCount++;

      scores.forEach(({ area, rawScore }) => {
        if (!groups[franchise].scores[area]) {
          groups[franchise].scores[area] = { rawScore: 0, frachiseAreaCount: 0 };
        }

        groups[franchise].scores[area].rawScore += rawScore;
        groups[franchise].scores[area].frachiseAreaCount++;
      });

      return groups;
    }, {});

    const groupedLeaderboardEntries = Object.entries(franchiseGroups).map(([franchise, group]) => {
      const averageAreaScores = Object.entries(group.scores).map(([area, { rawScore, frachiseAreaCount }]) => ({
        area,
        averageScore: rawScore / frachiseAreaCount,
      }));

      return {
        franchise,
        storesPerFranchise: group.storesPerFranchise,
        averageScore: group.totalScore / group.entriesPerFranchiseCount,
        averageAreaScores,
      };
    });

    return groupedLeaderboardEntries;
  };

  const hasLeaderboards = props.leaderboard.leaderboard?.entries;
  const isFranchiseLevel = selectedGroupByOption === 'Franchise';

  return (
    <Page mode=" leaderboard"
      pageNumber={1}>
      <div className="content podium">
        <div className="podiums">
          <h1>Leaderboard</h1>
          {tenantDetails && leaderboard.leaderboard && podiumData && renderPodium()}
        </div>
      </div>
      <div className="header-controls">
        <div className="header-wrapper">
          <h1>
            Leaderboard
          </h1>
          <div className='header-menu'>
            {permHelper.HasAccessToMultipleStores() && (
              <div className="leaderboard-radio">
                <label htmlFor="leaderboards">
                  <h5>Group By</h5>
                  <div>
                    {groupByOptions && groupByOptions.map(option => (
                      <label htmlFor={option.value} key={option.value}>
                        <input
                          type="radio"
                          id={option.value}
                          value={option.value}
                          checked={selectedGroupByOption === option.value}
                          onChange={handleGroupByOptionChange} />{' '}
                        {option.label}
                      </label>
                    ))}
                  </div>
                </label>
              </div>)}


            <div className="leaderboard-radio">
              <label htmlFor="leaderboards">
                <h5>Visibility</h5>
                <div>
                  <label>
                    <input
                      type="radio"
                      id="mine"
                      name="leaderboards"
                      value="mine"
                      checked={bandingId === 'mine'}
                      onChange={() => handleBandingIdChange('mine')} />Owned</label>
                  <label>
                    <input
                      type="radio"
                      id="all"
                      name="leaderboards"
                      value=""
                      checked={bandingId === ''}
                      onChange={() => handleBandingIdChange('')} /> All</label>
                </div>

                {bandings && bandings.map((band) => (
                  <div key={band.id.toString()}>
                    <input
                      type="radio"
                      id={band.id.toString()}
                      name="leaderboards"
                      value={band.id.toString()}
                      checked={bandingId === band.id.toString()}
                      onChange={() => handleBandingIdChange(band.id.toString())} />
                    <label htmlFor={band.id.toString()}>{band.name}</label>
                  </div>
                ))}
              </label>
            </div>

            {tenantDetails && tenantDetails.reportingWindow &&
              <div className="date-selector">
                <h5>Date Range</h5>
                <CronDateDropdown
                  placeholderText="Select date range"
                  cronString={tenantDetails.reportingWindow || ''}
                  currentDateKey={dateKey}
                  onChange={(dk: string) => dateChanged(dk)}
                  isGrey />
              </div>}
            <div className="leaderboard-selector area-selector">
              <label htmlFor="leaderboards">
                <h5>Sort By</h5>
                <select
                  name="areas"
                  placeholder="Scoring Area"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}>
                  <option value="">Orderly Score</option>

                  {areas && areas.filter((area) => area.type === ScoreAreaType.store).map((scoringArea) => (
                    <option
                      key={scoringArea.dataId}
                      value={scoringArea.dataId}
                    >
                      {scoringArea.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          </div>

          {selectedGroupByOption === 'Franchise' && (
            <div>
              <button className='collapse-button' onClick={handleCollapseAllClick}>{isCollapsedAll ? 'Expand All' : 'Collapse All'}</button>
            </div>)}
        </div>
      </div>

      <div className="content leaderboard">
        <div className="leaderboards">

          {(loading || leaderboard.isLoading) && (<Loading />)}

          {!leaderboard.isLoading && !hasLeaderboards && (<Message text="No leaderboards are currently available." />)}

          {!loading && !leaderboard.isLoading && hasLeaderboards && 
          (isFranchiseLevel ? renderTreeViewContent(leaderboardEntries) : renderContent(leaderboardEntries))}
       
        </div>
      </div>

    </Page>
  );
};

export default connector(withRouter(Leaderboard));