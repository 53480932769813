import * as React from 'react';

interface IProps{
    text: string,
    onClick: Function,
    enabled: boolean,
    className?: string,
}

export default class Button extends React.PureComponent<IProps, {}> {
  public render() {
    const { enabled, text, onClick, className } = this.props;
    return (
      <div className="button ui-toolkit">
        <button
          type="button"
          disabled={!enabled}
          className={className}
          onClick={() => onClick()}
        >
          {text}
        </button>
      </div>
    );
  }
}
