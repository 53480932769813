import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface BandingState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    isDeleted: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    bandings: Banding[];
    selectedBanding?: Banding;
}

export interface Banding {
    id: number;
    name: string;
    competingLabel: string;
    default: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects;
// they just describe something that is going to happen.

interface RequestBandingsAction {
    type: 'REQUEST_BANDINGS';
}

interface ReceiveBandingsAction {
    type: 'RECEIVE_BANDINGS';
    payload: any;
}

interface FailedBandingsAction {
    type: 'FAILED_BANDINGS';
}

interface RequestBandingAction {
    type: 'REQUEST_BANDING';
}

interface ReceiveBandingAction {
    type: 'RECEIVE_BANDING';
    payload: any;
}

interface FailedBandingAction {
    type: 'FAILED_BANDING';
}

interface RequestSaveBandingAction {
    type: 'REQUEST_SAVE_BANDING';
}

interface ReceiveSaveBandingAction {
    type: 'RECEIVE_SAVE_BANDING';
    payload: any;
}

interface FailedSaveBandingAction {
    type: 'FAILED_SAVE_BANDING';
}

interface RequestDeleteBandingAction {
    type: 'REQUEST_DELETE_BANDING';
}

interface ReceiveDeleteBandingAction {
    type: 'RECEIVE_DELETE_BANDING';
    payload: any;
}

interface FailedDeleteBandingAction {
    type: 'FAILED_DELETE_BANDING';
}

// Declare a 'discriminated union' type.
// This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestBandingsAction | ReceiveBandingsAction | FailedBandingsAction |
RequestBandingAction | ReceiveBandingAction | FailedBandingAction | RequestSaveBandingAction |
ReceiveSaveBandingAction | FailedSaveBandingAction | RequestDeleteBandingAction |
ReceiveDeleteBandingAction | FailedDeleteBandingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components
// that will trigger a state transition.
// They don't directly mutate state, but they can have external
// side-effects (such as loading data).

export const actionCreators = {
  requestBandings: (filter: string, page: number, pageSize: number):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.bandings && appState.bandings.isLoading === false) {
      dispatch({
        type: 'REQUEST_BANDINGS',
        http: {
          verb: 'GET',
          endpoint: `/api/bandingConfiguration/bandings?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_BANDINGS',
          failureAction: 'FAILED_BANDINGS',
        },
      });
    }
  },
  requestBanding: (bandingId: string): AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.bandings && appState.bandings.isLoading === false) {
      dispatch({
        type: 'REQUEST_BANDING',
        http: {
          verb: 'GET',
          endpoint: `/api/bandingConfiguration/banding?bandingId=${bandingId}`,
          successAction: 'RECEIVE_BANDING',
          failureAction: 'FAILED_BANDING',
        },
      });
    }
  },
  saveBanding: (data: {id :number, name :string, competingLabel: string}):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.bandings && appState.bandings.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_BANDING',
        http: {
          verb: 'POST',
          endpoint: '/api/bandingConfiguration/save',
          successAction: 'RECEIVE_SAVE_BANDING',
          failureAction: 'FAILED_SAVE_BANDING',
          body: {
            ...data,
          },
        },
      });
    }
  },
  deleteBanding: (bandingId: string): AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.bandings && appState.bandings.isLoading === false) {
      dispatch({
        type: 'REQUEST_DELETE_BANDING',
        http: {
          verb: 'DELETE',
          endpoint: `/api/bandingConfiguration/delete?bandingId=${bandingId}`,
          successAction: 'RECEIVE_DELETE_BANDING',
          failureAction: 'FAILED_DELETE_BANDING',
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: BandingState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,
  isDeleted: false,

  bandings: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<BandingState> = (state: BandingState |
    undefined, incomingAction: Action): BandingState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_BANDINGS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      isDeleted: false,

      bandings: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'RECEIVE_BANDINGS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      isDeleted: false,

      bandings: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,
    };
  case 'FAILED_BANDINGS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      isDeleted: false,

      bandings: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,
    };
  case 'REQUEST_BANDING':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      isDeleted: false,
      selectedBanding: undefined,
    };
  case 'RECEIVE_BANDING':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      isDeleted: false,
      selectedBanding: action.payload,
    };
  case 'FAILED_BANDING':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      isDeleted: false,
      selectedBanding: undefined,
    };
  case 'REQUEST_SAVE_BANDING':
  case 'REQUEST_DELETE_BANDING':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
      isDeleted: false,
    };
  case 'RECEIVE_SAVE_BANDING':
  case 'RECEIVE_DELETE_BANDING':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,
      isDeleted: true,
    };
  case 'FAILED_SAVE_BANDING':
  case 'FAILED_DELETE_BANDING':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
      isDeleted: false,
    };
  default:
    return state;
  }
};
