import * as React from 'react';

export default ({ title, children }: { title: string, children?: React.ReactNode }) => (
  <div className="form ui-toolkit">
    <div className="form-wrapper">
      {title.length > 0 && <h2 className="title">{title}</h2>}
      <div className="fields">
        {children}
      </div>
    </div>
  </div>
);
