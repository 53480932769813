import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
    onClick: Function,
    icon: IconDefinition,
    text: string,
    disabled?: boolean,
    extraClass?:string,
}

const NavItemWithIcon = (props: IProps) => {

  const { icon, disabled, extraClass, onClick, text } = props;
  return (
    <button
      type="button"
      className={`nav-item ui-toolkit ${extraClass}`}
      disabled={disabled}
      onClick={() => onClick()}
    >
      <FontAwesomeIcon icon={icon}
        style={{ color: 'white', width: '30px', height: '30px', marginRight: '10%' }} />

      <div className="label">{text}</div>
    </button>
  );
  
};

export default NavItemWithIcon;