import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import ReactGA from 'react-ga';
import Accordion from '../Common/Accordion/accordion';
import * as FaqsStore from '../../store/help/frequentQuestions';
import Loading from '../Common/Loading/loading';
import Error from '../Common/Error/error';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  faqs: state.faqs,
});
const mapDispatch = {
  ...FaqsStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

class FrequentQuestions extends React.PureComponent<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public componentDidMount() {
    const { requestFaqs } = this.props;

    ReactGA.pageview(window.location.pathname + window.location.search);

    requestFaqs();
  }

  public render() {
    const { faqs: { isLoading, frequentQuestions, isErrored,errorMessage } } = this.props;

    return (
      <div className="faq-accordion">
        <h2>FAQ's</h2>

        { isLoading && <Loading /> }

        { isErrored && <div><Error text={ errorMessage } /></div> }

        { frequentQuestions.map((f) => (
          <Accordion key={ f.id }
            title={ f.question }
            content={ f.answer } />
        ),
        )}
      </div>
    );
  }
}

export default connector(withRouter(FrequentQuestions));