import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    users: User[];

    selectedUser?: UserDetail;
    availableRoles: Role[];
    availableScoringEntities: ScoringEntity[];
    availableScoringArea: ScoringArea[];
}

export interface User {
    id: number;
    name: string;
    permissions: number;
    scores: number;
    areas: number;
}

export interface UserDetail {
  emailAddress: string;
  phoneNumber: string;
  id: number;
  name: string;
  permissionIds: number[];
  scoreIds: number[];
  areaIds: number[];
  roleId:number;
}

export interface Role {
  id: number;
  name: string;
}

export interface ScoringEntity {
  id: number;
  description: string;
}

export interface ScoringArea {
  id: number;
  name: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects;
// they just describe something that is going to happen.

interface RequestUsersAction {
    type: 'REQUEST_USERS';
}

interface ReceiveUsersAction {
    type: 'RECEIVE_USERS';
    payload: any;
}

interface FailedUsersAction {
    type: 'FAILED_USERS';
}

interface RequestUserAction {
    type: 'REQUEST_USER';
}

interface ReceiveUserAction {
    type: 'RECEIVE_USER';
    payload: any;
}

interface FailedUserAction {
    type: 'FAILED_USER';
}

interface RequestSaveUserAction {
    type: 'REQUEST_SAVE_USER';
}

interface ReceiveSaveUserAction {
    type: 'RECEIVE_SAVE_USER';
    payload: any;
}

interface FailedSaveUserAction {
    type: 'FAILED_SAVE_USER';
}

// Declare a 'discriminated union' type.
// This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestUsersAction | ReceiveUsersAction | FailedUsersAction |
RequestUserAction | ReceiveUserAction | FailedUserAction |
RequestSaveUserAction | ReceiveSaveUserAction | FailedSaveUserAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI
// components that will trigger a state transition.
// They don't directly mutate state, but they can have
// external side-effects (such as loading data).

export const actionCreators = {
  requestUsers: (filter: string, page: number, pageSize: number):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.users && appState.users.isLoading === false) {
      dispatch({
        type: 'REQUEST_USERS',
        http: {
          verb: 'GET',
          endpoint: `/api/userConfiguration/users?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_USERS',
          failureAction: 'FAILED_USERS',
        },
      });
    }
  },
  requestUser: (userId: string): AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.users && appState.users.isLoading === false) {
      dispatch({
        type: 'REQUEST_USER',
        http: {
          verb: 'GET',
          endpoint: `/api/userConfiguration/userDetail?userId=${userId}`,
          successAction: 'RECEIVE_USER',
          failureAction: 'FAILED_USER',
        },
      });
    }
  },
  saveUser: (data: {
    id: number,
    name: string,
    phoneNumber: string,
    emailAddress: string,
    selectedAreaIds:number[],
    selectedRoleId: number,
    selectedScoreIds:number[]
   }): AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.users && appState.users.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_USER',
        http: {
          verb: 'POST',
          endpoint: '/api/userConfiguration/save',
          successAction: 'RECEIVE_SAVE_USER',
          failureAction: 'FAILED_SAVE_USER',
          body: {
            ...data,
          },
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.

const unloadedState: UserState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  users: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,

  availableRoles: [],
  availableScoringEntities: [],
  availableScoringArea: [],
};

export const reducer: Reducer<UserState> = (state: UserState |
    undefined, incomingAction: Action): UserState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
  case 'REQUEST_USERS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      users: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,

      availableRoles: [],
      availableScoringEntities: [],
      availableScoringArea: [],
    };
  case 'RECEIVE_USERS':
    return {
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      users: action.payload.data,
      pageNumber: action.payload.pageNumber,
      pageSize: action.payload.pageSize,
      totalRecords: action.payload.totalRecords,

      availableRoles: [],
      availableScoringEntities: [],
      availableScoringArea: [],
    };
  case 'FAILED_USERS':
    return {
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      users: [],
      pageNumber: 1,
      pageSize: 25,
      totalRecords: 0,

      availableRoles: [],
      availableScoringEntities: [],
      availableScoringArea: [],
    };
  case 'REQUEST_USER':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,

      selectedUser: undefined,
      availableRoles: [],
      availableScoringEntities: [],
      availableScoringArea: [],
    };
  case 'RECEIVE_USER':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: false,

      selectedUser: action.payload.user,
      availableRoles: action.payload.availableRoles || [],
      availableScoringEntities: action.payload.availableReportingEntities || [],
      availableScoringArea: action.payload.availableAreas || [],
    };
  case 'FAILED_USER':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,

      selectedUser: undefined,
      availableRoles: [],
      availableScoringEntities: [],
      availableScoringArea: [],
    };
  case 'REQUEST_SAVE_USER':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: true,
      isUpdated: false,
    };
  case 'RECEIVE_SAVE_USER':
    return {
      ...state,
      errorMessage: '',
      isErrored: false,
      isLoading: false,
      isUpdated: true,
    };
  case 'FAILED_SAVE_USER':
    return {
      ...state,
      errorMessage: 'An error occurred.',
      isErrored: true,
      isLoading: false,
      isUpdated: false,
    };
  default:
    return state;
  }
};
