import React from 'react';
import ReactGA from 'react-ga';

export default class Footer extends React.PureComponent<{}, { isOpen: boolean }> {
  public componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  public render() {
    const year = new Date().getFullYear();

    return (
      <footer className="ui-toolkit">
        <div className="container">
          <div className="message">
            <p>
              <a href="https://orderly.io/">Learn more</a>
              {' '}
              about how we can all work together towards a more sustainable future.
            </p>
          </div>
          <div className="left-nav">
            <nav>
              <a href="https://orderly.io/privacy-policy">Privacy Policy</a>
            </nav>
            <div className="copyright">
              &copy;
              {` ${year} Orderly`}
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
