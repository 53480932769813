import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import { ApplicationState } from '../../store';
import ReactGA from 'react-ga';
import * as AuthActions from '../../store/authentication/actions';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
});
const mapDispatch = {
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoreid?: string, datekey?: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type Props =
  PropsFromRedux
  & PropsType;


class EmbeddedLogin extends React.PureComponent<Props, {}> {
    public recaptchaRef: any = React.createRef();

    public componentDidMount() {
      const { history, match } = this.props;
      
      ReactGA.pageview(window.location.pathname + window.location.search);

      const query = qs.parse(history.location.search.replace('?', ''));

      const gatewayToken: string = (query.gatewaytoken && query.gatewaytoken.toString()) || '';
      const refreshToken: string = (query.refreshtoken && query.refreshtoken.toString()) || '';

      if (gatewayToken && refreshToken) {
        history.replace(`/embedded/${match.params.scoreid}/${match.params.datekey}`);
        this.onSubmit(gatewayToken, refreshToken);
      }
    }

    private onSubmit(gatewayToken:string, refreshToken:string) {
      //TODO check later
    }

    public render() {
      return null;
    }
}

export default connector(withRouter(EmbeddedLogin));
