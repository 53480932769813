import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CategoryState {
    isLoading: boolean;
    isErrored: boolean;
    isUpdated: boolean;
    errorMessage: string;

    pageNumber: number;
    pageSize: number;
    totalRecords: number;
    categories: Category[];

    selectedCategory?: Category;
}

export interface Category {
    id: number;
    default: number;
    name: string;
    shockGraphic?: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state
// transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

interface RequestCategoriesAction {
    type: 'REQUEST_CATEGORIES';
}

interface ReceiveCategoriesAction {
    type: 'RECEIVE_CATEGORIES';
    payload: any;
}

interface FailedCategoriesAction {
    type: 'FAILED_CATEGORIES';
}

interface RequestCategoryAction {
    type: 'REQUEST_CATEGORY';
}

interface ReceiveCategoryAction {
    type: 'RECEIVE_CATEGORY';
    payload: any;
}

interface FailedCategoryAction {
    type: 'FAILED_CATEGORY';
}

interface RequestSaveCategoryAction {
    type: 'REQUEST_SAVE_CATEGORY';
}

interface ReceiveSaveCategoryAction {
    type: 'RECEIVE_SAVE_CATEGORY';
    payload: any;
}

interface FailedSaveCategoryAction {
    type: 'FAILED_SAVE_CATEGORY';
}

interface RequestDeleteCategoryAction {
    type: 'REQUEST_DELETE_CATEGORY';
}

interface ReceiveDeleteCategoryAction {
    type: 'RECEIVE_DELETE_CATEGORY';
    payload: any;
}

interface FailedDeleteCategoryAction {
    type: 'FAILED_DELETE_CATEGORY';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestCategoriesAction | ReceiveCategoriesAction | FailedCategoriesAction |
    RequestCategoryAction | ReceiveCategoryAction | FailedCategoryAction |
    RequestSaveCategoryAction | ReceiveSaveCategoryAction | FailedSaveCategoryAction |
    RequestDeleteCategoryAction | ReceiveDeleteCategoryAction | FailedDeleteCategoryAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a
// state transition.
// They don't directly mutate state, but they can have external side-effects (such as
// loading data).

export const actionCreators = {
  requestCategories: (filter: string, page: number, pageSize: number):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.categories && appState.categories.isLoading === false) {
      dispatch({
        type: 'REQUEST_CATEGORIES',
        http: {
          verb: 'GET',
          endpoint: `/api/ItemCategoryConfiguration/Categories?filter=${filter}&page=${page}&pagesize=${pageSize}`,
          successAction: 'RECEIVE_CATEGORIES',
          failureAction: 'FAILED_CATEGORIES',
        },
      });
    }
  },
  requestCategory: (categoryId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.categories && appState.categories.isLoading === false) {
      dispatch({
        type: 'REQUEST_CATEGORY',
        http: {
          verb: 'GET',
          endpoint: `/api/ItemCategoryConfiguration/Category?categoryId=${categoryId}`,
          successAction: 'RECEIVE_CATEGORY',
          failureAction: 'FAILED_CATEGORY',
        },
      });
    }
  },
  saveCategory: (data: { id: number, name:string }):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.categories && appState.categories.isLoading === false) {
      dispatch({
        type: 'REQUEST_SAVE_CATEGORY',
        http: {
          verb: 'POST',
          endpoint: '/api/ItemCategoryConfiguration/save',
          successAction: 'RECEIVE_SAVE_CATEGORY',
          failureAction: 'FAILED_SAVE_CATEGORY',
          body: {
            ...data,
          },
        },
      });
    }
  },
  deleteCategory: (categoryId: string):
  AppThunkAction<any> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.categories && appState.categories.isLoading === false) {
      dispatch({
        type: 'REQUEST_DELETE_CATEGORY',
        http: {
          verb: 'DELETE',
          endpoint: `/api/ItemCategoryConfiguration/delete?categoryId=${categoryId}`,
          successAction: 'RECEIVE_DELETE_CATEGORY',
          failureAction: 'FAILED_DELETE_CATEGORY',
        },
      });
    }
  },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time
// travel, this must not mutate the old state.

const unloadedState: CategoryState = {
  errorMessage: '',
  isErrored: false,
  isLoading: false,
  isUpdated: false,

  categories: [],
  pageNumber: 1,
  pageSize: 25,
  totalRecords: 0,
};

export const reducer: Reducer<CategoryState> = (state:
    CategoryState | undefined, incomingAction: Action): CategoryState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_CATEGORIES':
      return {
        errorMessage: '',
        isErrored: false,
        isLoading: true,
        isUpdated: false,

        categories: [],
        pageNumber: 1,
        pageSize: 25,
        totalRecords: 0,
      };
    case 'RECEIVE_CATEGORIES':
      return {
        errorMessage: '',
        isErrored: false,
        isLoading: false,
        isUpdated: false,

        categories: action.payload.data,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalRecords: action.payload.totalRecords,
      };
    case 'FAILED_CATEGORIES':
      return {
        errorMessage: 'An error occurred.',
        isErrored: true,
        isLoading: false,
        isUpdated: false,

        categories: [],
        pageNumber: 1,
        pageSize: 25,
        totalRecords: 0,
      };
    case 'REQUEST_CATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: true,
        isUpdated: false,

        selectedCategory: undefined,
      };
    case 'RECEIVE_CATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: false,
        isUpdated: false,

        selectedCategory: action.payload,
      };
    case 'FAILED_CATEGORY':
      return {
        ...state,
        errorMessage: 'An error occurred.',
        isErrored: true,
        isLoading: false,
        isUpdated: false,

        selectedCategory: undefined,
      };

    case 'REQUEST_SAVE_CATEGORY':
    case 'REQUEST_DELETE_CATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: true,
        isUpdated: false,
      };
    case 'RECEIVE_SAVE_CATEGORY':
    case 'RECEIVE_DELETE_CATEGORY':
      return {
        ...state,
        errorMessage: '',
        isErrored: false,
        isLoading: false,
        isUpdated: true,
      };
    case 'FAILED_SAVE_CATEGORY':
    case 'FAILED_DELETE_CATEGORY':
      return {
        ...state,
        errorMessage: 'An error occurred.',
        isErrored: true,
        isLoading: false,
        isUpdated: false,
      };
    default:
      return state;
  }
};
