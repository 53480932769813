import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

type IProps = 
  { 
    label: string,
    style: string, 
    children?: React.ReactNode, 
    editMode?: boolean
    addMetricHandler: Function,
    metricVariables?: string[],
    onInputChange: Function,
  } 

const ScoreListItemMetric = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const { style, label, children, editMode, addMetricHandler, onInputChange, metricVariables } = props;

  return (
    <div className="score-list-item-metric ui-toolkit">
      <div className={`header ${style}`}>
        {
          editMode ? 
            <select className='variable-selector'
              value={label} 
              onChange={(e) => onInputChange(e.target.value)}>
              <option value={''}>Numbered</option>
              {
                metricVariables && metricVariables.map(m => (
                  <option key={m}
                    value={m}>{m}</option>
                ))
              }

            </select>
            : 
            label
        } 
      </div>
        
      <div className="metrics">
        {children}
        {
          editMode && 
            <div className='add-metric'>
              <button onClick={() => addMetricHandler()}
                className='add-metric-btn'>Add new metric +
              </button>
            </div>
        }
      </div>
      <datalist id="styleOptions">
        {
          metricVariables && metricVariables.map(m => (
            <option key={m}>{m}</option>
          ))
        }
          
      </datalist>
    </div>
  );
  
};

export default ScoreListItemMetric;
