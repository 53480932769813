/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnyAction } from 'redux';
import { Suggestion } from '../score/types';

export interface Period {
    end: string,
    start: string
}

export interface Metrics {
    [id:string]: any
}

export interface ListMetrics {
    [id:string]: Metrics[]
}

export interface Template {
    components: Components,
    componentValues: ComponentValue[]
}

export interface Components {
    listMetrics: ListMetric[],
    sequence: number[],
    textMetrics: TextMetric[],
    valueMetrics: ValueMetric[],
}

export interface ComponentValue {
    scoringEntityDataId: string,
    rawScore: number[],
    metrics: any,
    listMetrics: any,
    suggestions: Suggestion[]
}

export interface ValueMetric {
    id: number,
    label: string,
    value: string,
    total: string,
    format: string,
    increaseGood: boolean,
    tooltip:string,
}

export interface TextMetric {
    id: number,
    text: string,
    tooltip:string,
}

export interface ListMetric {
    id: number,
    label: string,
    tooltip:string,
    data: string,
    flavouredData?: string,
    style: string,
    items: ListItemRow,
    sortOptions: SortOption[]
}

export interface ListItemRow {
    title: string,
    metrics: ListItemMetric[]
}

export interface ListItemMetric {
    id: number,
    format: string,
    label: string,
    value: string,
    suffix?: string
}

export interface MetricVariable {
    id: number,
    label: string,
    value: string,
    type: MetricVariableType
}

export interface MetricScoringEntityData {
    scoringEntityDataId: string,
    values: number[] | string[]
} 

export interface SortOption {
    label: string,
    data: string,
}

export interface ScoreTrendPoint{
    rawScore: number,
    reportingWindowStart: string,
}

export interface MetricWrapper {
    id:string,
    tenantId:string,
    name: string,
    siteId:string,
    area:string,
    period: Period,
    image: string,
    sequence: number,
    dataId: string,

    rawScore: number,

    scoreTrends: ScoreTrendPoint[],
    metrics: Metrics,
    listMetrics: ListMetrics
}

export interface ItemData{
    categoryId: number,
    categoryName: string,
    description: string,
    id: number
    label: string
}

export interface trendData{
    id:string,
    tenantId:string,
    siteId:string,
    period: Period;
    scoreTrends: ScoreTrendPoint[];
}

export interface ScoreAreaResponse {
    template: Template,
    data: MetricWrapper,
    items: ItemData[],
    previousData?: MetricWrapper,
    trend: trendData,
    previousTrend:trendData
}
export interface MetricTrend {
    reportingWindowStart: string,
    metrics: MetricValue[],
}
export interface MetricValue{
    label: string,
    value:number,
}


export enum MetricType {
    Value = 'value',
    List = 'list',
    ListItem = 'listItem',
    Sort = 'sort',
    Text = 'text' 
}

export enum MetricVariableType {
    Value = 'value',
    ListItem = 'listItem',
    Sort = 'sort'
}

export enum TemplateUpdateActionType {
    Add = 'add',
    Update = 'update',
    UpdateValue = 'updateValue',
    Delete = 'delete'
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something
// that is going to happen.

export interface RequestScoreAreaAction extends AnyAction {
    type: 'REQUEST_SCOREAREA';
}

export interface ReceiveScoreAreaAction extends AnyAction {
    type: 'RECEIVE_SCOREAREA';
    payload: ScoreAreaResponse;
}

export interface FailedScoreAreaAction extends AnyAction {
    type: 'FAILED_SCOREAREA';
}

export interface RequestScoreAreasAction extends AnyAction {
    type: 'REQUEST_SCOREAREAS';
}

export interface ReceiveScoreAreasAction extends AnyAction {
    type: 'RECEIVE_SCOREAREAS';
}

export interface FailedScoreAreasAction extends AnyAction {
    type: 'FAILED_SCOREAREAS';
}

export interface RequestSaveScoreAreaAction extends AnyAction {
    type: 'REQUEST_SAVE_SCOREAREA';
}

export interface ReceiveSaveScoreAreaAction extends AnyAction {
    type: 'RECEIVE_SAVE_SCOREAREA';
}

export interface FailedSaveScoreAreaAction extends AnyAction {
    type: 'FAILED_SAVE_SCOREAREA';
}

export interface RequestAreaMetricTrendsAction extends AnyAction {
    type: 'REQUEST_AREA_METRIC_TRENDS';
}

export interface ReceiveAreaMetricTrendsAction extends AnyAction {
    type: 'RECEIVE_AREA_METRIC_TRENDS';
    payload: MetricTrend[];
}

export interface FailedAreaMetricTrendsAction extends AnyAction {
    type: 'FAILED_AREA_METRIC_TRENDS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type'
// properties contain one of the
// declared type strings (and not any other arbitrary string).
export type AreaAction = RequestScoreAreaAction | ReceiveScoreAreaAction | FailedScoreAreaAction | 
                        RequestScoreAreasAction | ReceiveScoreAreasAction | FailedScoreAreasAction |
                        RequestSaveScoreAreaAction | ReceiveSaveScoreAreaAction | FailedSaveScoreAreaAction |
                        RequestAreaMetricTrendsAction | ReceiveAreaMetricTrendsAction | FailedAreaMetricTrendsAction;

