import * as React from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga';

interface IProps {
    label: string,
    companyImageLink: string,
}

export const Avatar = (props: IProps) => {
  const { label, companyImageLink } = props;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="avatar ui-toolkit">
      <div className='avatar-circle'>{label}</div>
      <img className="company-logo"
        src={`./assets/tenants/${companyImageLink}`}
        alt="Company Logo" />
    </div>
  );
};
