import * as React from 'react';

interface IProps {
    onClick: Function,
    imagePath: string,
    imageAltTest: string,
    text: string,
    disabled?: boolean,
    extraClass?:string
}

export default class NavItem extends React.PureComponent<IProps, {}> {
  public render() {
    const {
      imageAltTest,
      imagePath,
      disabled,
      extraClass,
      onClick,
      text,
    } = this.props;
    return (
      <button
        type="button"
        className={`nav-item ui-toolkit ${extraClass}`}
        disabled={disabled}
        onClick={() => onClick()}
      >
        <img
          src={imagePath}
          alt={imageAltTest}
        />
        <div className="label">{text}</div>
      </button>
    );
  }
}
