import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import Table from '../../uiToolkit/containers/table';
import { ApplicationState } from '../../store';
import * as ItemStore from '../../store/configuration/Items';
import Button from '../../uiToolkit/components/button';
import Modal from '../../uiToolkit/components/modal';
import ReactGA from 'react-ga';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  items: state.items,
});
const mapDispatch = {
  ...ItemStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

interface PropsType extends RouteComponentProps { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

interface IState {
  filter: string;
  page: number;
  pageSize: number;
  showModal: boolean;
  selectedIds: number[];
  selectedGroupId?: string;
}

class Items extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      filter: '',
      page: 1,
      pageSize: 10,
      showModal: false,
      selectedIds: [],
      selectedGroupId: undefined,
    };
  }

  public componentDidMount() {
    const { history, requestItems } = this.props;
    
    ReactGA.pageview(window.location.pathname + window.location.search);

    const query = qs.parse(history.location.search.replace('?', '').toLowerCase());

    const queryFilter: string = (query.filter && query.filter.toString()) || '';
    const queryPage: number = parseInt((query.page && query.page.toString()) || '1', 10) || 1;

    const newState: IState = {
      ...this.state,
    };

    let updateState: boolean = false;

    if (newState.filter !== queryFilter && queryFilter.length > 0) {
      updateState = true;
      newState.filter = queryFilter;
    }

    if (newState.page !== queryPage && queryPage > 0) {
      updateState = true;
      newState.page = queryPage;
    }

    if (updateState) {
      this.setState(newState);
    }

    requestItems(newState.filter, newState.page, newState.pageSize);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { requestItems, items } = this.props;
    const { filter, page, pageSize } = this.state;

    if (items.isUpdated && !prevProps.items.isUpdated && !items.isLoading) {
      toast.info('Successfully updated items');
      requestItems(filter, page, pageSize);
      this.setState({ selectedIds: [] });
    } else if(items.isErrored && !prevProps.items.isErrored) {
      toast.error('There was an error updating items', {
        className: 'toast-popup error',
      });
    }
  }

  public setCategory() {
    const { RequestSetItemCategory } = this.props;
    const { selectedIds, selectedGroupId } = this.state;

    if (selectedIds.length > 0 && selectedGroupId && selectedGroupId.length > 0) {
      RequestSetItemCategory(selectedGroupId, selectedIds);
      this.handleModalHide();
    }
  }


  public handleModalShow() {
    this.setState({ showModal: true });
  }

  public handleModalHide() {
    this.setState({ showModal: false });
  }

  public toggleOption(id: number) {
    const { selectedIds } = this.state;
    const index: number = selectedIds.indexOf(id);

    if (index > -1) {
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds: [...selectedIds] });
  }

  public doSearch(filter: string) {
    const { location, history, requestItems } = this.props;
    const { pathname } = location;
    const { pageSize } = this.state;

    this.setState({ filter, page: 1 });

    history.replace(`${pathname}?filter=${filter}&page=${1}`);

    requestItems(filter, 1, pageSize);
  }

  public doPaging(page: number) {
    const { location, history, requestItems } = this.props;
    const { pathname } = location;
    const { filter, pageSize } = this.state;

    this.setState({ page });

    history.replace(`${pathname}?filter=${filter}&page=${page}`);

    requestItems(filter, page, pageSize);
  }

  public render() {
    const { items } = this.props;
    const {
      filter,
      page,
      pageSize,
      selectedIds,
      showModal,
    } = this.state;

    const entries = items.items.sort((a, b) => a.id - b.id);
    const options = items.availableOptions.sort((a, b) => a.id - b.id);

    return (
      <Page mode=" center"
        pageNumber={1}
        padding>
        <div className="wrapper">
          <div className="text-wrapper">
            <h1>Configure Items</h1>
            <p>
            Score areas may use leaderboards to show the top items that contributed
            to your score. These items can be further categorised to show detailed views.

            </p>
            <p>
            Please note changes to groups will be reflected only when data is processed
            within the current or future reporting periods.

            </p>
          </div>
          <Button
            text="Set Category"
            enabled={selectedIds.length > 0}
            onClick={() => this.handleModalShow()}
          />
          <Table
            add={false}
            addClick={() => null}
            addLabel=""
            search
            searchClick={(f: string) => this.doSearch(f)}
            initialValue={filter}
            pagination
            pageSize={pageSize}
            pageNumber={page}
            pageChange={(p: number) => this.doPaging(p)}
            totalRecords={items.totalRecords}
          >
            <thead>
              <tr>
                <th className="padding title">ID</th>
                <th className="padding title">Description</th>
                <th className="padding title">Category</th>
              </tr>
            </thead>
            <tbody>
              {items.isLoading && 
                <tr><td colSpan={3}><Loading/></td></tr>}

              {!items.isLoading && entries.map((b) => (
                <tr
                  key={b.id.toString()}
                  onClick={() => this.toggleOption(b.id)}
                  className={`${selectedIds.indexOf(b.id) > -1 ? 'selected' : ''}`}
                >
                  <td className="padding">{b.label}</td>
                  <td className="padding">{b.description}</td>
                  <td className="padding">{b.categoryName }</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {showModal && 
          ( <Modal
            title="Set Category"
            cancel={() => this.handleModalHide()}
            inProgress={items.isLoading}
            continue={() => this.setCategory()}
            background={true}
          >
            <select onChange={(e) => this.setState({ selectedGroupId: e.target.value })}>
              <option value="">Select a Group</option>
              {options.map((a) => <option value={a.id}
                key={a.id}>{a.name}</option>)}
            </select>
          </Modal>
          )}
        </div>
      </Page>
    );
  }
}

export default connector(withRouter(Items));
