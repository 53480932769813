import * as React from 'react';

type TableEntryProps = {
    id: string,
    label: string,
    fade: boolean,
    onClick: Function
}

export default class TableAddEntry extends React.PureComponent<TableEntryProps, {}> {
  public render() {
    const { fade, onClick, label } = this.props;

    return (
      <div className={`table-add-entry ui-toolkit ${fade ? 'dim' : ''}`}>
        <button
          type="button"
          onClick={() => (!fade ? onClick() : null)}
        >
          <div className="label">{label}</div>
         
          <div className="plus-icon">
            +
            <div className="circle-plus-icon"></div>
          </div>
        </button>
      
      </div>
    );
  }
}
