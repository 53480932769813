import * as React from 'react';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Avatar } from '../../Common/Avatar/avatar';

type LeaderboardEntryProps = {
  first: string,
  firstScore: number,
  second: string,
  secondScore: number,
  third: string,
  thirdScore: number,
  companyLogo:string,
}

export const Podium = (props: LeaderboardEntryProps) => {
  const { first, firstScore, second, secondScore, third, thirdScore, companyLogo } = props;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="podium ui-toolkit">
      <div className="positions">
        <div className={`first ${first === '?' && 'text-lg'}`}>
          <div className="position">1st</div>
          <Avatar label={first}
            companyImageLink={companyLogo} />
          <div className="score">{firstScore.toFixed(1)}<span className="total">/5</span></div>
        </div>

        <div className={`second ${second === '?' && 'text-lg'}`}>
          <div className="position">2nd</div>
          <Avatar label={second}
            companyImageLink={companyLogo} />
          <div className="score">{secondScore.toFixed(1)}<span className="total">/5</span></div>
        </div>

        <div className={`third ${third === '?' && 'text-lg'}`}>
          <div className="position">3rd</div>
          <Avatar label={third}
            companyImageLink={companyLogo} />
          <div className="score">{thirdScore.toFixed(1)}<span className="total">/5</span></div>
        </div>

      </div>
    </div>
  );
};
