import * as React from 'react';
import ReactGA from 'react-ga';
import * as LeaderboardTypes from '../../../store/leaderboard/types';
import * as AuthTypes from '../../../store/authentication/types';
import ToolTips from '../../Common/ToolTips/toolTips';
import { useEffect, useState } from 'react';
import { WaveScore } from '../../Common/WaveScore/waveScore';

type IProps = {
    rank: number,
    firstRow: boolean,
    maxScore: number,
    onClick: Function,
    entry:LeaderboardTypes.LeaderboardEntry,
    prevEntry?:LeaderboardTypes.LeaderboardEntry,
    banding?:AuthTypes.Banding,
    tenantDetails?: AuthTypes.TenantDetails,
    scoringAreaLookup?: { [id: string]: AuthTypes.ScoreArea },
    selectedScoringArea?: string,
    scoringEntity?: AuthTypes.ScoringEntity,
}

const LeaderboardEntry  = (props: IProps) => {
  const[scoringAreaTooltipsLookup, setScoringAreaTooltipsLookup] = useState<{ [area: string]: boolean }>({});

  useEffect(() => {
    const { scoringAreaLookup } = props;
    const scoringAreaTooltipsLookup: { [area: string]: boolean } = {};
    if(scoringAreaLookup) {
      Object.keys(scoringAreaLookup).forEach((scoringArea) => {
        scoringAreaTooltipsLookup[scoringArea] = false;
      });
      setScoringAreaTooltipsLookup(scoringAreaTooltipsLookup);
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  //check if the scoring area of entity exists and if the same is selected in the sort dropdown
  const checkIfActiveArea = (scoringArea: string) => {
    const entryScoringArea = props.entry.scores.find(s => s.area === (props.scoringAreaLookup && props.scoringAreaLookup[scoringArea].dataId));
    if (entryScoringArea && entryScoringArea.area === props.selectedScoringArea) {
      return true;
    }
    return false;
  };

  const getAreaScore = (scoringArea: string) => {
    const entryScoringArea = props.entry.scores.find(s => s.area === (props.scoringAreaLookup && props.scoringAreaLookup[scoringArea].dataId));
    if (entryScoringArea && entryScoringArea.rawScore > 0) {
      return entryScoringArea.rawScore.toFixed(1);
    }
    return '0.0';
  };

  const toggleTooltip = (scoringArea: string, toggle: boolean) => {
    const newScoringAreaLookup = { ...scoringAreaTooltipsLookup };
    if(scoringAreaTooltipsLookup) {
      newScoringAreaLookup[scoringArea] = toggle;
    }
    setScoringAreaTooltipsLookup(newScoringAreaLookup);
  };
   
  const ownerName = (props.scoringEntity && props.scoringEntity.owner && props.scoringEntity.owner.name) || 'No owner set';

  return (
    <div className='leaderboard-wrapper'>
      {
        props.firstRow && 
            <div className='leaderboard-headers'>
              <div className='leaderboard-header'>Ranking</div>
              <div className='leaderboard-header'>Store Info <br/> Store Owner</div>
              <div className='leaderboard-header'>Scoring Areas</div>
              <div className='leaderboard-header'>Orderly Score</div>
            </div>
      }
      <div className={`leaderboard-entry ui-toolkit ${!props.scoringEntity ? 'dim' : ''}`}
        onClick={() => (props.scoringEntity ? props.onClick() : null)}>
        <div className={`entry-header ${!props.scoringEntity ? 'dim' : ''}`}>
          <div className="position">{props.rank}</div>
          <div className='streak-icon'>{ props.entry.onStreak && <img src='assets/flame.png' /> }</div>
          <div className="change">
          </div>
        </div>
        <div className="metrics">
          <div className='store'>
            <img className="avatar-circle"
              src={`./assets/tenants/${(props.tenantDetails && props.tenantDetails.logo) || ''}`}
              alt="Company Logo" />
            <label>{props.entry.siteId}</label>
          </div>
          <button className="leaderboard-details-info">
            <div className={`details ${!props.scoringEntity ? 'dim' : ''}`}>
              <div className="label">
                {props.scoringEntity && props.scoringEntity.name.replace(props.entry.siteId, '').trim()}
              </div>
              <div className="tenant">
                {props.tenantDetails && props.tenantDetails.name}
              </div>
              <div className="owner-details">
                <div className="owner">
                  {ownerName}
                </div>
              </div>
            </div>
          </button>
          <div className="areas">
            {props.scoringAreaLookup && Object.keys(props.scoringAreaLookup).map((scoringArea) => (
              <div
                onMouseOver={() => toggleTooltip(scoringArea, true)}
                onMouseOut={() => toggleTooltip(scoringArea, false)}
                className={ window.screen.width < 834 && 
                  checkIfActiveArea(scoringArea) 
                  ? 
                  `area-score active ${!props.scoringEntity ? 'dim' : ''}` 
                  : 
                  `area-score ${!props.scoringEntity ? 'dim' : ''}`}
                key={props.scoringAreaLookup?.[scoringArea].dataId}>
                <img
                  className='area-image'
                  src={ `/assets/areas/${props.scoringAreaLookup?.[scoringArea].image}` }
                ></img>
                <ToolTips
                  text={props.scoringAreaLookup?.[scoringArea]?.name ?? ''}
                  tipToggled={Object.keys(scoringAreaTooltipsLookup).length > 0 ? scoringAreaTooltipsLookup[scoringArea] : false}
                  handleParentClick={() => null}
                  onInputChange={() => null}
                  hideButton={true}
                />
                <div>
                  {getAreaScore(scoringArea)}
                </div>
              </div>
            ))}
          </div>
          <div className={`score ${!props.scoringEntity ? 'dim' : ''}`}>
            <WaveScore maxScore={props.maxScore}
              score={props.entry.totalScore}
              index={props.rank + 10} />
            <button type="button">
              <div className="chevron" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderboardEntry;