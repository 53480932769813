/* eslint-disable no-unused-vars */
import { connect, ConnectedProps } from 'react-redux';
import { ApplicationState } from '../../store';
import React, { useEffect, useState } from 'react';
import Page from '../../uiToolkit/containers/page';
import MetricCard from './MetricCard/metricCard';
import * as ProductActions from '../../store/product/actions';
import * as AuthActions from '../../store/authentication/actions';
import { useHistory, useParams } from 'react-router';
import Loading from '../Common/Loading/loading';
import ReactGA from 'react-ga';
import qs from 'qs';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import { WaveScore } from '../Common/WaveScore/waveScore';
import ToolTips from '../Common/ToolTips/toolTips';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import { ProductScoreIndicatorResult, ScoringEntityTypes } from '../../store/product/types';
import ReportingEntityDropdown from '../Common/ReportingEntityDropdown/ReportingEntityDropdown';
import PermissionHelper from '../../uiToolkit/helpers/PermissionHelper';
import { Message } from '../Common/Message/message';
import Modal from '../../uiToolkit/components/modal';
import { ScoreAreaType } from '../../store/authentication/types';

const mapState = (state: ApplicationState) => ({
  product: state.product,
  authentication: state.authentication,
});

const mapDispatch = {
  ...ProductActions.actionCreators,
  ...AuthActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type ProductParams = {
    productid: string;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type IProps = PropsFromRedux;

const ProductScoreCard = (props: IProps) => {
  const { productid } = useParams<ProductParams>();
  const [stateDateKey, stateStateDateKey] = useState<string>('');
  const [stateReportingEntity, setStateReportingEntity] = useState<string>(''); // used for the scoring entities dropdown
  const [scoreTipToggled, setScoreTipToggled] = useState(false);
  const [showMissingScorecardsModal, setShowMissingScorecardsModal] = useState(false);
  const [stateItemDesc, setStateItemDesc] = useState('-'); // used to populate item description even when there is no data from the backend

  const { selectedReportingEntity, franchises, tenantDetails, scoringEntities, permissions, scoreAreas } = props.authentication;
  const { setCurrentReportingEntity, setCurrentDate } = props;
  const { product } = props.product;

  const permHelper = new PermissionHelper(permissions);
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const query = qs.parse(history.location.search.replace('?', ''));
    const tenantDetails = props.authentication && props.authentication.tenantDetails;

    const queryDateKey: string = (query.datekey && query.datekey.toString()) || '';
    const queryScoringEntityKey: string = (query.reportingEntity && query.reportingEntity.toString()) || '';

    let stateDateKey: string = queryDateKey;
    let stateReportingEntityKey: string = queryScoringEntityKey;
    
    // Manage the date state
    if (queryDateKey.length > 0) {
      setCurrentDate(queryDateKey);
      stateStateDateKey(queryDateKey);
    } else {
      const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
      stateDateKey = latestValidDate;
      stateStateDateKey(latestValidDate);
      setCurrentDate(latestValidDate);
    }

    if(!queryScoringEntityKey) {
      stateReportingEntityKey = getDefaultSelectedEntity();
    }

    setStateReportingEntity(stateReportingEntityKey);
  
    if(!props.product.product) {
      if(stateReportingEntityKey === ScoringEntityTypes.Area) {
        props.requestProductAverageScoreCard(productid, stateDateKey);
      } else {
        props.requestProductScoreCard(productid, stateDateKey, stateReportingEntityKey);
      }
    }
  }, []);

  useEffect(() => {
    if(stateDateKey && stateReportingEntity) {
      history.replace(`/product/score/${productid}?reportingEntity=${stateReportingEntity}&datekey=${stateDateKey}`);
      if(stateReportingEntity === ScoringEntityTypes.Area) {
        props.requestProductAverageScoreCard(productid, stateDateKey);
      } else {
        props.requestProductScoreCard(productid, stateDateKey, stateReportingEntity);
      }
    }
    
  }, [stateDateKey, stateReportingEntity]);

  useEffect(() => {
    if(product && product.itemDescription) {
      setStateItemDesc(product.itemDescription);
    }
  }, [product]);

  const dateChanged = (selectedDate: string) => {
    const { setCurrentDate } = props;

    setCurrentDate(stateDateKey);
    stateStateDateKey(selectedDate);
  };

  const reportingEntityChanged = (selectedReportingEntity: string) => {
    if (scoringEntities && scoringEntities.map(x => x.dataId).includes(selectedReportingEntity)) {
      setCurrentReportingEntity(selectedReportingEntity); // if the option selected is a scoring entity, update the auth state
    }
    setStateReportingEntity(selectedReportingEntity);
  };

  const getDefaultSelectedEntity = () => {
    if (stateReportingEntity) {
      return String(stateReportingEntity);
    }

    if (permHelper.IsAdmin()) {
      return String(tenantDetails?.tenantId);
    }

    if (permHelper.IsOperationsManager()) {
      return String(franchises && franchises[0]);
    }

    if(permHelper.HasAccessToMultipleStores()) {
      return 'area';
    }

    return String(selectedReportingEntity);
  };

  const getItemNumberFromUrl = () => {
    const url = window.location.href;
    const regex = /\/(\d+)\?/;
    const match = url.match(regex);

    if (match && match.length > 1) {
      const itemNumber = match[1];
      return itemNumber;
    }

    return '-';
  };


  const getIndicatorIcon = (result: string) => {
    switch(result) {
    case ProductScoreIndicatorResult.Good:
    case ProductScoreIndicatorResult.Bad:
      return (      
        <svg className={`${result === ProductScoreIndicatorResult.Bad && 'bad'}`}
          viewBox="0,0 80,40"
          fill={result === ProductScoreIndicatorResult.Bad ? '#e04e39' : '#28b440'}>
          <path d={`M 0,0 
                        L 20,0  
                        A 2.5,2.5 0 1,0 60,0
                        L 80,0
                        A 12.5,12.5 0 1,1 0,0
                        `}
          />
        </svg>);    
    default:
      return (
        <svg className='dash-amber'
          viewBox="0 0 53 14"
          fill='#f2cd00'>
          <rect width="53"
            height="14" />
        </svg>
      );
    }
  };

  return (
    <Page mode=' product-page'
      pageNumber={1}>
      <div className='header-content'>
        <div className='content-wrapper'>
          <div className='top-row'>
            <div className='title'>
              <div className='product-name'>{product ? product.itemDescription : stateItemDesc}</div>
              <div className='product-sku'>{product ? product.itemNumber : getItemNumberFromUrl()}</div>
            </div>
            <div className='area-scores'>
              {
                scoreAreas?.filter(x => x.type === ScoreAreaType.product).map((a) => (
                  <div key={a.dataId}
                    className='score'>
                    <img src={`/assets/areas/${a.image}`}
                      title={a.name}/>
                    <div>{product ? product.scoreAreas.find(x => x.area.toLowerCase() === a.dataId)?.rawScore.toFixed(1) : '0.0'}</div>
                  </div>
                ))
              }
            </div>
            <div className="date-selector">
              <ReportingEntityDropdown
                placeholderText='Select a scoring entity'
                selectedEntity={stateReportingEntity}
                isGrey
                onChange={(reportingEntity: string) => reportingEntityChanged(reportingEntity)}
              />
              <CronDateDropdown
                placeholderText="Select date range"
                cronString={(tenantDetails && tenantDetails.reportingWindow) || ''}
                currentDateKey={stateDateKey}
                isGrey
                onChange={(date: string) => dateChanged(date)}
              />
            </div>
          </div>

          {
            props.product.isLoading && <Loading/>
      
          }

          {
            !product && !props.product.isLoading && <Message invertTextColour={true}
              text='No data available for this period' />
          }

          
          { 
            product && product.missingScorecards && 
            <div className='missing-scorecards-wrapper'>
              <Message invertTextColour={true} 
                text={`There is insufficient data for ${product.missingScorecards.length} out of ${scoringEntities?.length} stores`} />
              <div onClick={() => setShowMissingScorecardsModal(true)}
                className='missing-scorecards-learn-more'>Learn more</div>
            </div>
          }

          {
            showMissingScorecardsModal && 
              <Modal useCancelButton={false}
                useConfirmButton={false}
                cancel={() => setShowMissingScorecardsModal(false)}
                continue={() => null}
                inProgress={false}
                background={false}
                title='There is missing data for the following stores'>
                {
                  product && product.missingScorecards && 
                  scoringEntities && scoringEntities.filter(x => product.missingScorecards.includes(x.dataId)).map((x) => {
                    return (
                      <div className='modal-row'
                        key={x.dataId}>
                        {x.name}
                      </div>
                    );
                  })
                }
              </Modal>
          }

          {
            product && 
            <div className='bottom-row'>
              <div className='scoring-area'>
                <div className='score-wrapper'>
                  <WaveScore score={(product && product.rawScore) || 0}
                    maxScore={5}
                    index={1} />
                </div>
                <div className='indicators-wrapper'>
                  {
                    product.indicators && product.indicators.map((i) => {
                      return (
                        <div key={i.description}
                          className='indicator'>
                          {getIndicatorIcon(i.result)}
                          <div key={i.description}>{i.description}</div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>

              <div className='metric-area'>
                <MetricCard title='Retail Price'
                  value={product.metrics.retailPrice[0].toFixed(2)}
                  secondaryValue={product.metrics.retailPrice[1].toFixed(2)}
                  prefix='£'/>
                <MetricCard title='Profitability'
                  value={product.metrics.profitability[0].toFixed(2)}
                  secondaryValue={product.metrics.profitability[1].toFixed(2)}
                  prefix='£'/>
                <MetricCard title='Waste Value'
                  value={product.metrics.wasteValue.toFixed(2)}
                  prefix='£'/>
                <MetricCard title='Sales Volume'
                  value={product.metrics.salesVolume.toString()}/>
                <MetricCard title='Waste Percent'
                  value={product.metrics.wastePercent.toFixed(2)}
                  suffix='%'/>
                <MetricCard title='Availability'
                  value={product.metrics.availability.toFixed(2)}
                  suffix='%'/>
                <MetricCard title='Missed Sales'
                  value={product.metrics.missedSales.toFixed(2)}
                  prefix='£'/>
                <MetricCard title='Waste Qty'
                  value={product.metrics.wasteQty.toString()}/>
                <MetricCard title='Profit Margin'
                  value={product.metrics.profitMargin.toFixed(2)}
                  suffix='%'/>
              </div>
            </div>
          }


        </div>
        <div className='bottom-tab'>
          <div className='left-section'>
            <div className='title'>
              <div className='product-name'>{product ? product.itemDescription : '-'}</div>
              <div className='product-sku'>{product ? product.itemNumber : '-'}</div>
            </div>
          </div>
          <div className='right-section'>
            <div className='area-scores'>
              {
                scoreAreas?.filter(x => x.type === ScoreAreaType.product).map((a) => (
                  <div key={a.dataId}
                    className='score'>
                    <img src={`/assets/areas/${a.image}`}
                      title={a.name}/>
                    <div>{product ? product.scoreAreas.find(x => x.area.toLowerCase() === a.dataId)?.rawScore.toFixed(1) : '0.0'}</div>
                  </div>
                ))
              }
            </div>
          </div>

        </div>
      </div>
      
      
    </Page>
  );
};

export default connector(ProductScoreCard);