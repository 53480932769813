import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as ScoreActions from '../../store/score/actions';
import ReactGA from 'react-ga';
import { WaveScore } from '../Common/WaveScore/waveScore';
import SuggestionItem from './SuggestionItem/suggestionItem';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  score: state.score,
});
const mapDispatch = {
  ...ScoreActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoreid?: string, datekey?: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
  PropsFromRedux
  & PropsType;

const EmbeddedScoreCard = (props: IProps) => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const { requestScore, match } = props;

    // load data
    if (match.params.scoreid && match.params.datekey) {
      requestScore(match.params.datekey, match.params.scoreid);
    }
  }, []);

  const { score, authentication } = props;

  const scoreDetails = score;
  const suggestions = (scoreDetails && scoreDetails.suggestions) || [];
  const scoreAreaLookup = (authentication && authentication.scoreAreaLookup) || {};

  return (
    <div className="score-card embedded">
      <div className="content">
        <div className="card-wrapper">
          <div className="summary-card primary">
            <WaveScore score={(scoreDetails && scoreDetails.rawScore) || 0}
              maxScore={5}
              index={1} />
            <div className="suggestions">
              {suggestions.sort((a, b) => (b.goodResult ? 1 : 0) - (a.goodResult ? 1 : 0)).map((suggestion) => (
                <SuggestionItem
                  key={suggestion.areaName + suggestion.suggestion}
                  suggestion = {suggestion}
                  scoreArea = {scoreAreaLookup[suggestion.areaName]}
                  clickEvent={() => null}
                />
              ))}
            </div>
            <button className="toggle-button forced"
              type="button"
              onClick={() => window.open(window.location.origin, '_blank')}>
                Full Scorecard
              {' '}
              <div className="chevron" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(withRouter(EmbeddedScoreCard));
