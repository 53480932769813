import * as React from 'react';

type TableSaveEntryProps = {
    id: string,
    label: string,
    fade: boolean,
    onClick: Function
}

export default class TableSaveEntry extends React.PureComponent<TableSaveEntryProps, {}> {
    public render() {
        const { fade, onClick, label } = this.props;

        return (
            <div className={`table-add-entry ui-toolkit ${fade ? 'dim' : ''}`}>
                <button
                    type="button"
                    onClick={() => (!fade ? onClick() : null)}
                >
                    <div className="label">{label}</div>
                </button>
            </div>
        );
    }
}
