import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router';
import { Avatar } from '../../Common/Avatar/avatar';

interface propTypes {
    score: number,
    maxScore: number,
    companyImageLink: string,
    owner:string,
    name: string
}
type TParams = { scoreid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }

type IProps =
    propTypes
    & PropsType;

const ScoreCardSummaryBar = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  const radius: number = 25; // 131;

  const centerX: number = radius;

  const centerY: number = 0;

  let angle: number = 0;

  const { maxScore, score, companyImageLink, name, owner, match: { params: { scoreid } } } = props;

  angle = (((maxScore - score) / maxScore)) * (Math.PI);

  return (
    <div className="score-card-summary-bar ui-toolkit">
      <div className="score-card-wrapper">
        <div className="detail-holder">
          <div className="image-holder">
            <Avatar companyImageLink={companyImageLink}
              label={scoreid} />
          </div>
          <div>
            <div className="score">
              <div className="score-values">
                <div className="current-score">{score.toFixed(1)}</div>
                <div className="total-score">
                      /
                  {maxScore}
                </div>
              </div>

              {score > 0 && (
                <svg>

                  <mask id="myMask">
                    <path
                      id="pieBig"
                      fill="white"
                      d={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(Math.PI))},${centerY + (radius * Math.sin(Math.PI))} z`}
                    />

                  </mask>
                  <image
                    className="score-image" 
                    href="/assets/logo-shape-beach.png"
                    mask="url(#myMask)"
                  />

                  <animate
                    href="#pieBig"
                    xlinkHref="#pieBig"
                    attributeName="d"
                    dur="1s"
                    from={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(Math.PI))},${centerY + (radius * Math.sin(Math.PI))} z`}
                    to={`M${centerX},${centerY} 
                            L${centerX - centerX},${centerY}  
                            A${radius},${radius} 1 1,0 ${centerX + (radius * Math.cos(angle))},${centerY + (radius * Math.sin(angle))} z`}
                    fill="freeze"
                  />

                </svg>
              )}
            </div>

          </div>
          <div className="labelling">
            <div>{name}</div>
            <div>{owner}</div>
          </div>
        </div>
      </div>
    </div>
  );
    
};

export default withRouter(ScoreCardSummaryBar);