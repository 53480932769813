import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import Page from '../../uiToolkit/containers/page';
import Form from '../../uiToolkit/containers/form';
import Input from '../../uiToolkit/components/input';
import Button from '../../uiToolkit/components/button';
import { ApplicationState } from '../../store';
import * as CompaniesStore from '../../store/configuration/Companies';
import { toast } from 'react-toastify';
import Loading from '../Common/Loading/loading';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  companies: state.companies,
});
const mapDispatch = {
  ...CompaniesStore.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { categoryid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type IProps =
    PropsFromRedux
    & PropsType;

interface IState {
    name: string;
    formIsSubmitted: boolean;
}

class AddCompany extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      name: '',
      formIsSubmitted: false,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    const { companies, history } = this.props;    

    if (companies.isUpdated && !prevProps.companies.isUpdated) {
      toast.info('Successfully created company');
      history.goBack();
    } else if(companies.isErrored && !prevProps.companies.isErrored){
      toast.error('There was an error creating company', {
        className: 'toast-popup error',
      });
    }
  }

  public handleTextInputUpdate(value: string, fieldName: string) {
    const update: object = { [fieldName]: value };

    this.setState(update);
  }

  public handleSave() {
    const { saveCompany } = this.props;
    const { name } = this.state;

    this.setState({ formIsSubmitted: true });

    if (name) {
      saveCompany(name);
    }
  }

  public render() {
    const {
      name,
      formIsSubmitted,
    } = this.state;

    const { companies } = this.props;  
    return (
      <Page mode=""
        pageNumber={1}>
        { companies.isLoading && <Loading/> }
        { !companies.isLoading && 
        <Form title="Create Company">
          <Input
            name="name"
            label="Company Name"
            placeholderText="Name"
            inputType="text"
            onChange={(e: string) => this.handleTextInputUpdate(e, 'name')}
            value={name}
            valid={formIsSubmitted && name.length === 0}
          />
 
          <Button
            text="Save"
            onClick={() => this.handleSave()}
            enabled
          />
        </Form>}
      </Page>
    );
  }
}

export default connector(withRouter(AddCompany));
