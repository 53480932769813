import * as React from 'react';
import * as HISTORY from 'history';
import { Prompt } from 'react-router-dom';
import PromptModal from './promptModal';

interface IProps {
    children?: React.ReactNode;
    when: boolean;
    navigate: Function;
    shouldBlockNavigation: Function;
}

interface IState {
    modalVisible: boolean;
    lastLocation: HISTORY.Location | null;
    confirmedNavigation: boolean;
}

export class CustomPrompt extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      modalVisible: false,
      lastLocation: null,
      confirmedNavigation: false,
    };
  }
   
    public showModal = (location: HISTORY.Location) => this.setState({
      modalVisible: true,
      lastLocation: location,
    })

    public handleBlockedNavigation = (nextLocation: HISTORY.Location) => {
      const { confirmedNavigation } = this.state;
      const { shouldBlockNavigation } = this.props;
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        this.showModal(nextLocation);
        return false;
      }
      return true;
    }

    public handleConfirmNavigationClick = () => {
      this.closeModal(() => {
        const { navigate } = this.props;
        const { lastLocation } = this.state;
        if (lastLocation) {
          this.setState({
            confirmedNavigation: true,
          }, () => {
            navigate(lastLocation.pathname);
          });
        }
      });
    }

    public closeModal = (callback: Function) =>
      this.setState({ modalVisible: false }, callback())

    render() {
      const { when } = this.props;
      const { modalVisible } = this.state;
      return (
        <>
          <Prompt
            when={when}
            message={this.handleBlockedNavigation} />

          { modalVisible &&
            <PromptModal
              inProgress={false}
              cancel={() => this.setState({ modalVisible:false })}
              continue={() => {this.handleConfirmNavigationClick();}}
            />
          }
        </>
      );
    }
}
export default CustomPrompt;




