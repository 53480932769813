/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter, RouteComponentProps, useParams, useHistory } from 'react-router';
import * as qs from 'qs';
import Page from '../../uiToolkit/containers/page';
import ScoreValueMetric from './ScoreValueMetric/scoreValueMetric';
import ScoreTextMetric from './ScoreTextMetric/scoreTextMetric';
import ScoreListItemMetric from './ScoreListItemMetric/scoreListItemMetric';
import ScoreListItemValueMetric from './ScoreListItemValueMetric/scoreListItemValueMetric';
import ScoreListMetric from './ScoreListMetric/scoreListMetric';
import ScoreCardSummaryBar from './ScoreCardSummaryBar/scoreCardSummaryBar';
import { ApplicationState } from '../../store';
import * as ScoreAreaTypes from '../../store/area/types';
import * as ScoreAreaActions from '../../store/area/actions';
import * as ScoreActions from '../../store/score/actions';
import * as AuthActions from '../../store/authentication/actions';
import CronDateDropdown from '../Common/CronDateDropdown/cronDateDropdown';
import ReactGA from 'react-ga';
import ScoreTrendChart from '../Common/TrendChart/ScoreTrendChart';
import { getLatestValidDate } from '../../uiToolkit/helpers/cronHelper';
import Loading from '../Common/Loading/loading';
import MetricChart from './MetricTrendChart/metricChart';
import { TrendFilter } from '../../enums/TrendFilter';
import { MetricTrend } from '../../store/area/types';
import { WaveScore } from '../Common/WaveScore/waveScore';
import { Message } from '../Common/Message/message';

const mapState = (state: ApplicationState) => ({
  authentication: state.authentication,
  score: state.score,
  scoreArea: state.scoreArea,
});
const mapDispatch = {
  ...ScoreAreaActions.actionCreators,
  ...AuthActions.actionCreators,
  ...ScoreActions.actionCreators,
};

const connector = connect(mapState, mapDispatch);

type TParams = { scoreid?: string, areaid: string };
interface PropsType extends RouteComponentProps<TParams> { children?: React.ReactNode }
type PropsFromRedux = ConnectedProps<typeof connector>

type Props =
  PropsFromRedux
  & PropsType;

const ScoreArea = (props: Props) => {
  const history = useHistory();
  const { areaid, scoreid } : any = useParams();
  const [reportingEntity, setReportingEntity] = useState<string>(scoreid || '');
  const [areaId, setAreaId] = useState<string>(areaid);
  const [stateDateKey, setStateDateKey] = useState<string>('');

  const [flavourKey, setFlavourKey] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('');
  const [sortAsc, setSortAsc] = useState<boolean>(true);
  const [grandparentClicked, setGrandparentClicked] = useState<boolean>(false);
  const [showGraphs, setShowGraphs] = useState<{ [key: string]: boolean }>({});
  const { requestScoreArea, requestAreaMetricTrend, setCurrentDate, setCurrentReportingEntity, requestScore  } = props;

  const query = qs.parse(history.location.search.replace('?', ''));
  const queryDateKey = query.datekey && query.datekey.toString() || '';

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let stateDateKey: string = queryDateKey;

    // Manage the date state
    if (queryDateKey.length > 0) {
      setCurrentDate(queryDateKey);
      setStateDateKey(queryDateKey);
    } else {
      const latestValidDate = getLatestValidDate(tenantDetails && tenantDetails.reportingWindow);
      stateDateKey = latestValidDate;
      setStateDateKey(latestValidDate);
      setCurrentDate(latestValidDate);
    }
    

    // Manage the site data
    if (reportingEntity.length > 0) {
      setCurrentReportingEntity(reportingEntity);
    }
    // load data
    if ((score === undefined || score.siteId === undefined) && (score && !score.isLoading)) {
      requestScore(stateDateKey,reportingEntity);   
    }
    requestScoreArea(stateDateKey, areaId, reportingEntity);
    requestAreaMetricTrend(reportingEntity, areaId, stateDateKey, TrendFilter.ONE_YEAR);

  }, []);

  useEffect(() => {
    if (stateDateKey && areaId && reportingEntity) {
      history.replace(`/score/${reportingEntity}/area/${areaId}/?datekey=${stateDateKey}`);
      requestScoreArea(stateDateKey, areaId, reportingEntity);
      requestAreaMetricTrend(reportingEntity, areaId, stateDateKey, TrendFilter.ONE_YEAR);
    }

  }, [stateDateKey, areaId, reportingEntity]);


  const handleToggleGraph = (id: string) => {
    setShowGraphs((prevState: { [key: string]: boolean }) => {
      const updatedShowGraphs = { ...prevState };
      updatedShowGraphs[id] = !prevState[id];
      return updatedShowGraphs;
    });
  };
  
  const dateChanged = (dateKey: string) => {
    const { setCurrentDate } = props;
    setCurrentDate(dateKey);
    setStateDateKey(dateKey);
  };

  const renderMetrics = () =>  {
    const { scoreArea } = props;

    const components = scoreArea && scoreArea.template && scoreArea.template.components;
    const sequence = components && components.sequence;

    if (sequence === undefined) {
      return null;
    }

    const metrics = scoreArea && scoreArea.metrics;
    const previousMetrics = (scoreArea && scoreArea.prevMetrics) || {};

    if (metrics === undefined) {
      return null;
    }

    const renderedComponents: (JSX.Element | null)[] = [];

    for (let i = 0; i < sequence.length; i += 1) {
      const currentSequence = sequence[i];

      const textComponent = components && components.textMetrics
      && components.textMetrics.find((t) => t.id === currentSequence);

      if (textComponent) {
        renderedComponents.push(renderTextMetric(textComponent, metrics));
        continue;
      } 
        
      const valueComponent = components && components.valueMetrics
        && components.valueMetrics.find((t) => t.id === currentSequence);

      if (valueComponent) {
        renderedComponents.push(renderValueMetric(valueComponent, metrics, previousMetrics));
        continue;
      } 
        
      const listComponent = components && components.listMetrics
        && components.listMetrics.find((t) => t.id === currentSequence);

      if (listComponent) {

        const listMetrics = scoreArea && scoreArea.listMetrics && scoreArea.listMetrics[listComponent.data];
        if(listMetrics){
          const itemLookupData = (scoreArea && scoreArea.itemDataLookup) || {};
          renderedComponents.push(renderListMetric(listComponent, listMetrics, itemLookupData ));
        }
      }
    }

    return renderedComponents;
  };

  const replacePlaceholders = (text:string, values: {[id:string]: any}, extraValues ?: ScoreAreaTypes.ItemData): string => {

    if(typeof(text) !== 'string'){
      return text;
    }

    const placeholders = text.match(/\{\{[a-z0-9]+\}\}/ig);
    const replaceValues = (placeholders && placeholders.map((match) => match.replace('{{', '').replace('}}', ''))) || [];

    for (let i = 0; i < replaceValues.length; i += 1) {
      let value = values[replaceValues[i]];
      
      if(replaceValues[i] === 'description' && extraValues && extraValues.description){
        value = extraValues.description;
      }

      if (value !== undefined) {
        text = text.replace(`{{${replaceValues[i]}}}`, value);
      }else{
        text = text.replace(`{{${replaceValues[i]}}}`, '');
      }
    }

    return text;
  };

  const renderTextMetric = (component: ScoreAreaTypes.TextMetric, metrics: ScoreAreaTypes.Metrics) => {
    const { tooltip } = component;
    
    let { text } = component;

    text = replacePlaceholders(text, metrics, undefined);
    
    return (
      <ScoreTextMetric key={text}
        id={component.id}
        text={text} 
        grandparentClicked={grandparentClicked}
        handleGrandparentClicked={handleGrandparentClicked}
        tooltip = {tooltip}
        editMode={false}
        onDelete={() => null}
        onInputChange={() => null}
      />);
  };

  const renderValueMetric = (component: ScoreAreaTypes.ValueMetric, metrics: ScoreAreaTypes.Metrics, previousMetrics: ScoreAreaTypes.Metrics) => {
    const { value, total } = component; 
    //let { change } = component; 
    const { increaseGood, format, tooltip } = component;
    const { scoreArea } = props;

    let prevValue = '';
    let newValue = '';
    let newTotal = '';
    let change = 0;

    newValue = replacePlaceholders(value, metrics); 
    prevValue = replacePlaceholders(value, previousMetrics);
    newTotal = replacePlaceholders(total, metrics);

    if ((prevValue && Number.parseFloat(prevValue)) || (newValue && Number.parseFloat(newValue))) {
      const previous = Number.parseFloat(prevValue) || 0;
      const current = Number.parseFloat(newValue) || 0;
      change = (current - previous);
    }

    if(newValue){
      return (
        <> 
          <ScoreValueMetric
            id={component.id}
            key={component.label}
            label={component.label}
            value={newValue}
            total={newTotal}
            format={format}
            change={change}
            increaseGood={increaseGood}
            tooltip = {tooltip}
            grandparentClicked={grandparentClicked}
            handleGrandparentClicked={handleGrandparentClicked}
            onInputChange={() => null}
            onDelete={() => null}
            showGraph={showGraphs[component.id] || false}
            onToggleGraph={() => handleToggleGraph(component.id.toString())}
          />

          { 
       
            showGraphs[component.id] && 
           <MetricChart 
             onClose={() => handleToggleGraph(component.id.toString())}
             metricData={getMetricTrendByName(scoreArea.areaMetricTrends, component.value.replace(/{{|}}/g, ''))}
             reportingStartDate={stateDateKey}

           />
          }
        </>
      );
    }

    return null;
  };

  const getMetricTrendByName = (trendData: MetricTrend[], metricName: string): { reportingWindowStart: string; value: number }[] => {
    return trendData.map((item) => {
      const targetMetric = item.metrics.find((metric) => metric.label.toLowerCase() === metricName.toLowerCase());
      if (targetMetric) {
        return {
          reportingWindowStart: item.reportingWindowStart,
          value: targetMetric.value,
        };
      } 
      return null; 
    }).filter(Boolean) as { reportingWindowStart: string; value: number }[]; // cast filter result to the correct return type
  };

  const renderListMetric = (component: ScoreAreaTypes.ListMetric, metrics: ScoreAreaTypes.Metrics[], itemDataLookup: {[id: string]: ScoreAreaTypes.ItemData}) => {
    const item = component.items;
    const lookUpKey  = component.flavouredData || '';
    const metricData = metrics;
    const { tooltip } = component;

    if (!metricData || !item) {
      return null;
    }

    //Sort data
    let sortedData = metricData;

    if(sortKey){
      if(sortAsc){
        sortedData = metricData.sort((a,b) => a[sortKey] - b[sortKey]);
      }else{
        sortedData = metricData.sort((a,b) => b[sortKey] - a[sortKey]);
      }
    }else{
      if(component.sortOptions && component.sortOptions[0]){
        setSortKey(component.sortOptions[0].data);
        setSortAsc(false);
      }
    }
    

    const components: JSX.Element[] = [];
    let flavourItems: {data:string, label:string}[] = [];

    for (let i = 0; i < sortedData.length; i += 1) {

      let itemData:ScoreAreaTypes.ItemData | undefined = undefined;

      //Get Matching Item
      const lookUpValue = sortedData[i][lookUpKey];

      if(lookUpValue){
        itemData = itemDataLookup[lookUpValue];
      }

      //Get Flavoured Options
      if(itemData){
        flavourItems.push({ data: itemData.categoryId.toString(), label: itemData.categoryName });
      }
      
      //Filter Data
      if((!flavourKey || !itemData || flavourKey === itemData.categoryId.toString()) && components.length < 10){
        components.push(renderListItemMetric(item, sortedData[i], component.style, i, itemData));
      }
    }

    flavourItems = flavourItems.filter((value, index, self) => self.findIndex((m) => m.data === value.data ) === index);

    return (
      <ScoreListMetric
        key={component.label}
        id={component.id}
        label={component.label}
        tooltip = {tooltip}
        grandparentClicked={grandparentClicked}
        handleGrandparentClicked={handleGrandparentClicked}
        flavourOptions={flavourItems}
        sortOptions={component.sortOptions}
        selectedFlavourKey={flavourKey}
        onFlavourChange={ (key:string) => setFlavourKey(key) }
        onSortChange={(key: string) => {
          const newSortKey = key.replace('_asc', '').replace('_desc', '');
          const newSortAsc = key.indexOf('_asc') > -1;
          setSortKey(newSortKey);
          setSortAsc(newSortAsc);
        }}
        onInputChange={() => null}
        metricVariables={[]}
        onDelete={() => null}
      >
        {components && components.length > 0 ? components : <Message text="No Items Founds"/>}
      </ScoreListMetric>
    );
  };

  const renderListItemMetric = (component: ScoreAreaTypes.ListItemRow,
    metrics: ScoreAreaTypes.Metrics, style: string, index: number, itemData?: ScoreAreaTypes.ItemData) => {
    const components: JSX.Element[] = [];

    let { title } = component;
    
    title = replacePlaceholders(title, metrics, itemData); 

    if(style === 'numbered'){
      title = (index+1).toString();
    }

    const key =((itemData && itemData.label) || title) + index; 

    for (let i = 0; i < component.metrics.length; i += 1) {
      components.push(renderListItemValueMetric(component.metrics[i], metrics, key, itemData));
    }

    return (
      <ScoreListItemMetric key={key}
        label={title}
        style={style}
        addMetricHandler={() => null}
        onInputChange={() => null}>
        {components}
      </ScoreListItemMetric>
    );
  };

  const renderListItemValueMetric = (component: ScoreAreaTypes.ListItemMetric,
    metrics: ScoreAreaTypes.Metrics, parentKey:string,  itemData?: ScoreAreaTypes.ItemData)  => {
    
    const { format } = component;
    
    const value = replacePlaceholders(component.value, metrics, itemData);
    const label = replacePlaceholders(component.label, metrics, itemData); 
    const suffix = component.suffix ? replacePlaceholders(component.suffix, metrics, itemData): ''; 

    return (<ScoreListItemValueMetric key={parentKey + '-' + label}
      label={label}
      value={value}
      format={format}
      suffix={suffix}
      onInputChange={() => null}
      onDelete={() => null} />);
  };

  const handleGrandparentClicked = (e: React.MouseEvent, value: boolean) => {
    e.stopPropagation();
    setGrandparentClicked(value);
  };


  const { authentication, score, scoreArea } = props;


  const userDetails = authentication;
  const tenantDetails = userDetails.tenantDetails;
  const scoreDetails = score;
  const scoreAreaDetails = scoreArea;
  const metrics = renderMetrics() || [];

  const scoringEntity = userDetails 
      && scoreAreaDetails 
      && userDetails.scoringEntityLookup 
      && userDetails.scoringEntityLookup[scoreAreaDetails.siteId];

  const scoringArea = userDetails 
      && scoreAreaDetails 
      && userDetails.scoreAreaLookup 
      && userDetails.scoreAreaLookup[scoreAreaDetails.area];

  const areaTrend = (scoreAreaDetails && scoreAreaDetails.trend && scoreAreaDetails.trend.scoreTrends) || [];
  const prevAreaTrend = (scoreAreaDetails && scoreAreaDetails.prevTrend && scoreAreaDetails.prevTrend.scoreTrends) || [];

  const threshold = (tenantDetails && tenantDetails.threshold) || 0;

  let scoreChange = '-';

  const currentScore = scoreAreaDetails.rawScore;
  const prevScore = scoreAreaDetails.prevRawScore;

  scoreChange = (currentScore - prevScore).toFixed(1);

  let scoreDirection = '';

  if (scoreChange.indexOf('-') === -1) {
    scoreDirection = 'up';
    scoreChange = `+${scoreChange}`;
  } else if (scoreChange.length > 1 && scoreChange.indexOf('-') > -1) {
    scoreDirection = 'down';
  }

  return (
    <div
      onClick={(e) => handleGrandparentClicked(e, true)}>
      <Page pageNumber={1}
        mode="none score-area">
        <ScoreCardSummaryBar
          score={(scoreDetails && scoreDetails.rawScore) || 0}
          maxScore={5}
          companyImageLink="/assets/logo_starbucks.svg"
          name={(scoringEntity && scoringEntity.name) || ''}
          owner={(scoringEntity && scoringEntity.owner.name) || ''}
        />
        <div className="area-header">
          <div className="wrapper">
            <div className="cards">
              <div className="left-cards">
                <div className="title">{(scoringArea && scoringArea.name) || 'Score Area'}</div>
                <div className="area-score-card">
                  <WaveScore
                    score={(scoreAreaDetails && scoreAreaDetails.rawScore) || 0}
                    maxScore={5}
                    index={2}
                  />
                  <div className={`change ${scoreDirection}`}>{scoreChange}</div>
                </div>

              </div>
              <div className="trend-card">
                <div className="trend-chart">
                  <ScoreTrendChart 
                    scoreTrend = {areaTrend.concat(prevAreaTrend)}
                    threshold = {threshold}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="metrics-outer">
          <div className="metric-container">
            <div className="metric-header">
              <div className="large-header">Statistics</div>
              <CronDateDropdown
                cronString={(tenantDetails && tenantDetails.reportingWindow) || ''}
                currentDateKey={stateDateKey}
                onChange={(dk: string) => { dateChanged(dk); }}
                placeholderText="Select date range"
                isGrey
              />
            </div>
              
            <div className='loader-style'>
              <div>{scoreArea && scoreArea.isLoading && <Loading/>}
              </div>
            </div>

            {metrics.length === 0 && !scoreArea.isLoading ? <Message text="No data currently available." /> : metrics}
          </div>
        </div>
      </Page>
    </div>
  );
};

export default connector(withRouter(ScoreArea));
