import React, { useEffect } from 'react';
import ProgressBar from '../../Common/ProgressBar/progressBar';
import ReactGA from 'react-ga';

interface IProps {
    index: number,
    title: string,
    score: number,
    maxScore: number,
    onStreak?: boolean,
    detailClick: Function,
    backgroundImagePath: string,
}

const AreaSummary = (props: IProps) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const { index, title, score, maxScore, onStreak, detailClick, backgroundImagePath } = props;

  return (
    <div className="area-summary ui-toolkit">
      {onStreak && <img className='flame-icon'
        src='/assets/flame.png'/> }
      <img src={`/assets/areas/${backgroundImagePath}`}
        alt={title} />
      <div className="img-title">
        {title}
      </div>
     
      <ProgressBar 
        score={score}
        maxScore={maxScore}
        index={index} />
    
      <div className="score">
        {`${score.toFixed(1)}/${maxScore}`}
      </div>
      <button 
        type="button"
        onClick={() => detailClick()}
      >
        <div className="chevron" />
      </button>
    </div>
  );
};

export default AreaSummary;
