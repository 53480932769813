import * as React from 'react';

const Page = ({
  children, mode, padding, pageNumber,
}: { children: React.ReactNode, mode: string, pageNumber: number, padding?: boolean }) => (
  <div className={`page ui-toolkit page-number-${pageNumber} page-mode-${mode} ${padding ? 'page-padding' : ''}`}>
    {children}
  </div>
);

export default Page;
