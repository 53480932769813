import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

interface PropsType extends RouteComponentProps { children?: React.ReactNode }

class ScrollToTop extends React.Component<PropsType, {}> {
  componentDidUpdate(prevProps: PropsType) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <span />;
  }
}

export default withRouter(ScrollToTop);
