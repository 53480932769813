import { AppThunkAction } from '../index';
import { ProductAction } from './types';

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will
// trigger a state transition.
// They don't directly mutate state, but they can have external side-effects
// (such as loading data).

export const actionCreators = {
  requestProductScoreCard: (itemNumber: string, reportingStartDate: string, reportingEntity: string):
  AppThunkAction<ProductAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.score && appState.product.isLoading === false) {
      dispatch({
        type: 'REQUEST_PRODUCT',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/product?reportingEntity=${reportingEntity}&itemNumber=${itemNumber}&reportingStartDate=${reportingStartDate}`,
          successAction: 'RECEIVE_PRODUCT',
          failureAction: 'FAILED_PRODUCT',
        },
      });
    }
  },
  requestProductAverageScoreCard: (itemNumber: string, reportingStartDate: string):
  AppThunkAction<ProductAction> => (dispatch, getState) => {
    // Only load data if it's something we don't already have (and are not already loading)
    const appState = getState();
    if (appState && appState.score && appState.product.isLoading === false) {
      dispatch({
        type: 'REQUEST_PRODUCT',
        http: {
          verb: 'GET',
          endpoint: `/api/scorecard/productaverage?itemNumber=${itemNumber}&reportingStartDate=${reportingStartDate}`,
          successAction: 'RECEIVE_PRODUCT',
          failureAction: 'FAILED_PRODUCT',
        },
      });
    }
  },
};