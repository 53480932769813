import React from 'react';

interface IProps {
    title: string;
    value: string;
    secondaryValue?: string;
    prefix?: string;
    suffix?: string;
}

const MetricCard = (props: IProps) => {
  function formatValue(value: string){
    return (props.prefix ?? '') + value + (props.suffix ?? '');
  }

  return (
    <div className='metric-card'>
      {props.title}
      <div className='metric-value'>
        {formatValue(props.value)}
        {props.secondaryValue && props.secondaryValue != props.value && (' - ' + formatValue(props.secondaryValue))}
      </div>
    </div>
  );
};

export default MetricCard;